import { Box, Chip, Divider, ListItem } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useField, useFormikContext } from 'formik'
import { drop, filter, find, get, includes, isNil, map } from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useDebounce, usePrevious } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { LabelField } from '@opus/web.core.form.label-field'
import { makeStyles } from '@material-ui/core/styles'
import { appStore } from '~/stores'
import { css } from 'styled-components'
import { Clear } from '@material-ui/icons'
import { chipClearIconCss, useClipClasses, viewLabelStyle } from '~/components/fields/autocomplete-field/autocomplete-field.style'
import { TextField } from '~/components/fields'
import { Typography } from '~/components/typography'
import { CheckBoxSvg, CheckedBoxSvg, CheckMark2Svg } from '~/components/icons'

export const labelStyle = css`
	font-weight: 600;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.colors.primary.main} !important;
	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}
	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`

export const groupStyle = css`
	:not(:first-child) {
		margin-top: 15px;
	}
	.group-children {
		display: flex;
		flex-direction: column;
		gap: 26px;
		.MuiTypography-root {
			${({ theme }) => theme.typography.p1};
		}
	}

	.divider {
		width: calc(100% - 32px);
		margin: 0 auto;
		margin-top: 21px;
	}
`

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(1),
		width: '100%',
	},
	option: {
		'&:empty': {
			display: 'none',
		},
	},
}))

export const AutoCompleteField = ({
	name,
	validate,
	label,
	groupBy,
	placeholder,
	disabled,
	options,
	freeSolo,
	allowAddValue,
	mode,
	parentName,
	multiple,
	disableClearable,
	fullWidth = true,
	handleUpdateChange,
	hasAllowChangeField = false,
	allowShowLabel = false,
	endLabel,
	excludedOptionLabel,
	maxItemSelected,
	helperText,
	disableGroups,
	handleWithCurrentValue,
	allowCustomValue,
	...props
}) => {
	const { t } = useTranslation()
	const chipClasses = useClipClasses()
	const { values, setFieldValue } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const classes = useStyles()
	const [inputValue, updateInputValue] = useState(field.value)
	const [focused, focusProps] = useFieldFocused(field)
	const groupLabelUndef = useRef([])
	const [open, setOpen] = useState(false)

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(_, option) => {
			const newValue = option?.map?.((opt) => opt.value)
			updateInputValue(multiple ? (maxItemSelected && option.length > maxItemSelected ? drop(newValue, 1) : newValue) : option?.value)
			handleWithCurrentValue && handleWithCurrentValue(option?.value)
		},

		// eslint-disable-next-line
		[updateInputValue, multiple, maxItemSelected]
	)

	useDebounce(
		() => {
			if (field.value !== inputValue && !focused) {
				updateInputValue(field.value || (multiple ? [] : ''))
			}
		},
		0,
		[field.value]
	)

	useDebounce(
		() => {
			field.onChange({ target: { name, value: inputValue || '' } })
			if (hasAllowChangeField && inputValue !== '' && focused && !isNil(inputValue)) {
				handleUpdateChange()
			}
		},
		0,
		[inputValue]
	)

	const parentValue = useMemo(() => get(values, parentName), [values, parentName])

	const finalOptions = useMemo(() => {
		const items = parentName ? options?.filter((option) => option.parentValue === parentValue) : options
		// reset child autocomplete
		if (parentName) {
			setFieldValue(name, multiple ? [] : '')
		}

		const mapCustomItems = field.value
			? find(items, (item) => item?.value === field.value)
				? items
				: [
						...items,
						{
							label: field.value,
							value: field.value,
							disabled: true,
							...(groupBy && {
								groupLabel: field.value,
								groupId: field.value,
								disabledGroup: field.value,
							}),
						},
				  ]
			: items
		return map(allowCustomValue ? mapCustomItems : items, (option) => (option.label ? option : { ...option, label: option.value }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, setFieldValue, options, parentName, multiple, parentValue, allowCustomValue, groupBy])

	const selectedOption = useMemo(() => {
		if (multiple) {
			return filter(finalOptions, (option) => includes(field.value, option.value)) || []
		}

		return find(finalOptions, (option) => option.value === field.value) || (freeSolo && inputValue ? { value: inputValue, label: inputValue } : null)
	}, [finalOptions, field.value, multiple, freeSolo, inputValue])

	const prevParentValue = usePrevious(parentValue)

	useDebounce(
		() => {
			if (mode === FIELD_MODE.edit && parentName && prevParentValue !== parentValue) {
				field.onChange({ target: { name, value: null } })
			}
		},
		0,
		[parentValue, prevParentValue]
	)

	const groupSelectedOption = useMemo(() => {
		const matchedGroupItem = finalOptions?.find((item) => item.groupId === field.value)
		const groupId = matchedGroupItem?.groupId
		const groupName = matchedGroupItem?.groupLabel
		const disabledGroup = matchedGroupItem?.disabledGroup

		return groupId
			? {
					id: groupId,
					label: groupName,
					disabledGroup,
			  }
			: undefined
	}, [finalOptions, field.value])

	const viewValue = useMemo(
		() =>
			multiple
				? selectedOption?.map((opt) => opt.label)?.join(', ')
				: groupBy && groupSelectedOption
				? groupSelectedOption?.label
				: selectedOption
				? `${selectedOption?.groupLabel ?? ''} ${selectedOption?.label && selectedOption?.groupLabel ? '-' : ''} ${selectedOption?.label ?? ''}`
				: '--',
		[multiple, groupBy, groupSelectedOption, selectedOption]
	)

	if (mode === FIELD_MODE.view) {
		if (multiple && !allowShowLabel) {
			return (
				<Box>
					<Box mb={1} css={labelStyle}>
						<label> {t(label)} </label>
					</Box>
					{viewValue ? (
						<Box display="flex" gridGap={8} flexWrap="wrap">
							{viewValue?.split(',').map((item) => (
								<Chip size="small" classes={chipClasses} label={`${item}`} />
							))}
						</Box>
					) : (
						'--'
					)}
				</Box>
			)
		} else if (multiple && allowShowLabel) {
			return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => viewValue || field.value} />
		} else {
			return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => viewValue || field.value} />
		}
	}

	return (
		<Autocomplete
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			classes={classes}
			{...props}
			closeIcon={
				<Clear
					onClick={() => {
						if (hasAllowChangeField) {
							handleUpdateChange()
							field.onChange({ target: { name, value: null } })
						}
					}}
					css={[
						{
							fill: appStore.getTheme.palette.status.error.medium,
							fontSize: '16px',
						},
					]}
				/>
			}
			id={name}
			freeSolo={freeSolo}
			groupBy={groupBy}
			options={finalOptions}
			getOptionLabel={(option) => (option.isNew ? `Add "${option.label}"` : option.label || '')}
			getOptionSelected={(option, value) => option.value === value?.value}
			selectOnFocus
			filterOptions={(options, params) => {
				if (!params.inputValue) {
					return options
				}

				const filtered = filter(options, (option) => option?.label?.toLowerCase()?.includes(params?.inputValue?.toLowerCase()))

				// Suggest the creation of a new value
				if (params.inputValue !== '' && allowAddValue) {
					filtered.push({
						value: params.inputValue,
						label: params.inputValue,
						isNew: true,
					})
				}

				return filtered
			}}
			fullWidth={fullWidth}
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			disableClearable={disableClearable}
			value={groupBy && groupSelectedOption ? groupSelectedOption : selectedOption}
			onChange={handleChange}
			popupIcon={<MdExpandMore />}
			ChipProps={{ classes: chipClasses, deleteIcon: <Clear css={chipClearIconCss} />, size: 'small' }}
			disableCloseOnSelect={multiple}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t(label)}
					disabled={disabled}
					placeholder={multiple && viewValue?.length > 0 ? undefined : placeholder && t(placeholder)}
					error={!!error}
					helperText={error ? error : helperText}
					endLabel={endLabel}
				/>
			)}
			renderOption={(option, { selected }) => {
				if (option?.label?.toLowerCase() === excludedOptionLabel) groupLabelUndef.current = [...new Set([...groupLabelUndef.current, option.groupLabel])]
				return option?.label?.toLowerCase() === excludedOptionLabel ? null : (
					<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
						<Typography variant="h4" css={selected ? { color: appStore.getTheme.colors.secondary.main } : undefined}>
							{option.label}
						</Typography>
						{multiple ? selected ? <CheckedBoxSvg /> : <CheckBoxSvg /> : selected ? <CheckMark2Svg /> : null}
					</Box>
				)
			}}
			getOptionDisabled={(option) => option?.disabled}
			renderGroup={
				groupBy
					? ({ key, group, children }) => {
							return (
								<Box css={groupStyle}>
									<ListItem
										key={key}
										css={{
											cursor: 'pointer',
											justifyContent: 'space-between',
											color: appStore.getTheme.colors.primary.main,
											fontWeight: 800,
											fontSize: '16px',
											marginBottom: includes(groupLabelUndef.current, group) ? '0' : '26px',
											opacity: group === groupSelectedOption?.disabledGroup ? 0.6 : 1,
										}}
										onClick={() => {
											if ((disableGroups && disableGroups.includes(group)) || group === groupSelectedOption?.disabledGroup) {
												return
											}

											if (group) {
												setFieldValue(name, group)
											}
											setOpen(false)
										}}
									>
										{group}

										{group === field.value && <CheckMark2Svg />}
									</ListItem>
									<Box className="group-children">{children}</Box>
									<Divider className="divider" />
								</Box>
							)
					  }
					: undefined
			}
		/>
	)
}

AutoCompleteField.defaultProps = {
	freeSolo: false,
	allowAddValue: false,
	options: [],
	mode: FIELD_MODE.edit,
	multiple: false,
}
