import { css } from 'styled-components'

export const wrapperStyle = css``

export const redLabel = css`
	color: ${({ theme }) => theme.palette.status.error.medium};
`

export const redIcon = css`
	& path {
		fill: ${({ theme }) => theme.palette.status.error.medium};
	}
`
