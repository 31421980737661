import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store } from '~/common/mobx.decorator'
import { THEMES } from '~/themes'
import { notifyStore } from '~/stores/notify.store'

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}/api/public/v1`
axios.defaults.headers['Tenant-Id'] = process.env.REACT_APP_COMPANY_ID

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable meta_domain
	@observable store_android_url
	@observable store_ios_url

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get logo() {
		return `/assets/logo.png`
	}

	@computed
	get getTheme() {
		return THEMES.candidate
	}

	@action
	init = async () => {
		await this.fetchFEConfig()
		await this.fetchAppConfig()
	}

	fetchAppConfig = async () => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		if (!isMobile) {
			await notifyStore.initOneSignal().then(() => notifyStore.sendInfo())
		}
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		const data = await response.json()

		return data
	}
}

export const appStore = new AppStore()
