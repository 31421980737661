import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
	title: {
		...theme.typography.h3,
		color: theme.palette.primary.main,
	},
	actions: {
		padding: '16px 24px',
	},
}))
