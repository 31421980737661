import { action, computed } from 'mobx'
import moment from 'moment'
import { apolloClient } from '~/common/apollo'
import {
	DELETE_ALL_READ_NOTIFICATION_MUTATION,
	DELETE_NOTIFICATION_MUTATION,
	MARK_ALL_NOTIFICATION_STATUS_MUTATION,
	MARK_NOTIFICATION_STATUS_MUTATION,
} from '~/common/apollo/mutations/notification.mutation'
import { GET_NOTIFICATIONS } from '~/common/apollo/queries/notification.query'
import { observable, store } from '~/common/mobx.decorator'
import { notifyStore, routerStore } from '~/stores'
import { PATHS } from '~/common/constants'

@store()
class NotificationStore {
	@observable loading = false
	@observable pageInfo = {
		hasNextPage: true,
		endCursor: null,
	}
	@observable totalUnread = 0
	@observable notifications = []

	@computed
	get newNotifications() {
		return this.notifications.filter((item) => moment(item.createdAt).isSame(moment(), 'day'))
	}

	@computed
	get oldNotifications() {
		return this.notifications.filter((item) => !moment(item.createdAt).isSame(moment(), 'day'))
	}

	@action
	clearNotifications = () => {
		this.notifications = []
		this.totalUnread = 0
		this.pageInfo = {
			hasNextPage: true,
			endCursor: null,
		}
	}

	@action
	refreshNotifications = async () => {
		this.pageInfo = {
			hasNextPage: true,
			endCursor: null,
		}

		this.fetchNotifications()
	}

	@action
	fetchNotifications = async () => {
		if (this.loading || !this.pageInfo.hasNextPage) {
			return
		}

		try {
			this.loading = true
			const variables = { first: 20, after: this.pageInfo.endCursor }
			const response = await apolloClient.query({ query: GET_NOTIFICATIONS, variables })
			this.notifications = [...this.notifications, ...response.data.notifications.nodes]
			this.pageInfo = response.data.notifications.pageInfo
			this.totalUnread = response.data.notifications.totalUnread
		} catch (error) {
			console.error(error.message)
		} finally {
			this.loading = false
		}
	}

	@action
	refreshNotificationAfterAction = async () => {
		this.pageInfo = {
			hasNextPage: true,
			endCursor: null,
		}

		try {
			this.loading = true
			const variables = { first: this.notifications.length || 20, after: this.pageInfo.endCursor }
			const response = await apolloClient.query({ query: GET_NOTIFICATIONS, variables })
			this.notifications = [...response.data.notifications.nodes]
			this.pageInfo = response.data.notifications.pageInfo
			this.totalUnread = response.data.notifications.totalUnread
		} catch (error) {
			notifyStore.error(error.message)
		} finally {
			this.loading = false
		}
	}

	@action
	markAllAsRead = async () => {
		try {
			await apolloClient.mutate({ mutation: MARK_ALL_NOTIFICATION_STATUS_MUTATION })
			await this.refreshNotificationAfterAction()
			await notifyStore.success('All notifications have been marked as read')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	deleteAllRead = async () => {
		try {
			await apolloClient.mutate({ mutation: DELETE_ALL_READ_NOTIFICATION_MUTATION })
			await this.refreshNotificationAfterAction()
			notifyStore.success('All read notifications have been deleted')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	markNotificationAsRead = async (notification) => {
		try {
			await apolloClient.mutate({
				mutation: MARK_NOTIFICATION_STATUS_MUTATION,
				variables: { notificationIds: [notification.id], status: 'read' },
			})
			notification.status = 'read'
			this.notifications = this.notifications.map((item) => (item.id === notification.id ? notification : item))
			this.totalUnread = this.totalUnread - 1
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleClickNotification = async (notification) => {
		if (notification.status !== 'read') {
			await this.markNotificationAsRead(notification)
		}
		if (notification.event === 'job_alert') {
			await routerStore.goPage(`${PATHS.care.search}?saveSearchId=${notification?.objectId}`)
		}

		if (notification.event === 'job_rating') {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.jobId}?isShowRatingForm=true`)
		}
		if (notification.event === 'job_cancellation') {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.jobId}`)
		}
		if (notification.event === 'job_applicant_status_changed') {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.jobId}`)
		}

		if (
			notification.event === 'credential_new_request' ||
			notification.event === 'credential_expired_soon' ||
			notification.event === 'credential_incompleted'
		) {
			await routerStore.goPage(PATHS.care.credentials)
		}
		if (notification?.event === 'timecard_submission') {
			await routerStore.goPage(PATHS.care.timecards)
		}
		if (notification.objectType === 'Job') {
			await routerStore.goPage(`${PATHS.care.jobs}/${notification?.objectId}`)
		}

		if (notification.event === 'job_matches') {
			await routerStore.goPage(`${PATHS.care.suggested_jobs}`)
		}
		if (notification.event === 'assignment_check_out') {
			if (!!notification?.additionalData?.jobId) {
				await routerStore.goPage(`${PATHS.care.jobs}/${notification?.additionalData?.jobId}`)
			}
		}
	}

	@action
	handleDeleteNotification = async (notification) => {
		try {
			const variables = { notificationIds: [notification.id] }
			await apolloClient.mutate({ mutation: DELETE_NOTIFICATION_MUTATION, variables })
			await this.refreshNotificationAfterAction()
			notifyStore.success('Your notification has been deleted')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
}

export const notificationStore = new NotificationStore()
