import { css } from 'styled-components'

export const phoneFieldStyle = css`
	width: 100%;
`

export const verifiedTextStyle = css`
	${({ theme }) => theme.typography.title2};
	color: ${({ theme }) => theme.palette.status.success.medium};
`

export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main};
	text-transform: capitalize;
`
