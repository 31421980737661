import { gql } from '@apollo/client'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const LIKE_DISLIKE_JOB_MUTATION = gql`
	mutation LikeOrDislikeAJob($jobId: String!, $action: LikeActionEnum!) {
		likeOrDislikeAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const EXTEND_JOB_APPLICANT_MUTATION = gql`
	mutation CreateExtendAssignment($workerAssignmentId: String!, $timeOffStartDate: ISO8601Date!, $timeOffEndDate: ISO8601Date!, $note: String) {
		createExtendAssignment(workerAssignmentId: $workerAssignmentId, timeOffStartDate: $timeOffStartDate, timeOffEndDate: $timeOffEndDate, note: $note) {
			id
			note
			timeOffEndDate
			timeOffStartDate
		}
	}
`

export const EXTEND_WORKER_ASSIGNMENT_MUTATION = gql`
	mutation extendWorkerAssignment($workerAssignmentId: String!) {
		extendWorkerAssignment(workerAssignmentId: $workerAssignmentId) {
			success
		}
	}
`

export const APPLY_MULTIPLE_JOBS_MUTATION = gql`
	mutation createMultiJobApplicants($jobIds: [String!]!, $source: JobApplicantSourceEnum, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!]) {
		createMultiJobApplicants(jobIds: $jobIds, note: $note, source: $source, applicantTimeOffs: $applicantTimeOffs) {
			id
		}
	}
`

export const QUICK_APPLY_JOB_MUTATION = gql`
	mutation quickApplyJobMutation(
		$firstName: String
		$recaptchaToken: String
		$lastName: String
		$email: String
		$phone: String
		$jobId: String!
		$autoAssignScl: Boolean
		$resumes: [DocumentInput!]
		$workerReferences: [WorkerReferenceInput!]
	) {
		quickApply(
			firstName: $firstName
			recaptchaToken: $recaptchaToken
			lastName: $lastName
			email: $email
			phone: $phone
			jobId: $jobId
			autoAssignScl: $autoAssignScl
			resumes: $resumes
			workerReferences: $workerReferences
		) {
			success
		}
	}
`
export const CREATE_JOB_RATING = gql`
	mutation createJobRating($ratePoint: Float, $comment: String, $workerAssignmentId: String!) {
		createJobRating(ratePoint: $ratePoint, comment: $comment, workerAssignmentId: $workerAssignmentId) {
			comment
			createdAt
			id
			ratePoint
		}
	}
`

export const CHECK_IN_JOB_MUTATION = gql`
	mutation checkInJob($workerAssignmentId: String!) {
		checkInJob(workerAssignmentId: $workerAssignmentId) {
			success
		}
	}
`
export const PAUSE_JOB_MUTATION = gql`
	mutation pauseJob($workerAssignmentId: String!) {
		pauseJob(workerAssignmentId: $workerAssignmentId) {
			success
		}
	}
`

export const RESUME_JOB_MUTATION = gql`
	mutation resumeJob($workerAssignmentId: String!) {
		resumeJob(workerAssignmentId: $workerAssignmentId) {
			success
		}
	}
`

export const CHECK_OUT_MUTATION = gql`
	mutation checkOutJob($workerAssignmentId: String!) {
		checkOutJob(workerAssignmentId: $workerAssignmentId) {
			success
		}
	}
`
