import gql from 'graphql-tag'
import { JOB_DETAIL_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetail($id: String!) {
		job(id: $id) {
			...jobDetailFragment
			jobApplicant {
				status
			}
			workerAssignment {
				id
				status
				availableToRate
				permittedActions
				scheduledEndTime
				scheduledStartTime
				isExtend
				isCancelled
				availableToExtend
				actualEndTime
				actualStartTime
				acceptedAt
				hasExtendRequest
				rating {
					comment
					createdAt
					id
					ratePoint
				}
			}
		}
	}
	${JOB_DETAIL_FRAGMENT}
`
export const GET_APPLICANT_ASSIGNMENT_QUERY = gql`
	query SearchWorkerAssignments($after: String, $before: String, $first: Int, $last: Int, $filter: WorkerAssignmentFilter) {
		searchWorkerAssignments(after: $after, before: $before, first: $first, last: $last, filter: $filter) {
			nodes {
				job {
					jobApplicant {
						status
					}
					workerAssignment {
						id
						status
						permittedActions
						availableToRate
						scheduledEndTime
						scheduledStartTime
						isExtend
						isCancelled
						availableToExtend
						actualEndTime
						actualStartTime
						acceptedAt
						hasExtendRequest
						rating {
							comment
							createdAt
							id
							ratePoint
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
		}
	}
`

export const GET_WORKED_TIMESHEETS_QUERY = gql`
	query GetWorkedTimesheets($workerAssignmentId: String!, $first: Int!) {
		workedTimesheets(workerAssignmentId: $workerAssignmentId, first: $first) {
			...WorkedTimesheetFragment
		}
	}

	fragment WorkedTimesheetFragment on WorkedTimesheetConnection {
		nodes {
			adjustedWorkedHours
			endDatetime
			id
			startDatetime
			status
			totalBreakTime
			workedHours
		}
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		totalCount
	}
`
export const GET_WORKED_SHIFT_ACTIVES_QUERY = gql`
	query getSummaryWorkedShiftActivities($workerAssignmentId: String!) {
		summaryWorkedShiftActivities(workerAssignmentId: $workerAssignmentId) {
			checkInTime
			checkOutTime
			totalBreakMinutes
			totalWorkedHours
		}
	}
`
