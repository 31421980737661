import { Grid } from '@material-ui/core'
import { FormMode } from '@opus/web.core.form.utils'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AutoCompleteField, PhoneField, TextField } from '~/components/fields'
import { masterStore } from '~/stores'
import { Typography } from '~/components/typography'
import { CityField } from '~/components/fields/city-field/city-field.component'
import { FIELD_MODE } from '~/common/constants'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

export const ReferenceForm = observer(({ mode = FormMode.Edit, singleColumn, formName, index }) => {
	const { t } = useTranslation()
	const { referenceRelationship, workLocations } = masterStore
	const { setFieldValue, values } = useFormikContext()
	const hasCities = !isEmpty(values[formName]?.[index]?.['state'])
		? workLocations.find((state) => state.value === values[formName]?.[index]?.['state'])?.hasCities
		: true

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						className={'contactFirstName'}
						name={`${formName}.${index}.firstName`}
						placeholder={t('$PLACEHOLDERS.FIRST_NAME')}
						label={t('FIRST_NAME')}
						mode={mode}
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						className={'contactLastName'}
						name={`${formName}.${index}.lastName`}
						placeholder={t('$PLACEHOLDERS.LAST_NAME')}
						label={t('LAST_NAME')}
						mode={mode}
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						className={'emailField'}
						name={`${formName}.${index}.email`}
						mode={mode}
						label={t('EMAIL_ADDRESS')}
						placeholder={t('$PLACEHOLDERS.EMAIL')}
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<PhoneField placeholder={t('$PLACEHOLDERS.PHONE')} name={`${formName}.${index}.phone`} label={t('PHONE_NUMBER')} mode={mode} />
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField placeholder={t('$PLACEHOLDERS.JOB_TITLE')} name={`${formName}.${index}.jobTitle`} label={t('JOB_TITLE')} mode={mode} />
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<AutoCompleteField
						options={referenceRelationship}
						placeholder={t('$PLACEHOLDERS.RELATIONSHIPS')}
						name={`${formName}.${index}.relationship`}
						label={t('RELATIONSHIP')}
						mode={mode}
						allowCustomValue
					/>
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<AutoCompleteField
						endLabel={
							<Typography color="textSecondary" variant="p1">
								{t('OPTIONAL')}
							</Typography>
						}
						handleWithCurrentValue={(currentValue) => {
							if (!!currentValue) {
								setFieldValue(`${formName}.${index}.city`, undefined)
							}
						}}
						multiple={false}
						name={`${formName}.${index}.state`}
						label={t('STATE')}
						placeholder={t('$PLACEHOLDERS.STATE')}
						mode={mode}
						options={workLocations}
						allowCustomValue
					/>
				</Grid>

				{hasCities && (
					<Grid item xs={12} md={singleColumn ? 12 : 6}>
						<CityField
							endLabel={
								<Typography color="textSecondary" variant="p1">
									{t('OPTIONAL')}
								</Typography>
							}
							showStateCode={false}
							id={'city'}
							name={`${formName}.${index}.city`}
							label={t('CITY')}
							placeholder="$PLACEHOLDERS.CITY"
							mode={FIELD_MODE.edit}
							state={`${formName}.${index}.state`}
						/>
					</Grid>
				)}
			</Grid>
		</>
	)
})
