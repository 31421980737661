import React, { FC, ReactNode, createContext } from 'react'
import { IDataService } from '../interfaces'

export const DataContext = createContext<Partial<IDataService>>({})

export interface IDataProviderProps {
	dataService: IDataService
	children?: ReactNode
}

export const DataProvider: FC<IDataProviderProps> = ({ dataService, children }) => {
	return <DataContext.Provider value={dataService}>{children}</DataContext.Provider>
}
