import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { Button } from '~/components/button'
import React, { useEffect } from 'react'
import { useStyles } from '~/components/action-dialog/action-dialog.style'
import { Clear } from '@material-ui/icons';

export const ActionDialog = ({
	dialogTitle,
	showDialog,
	onClose,
	dialogActions,
	children,
	primaryAction,
	onPrimaryAction,
	secondaryAction,
	onSecondaryAction,
	onInit,
	showClose,
	...rest
}) => {
	const classes = useStyles()

	useEffect(() => {
		typeof onInit === 'function' && onInit()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Dialog open={showDialog} onClose={onClose} {...rest}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<DialogTitle className={classes.title} id="form-dialog-title">
					{dialogTitle}
				</DialogTitle>
				{showClose && (
					<IconButton onClick={() => onClose()}>
						<Clear style={ { fill: "#424242" }} />
					</IconButton>
				)}
			</Box>

			<DialogContent>{children}</DialogContent>
			<DialogActions className={classes.actions}>
				{dialogActions ? (
					dialogActions
				) : (
					<>
						<Button onClick={onPrimaryAction} color="primary" variant="outlined">
							{primaryAction}
						</Button>
						<Button onClick={onSecondaryAction} color="primary">
							{secondaryAction}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	)
}
