import React, { useCallback, useMemo, useState } from 'react'
import { useField } from 'formik'
import { TextField as MuiTextField } from '@material-ui/core'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { socialNumberFieldStyle } from './social-number-field.style'
import NumberFormat from 'react-number-format'
import { compose, required, socialSecurityNumber } from '~/common/validator'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'

const FORMAT = {
	view: 'xxx-xx-####',
	edit: '###-##-####',
}

const SocialNumberFormat = ({ inputRef, onChange, value, ...rest }) => {
	const format = useMemo(() => (value?.startsWith('xxx-xx') ? FORMAT.view : FORMAT.edit), [value])

	const [inputValue, setInputValue] = useState(value)

	const onValueChange = useCallback(
		(values) => {
			const updatedValue = format === FORMAT.view ? null : values.formattedValue
			setInputValue(updatedValue)
		},
		// eslint-disable-next-line
		[format, rest.name]
	)

	useDebounce(
		() => {
			if (inputValue !== value) {
				onChange({ target: { name: rest.name, value: inputValue } })
			}
		},
		300,
		[inputValue]
	)

	useDebounce(
		() => {
			if (value !== inputValue) {
				setInputValue(value)
			}
		},
		1000,
		[inputValue, value]
	)

	return <NumberFormat {...rest} value={inputValue} getInputRef={inputRef} onValueChange={onValueChange} format={format} />
}

export const SocialNumberField = ({ name, validate, label, placeholder, mode }) => {
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	if (mode === FIELD_MODE.view) {
		return <LabelField name={name} label={t(label)} />
	}

	return (
		<MuiTextField
			id={name}
			label={t(label)}
			placeholder={placeholder && t(placeholder)}
			helperText={mode === FIELD_MODE.edit && error}
			error={mode === FIELD_MODE.edit && !!error}
			css={socialNumberFieldStyle}
			InputProps={{ inputComponent: SocialNumberFormat }}
			{...field}
		/>
	)
}

SocialNumberField.defaultProps = {
	label: 'SOCIAL_SECURITY_NUMBER',
	placeholder: '$PLACEHOLDERS.SOCIAL_SECURITY_NUMBER',
	validate: compose(required, socialSecurityNumber),
	mode: FIELD_MODE.edit,
}
