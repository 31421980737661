import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { NotifyDialog } from '../notify'
import { observer } from '~/common/mobx.decorator'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'
import { handleLoadReCaptcha } from '~/common/helpers'
import { notifyStore } from '~/stores'
import { ERROR_CODE } from '~/common/constants'
import { OtpDialogInput } from '~/components/otp-dialog/otp-dialog-input.component'
import { ACTIATION_TRACKING_CONSTANT } from '~/features/care-activation/care-activation.constant'
import { logClickEvent } from '~/common/tracking/event-client.tracking'

export const OtpDialog = observer(({ dialogTitle, showDialog, onCancel, onClose, onOk, phoneNumber, okText, isShowUpdatePhone = true }) => {
	const {
		remainingOtpTotalRetries,
		handleSubmitOTP,
		handleSendOTP,
		errorCode,
		submitErrorCode,
		fetchInitialVerifyData,
		loadingInitial,
		showDialogOtp,
		resendTimeLeft,
		initialErrorCode,
	} = otpDialogStore

	useEffect(() => {
		if (!showDialog) {
			return
		}
		handleLoadReCaptcha()
		void fetchInitialVerifyData(phoneNumber)
	}, [fetchInitialVerifyData, showDialog, phoneNumber])

	const onClickResend = async (helper) => {
		await handleSendOTP({ phoneNumber }, helper)
	}

	const onSubmitOtp = (values, helper) => {
		const submitData = {
			phoneNumber,
			otpCode: values.otpCode,
		}
		void handleSubmitOTP(submitData, helper, onOk).catch((err) => {
			notifyStore.error(err?.message)
		})
	}

	if (loadingInitial || !showDialog) {
		return null
	}

	return !initialErrorCode ? (
		<>
			{remainingOtpTotalRetries <= 0 && !showDialogOtp ? (
				<NotifyDialog
					eventName={ACTIATION_TRACKING_CONSTANT.SCREEN_PHONE_CHANGE_LIMIT_6_TIMES}
					showButtonClose={true}
					onClose={() => {
						typeof onClose === 'function' && onClose()
					}}
					title="You have requested the verification code for 6 times"
					onOk={() => {
						logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_OK_BTN_PHONE_CHANGE_LIMIT_6_TIMES)
						onClose()
					}}
					okText="I got it"
				>
					<Typography color="textSecondary">Phone verification feature is temporarily locked. Please contact our support team for assistance.</Typography>
				</NotifyDialog>
			) : (
				<>
					{errorCode === ERROR_CODE.PHONE_EXISTED && (
						<NotifyDialog
							eventName={ACTIATION_TRACKING_CONSTANT.PHONE_EXISTED}
							showButtonClose={true}
							onClose={() => {
								typeof onClose === 'function' && onClose()
							}}
							title="Phone number already exists"
							onOk={() => {
								logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_UPDATE_PHONE_BTN_PHONE_EXISTED)
								onClose()
							}}
							okText="Update phone number"
						>
							<Typography color="textSecondary">
								Oops! It looks like the phone number{' '}
								<strong style={{ color: '#333335' }}>{phoneNumber?.replace('+1', '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</strong> you entered is
								already associated with an existing account. Please use a different phone number to create your account or contact our support for assistance.
							</Typography>
						</NotifyDialog>
					)}

					{errorCode === ERROR_CODE.CHANGE_LIMIT && (
						<NotifyDialog
							eventName={ACTIATION_TRACKING_CONSTANT.PHONE_CHANGE_LIMIT_REACHED}
							showButtonClose={true}
							onClose={() => {
								typeof onClose === 'function' && onClose()
							}}
							title="Phone number change limit reached"
							onOk={() => {
								logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_OK_BTN_PHONE_CHANGE_LIMIT_REACHED)
								onClose()
							}}
							okText="I got it"
						>
							<Typography color="textSecondary">
								You'll need to wait until the beginning of the next month to make another change. If you have an urgent need or any concerns, please don't
								hesitate to contact our support team for assistance.
							</Typography>
						</NotifyDialog>
					)}

					{(errorCode === '' ||
						[ERROR_CODE.INVALID_PHONE, ERROR_CODE.SESSION_LOCK, ERROR_CODE.PERMANENT_LOCK].includes(errorCode) ||
						['invalid_otp', 'twilio_error'].includes(submitErrorCode)) && (
						<OtpDialogInput
							errorCode={errorCode}
							onSubmitOtp={onSubmitOtp}
							onClose={onClose}
							phoneNumber={phoneNumber}
							onCancel={onCancel}
							resendTimeLeft={resendTimeLeft}
							onClickResend={onClickResend}
						/>
					)}
				</>
			)}
		</>
	) : (
		initialErrorCode === ERROR_CODE.OTP_REQUEST_LIMITED && (
			<NotifyDialog
				eventName={ACTIATION_TRACKING_CONSTANT.SPAM_OTP}
				showButtonClose={true}
				onClose={() => {
					typeof onClose === 'function' && onClose()
				}}
				title="You can only request one verification code per minute"
				onOk={() => {
					logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_OK_BTN_SPAM_OTP)
					onClose()
				}}
				okText="I got it"
			>
				<Typography color="textSecondary">Please wait a moment and try again later.</Typography>
				<Typography color="textSecondary">Thank you for your understanding.</Typography>
			</NotifyDialog>
		)
	)
})
