import { gql } from '@apollo/client'
import { EMPLOYER_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const VERIFY_EMPLOYER_QUERY = gql`
	query verifyEmployer($token: String!) {
		employerToken(token: $token) {
			token
			employer {
				...employerFragment
			}
		}
	}

	${EMPLOYER_FRAGMENT}
`
