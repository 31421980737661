import React from 'react'
import { Grid } from '@material-ui/core'
import { FormMode } from '@opus/web.core.form.utils'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { AutoCompleteField, DateField, TextField } from '~/components/fields'
import { masterStore } from '~/stores'
import { FIELD_MODE } from '~/common/constants'
import { useFormikContext } from 'formik'
import { CityField } from '~/components/fields/city-field/city-field.component'
import { isEmpty } from 'lodash'

export const EducationForm = ({ mode = FormMode.Edit, singleColumn, formName, index }) => {
	const { t } = useTranslation()
	const { educationDegrees, workLocations } = masterStore
	const { setFieldValue, values } = useFormikContext()

	const hasCities = !isEmpty(values[formName][index]['state'])
		? workLocations.find((state) => state.value === values[formName][index]['state'])?.hasCities
		: true

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<TextField name={`${formName}.${index}.schoolName`} label={t('SCHOOL_UNIVERSITY')} placeholder={t('$PLACEHOLDERS.SCHOOL_NAME')} mode={mode} />
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<AutoCompleteField
					handleWithCurrentValue={(currentValue) => {
						if (!!currentValue) {
							setFieldValue(`${formName}.${index}.city`, undefined)
						}
					}}
					multiple={false}
					name={`${formName}.${index}.state`}
					label={t('STATE')}
					placeholder={t('$PLACEHOLDERS.STATE')}
					mode={mode}
					options={workLocations}
					allowCustomValue
				/>
			</Grid>
			{!!hasCities && (
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<CityField
						showStateCode={false}
						id={'city'}
						name={`${formName}.${index}.city`}
						label={t('CITY')}
						placeholder="$PLACEHOLDERS.CITY"
						mode={FIELD_MODE.edit}
						state={`${formName}.${index}.state`}
					/>
				</Grid>
			)}

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<AutoCompleteField
					multiple={false}
					name={`${formName}.${index}.degreeName`}
					label={t('DEGREE')}
					placeholder={t('$PLACEHOLDERS.DEGREE_NAME')}
					mode={mode}
					options={educationDegrees}
					allowCustomValue
				/>
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<TextField name={`${formName}.${index}.major`} label={t('MAJOR')} placeholder={t('$PLACEHOLDERS.MAJOR')} mode={mode} />
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<DateField
					name={`${formName}.${index}.graduationDate`}
					label="GRADUATION_DATE"
					placeholder="$PLACEHOLDERS.GRADUATION_DATE"
					mode={mode}
					disableTyping={true}
				/>
			</Grid>
		</Grid>
	)
}
