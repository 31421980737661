export const EVENT_CLICK_ID = {
	//JH Add New
	clickContinueBtn: 'continue_btn',
	clickSignInWithAppleBtn: 'sign-in-with-apple_btn',
	clickSignInWithGoogleBtn: 'sign-in-with-google_btn',
	clickSignInWithFacebookBtn: 'sign-in-with-facebook_btn',
	clickSignInWithLinkedinBtn: 'sign-in-with-linkedin_btn',
	clickSkipForNowLink: 'skip-for-now_link',
	clickForgotPassLink: 'forgot-pass_link',
	clickLoginBtn: 'login_btn',
	clickSendMyCodeBtn: 'send-my-code_btn',
	clickGotItBtn: 'got-it_btn',
	clickResetPassBtn: 'reset-pass_btn',
	clickResetPassBtnOk: 'reset-pass_btn_ok',
	clickTermsOfServiceLinkSignup: 'terms-of-service_link_signup',
	clickPrivacyPolicyLinkSignup: 'privacy-policy_link_signup',
	clickContinueBtnSignup: 'continue_btn_signup',
	clickBackSignupBtn: 'back_btn_signup',
	clickSignInHereLinkSignup: 'sign-in-here_link_signup',
	clickSupportEmailLinkSignup: 'support-email_link_signup',
	clickSupportPhoneLinkSignup: 'support-phone_link_signup',
	clickHomeNavBtn: 'home_btn',
	clickSearchJobsNavBtn: 'search-jobs_btn',
	clickMyJobsNavBtn: 'my-jobs_btn',
	clickMessagesNavBtn: 'messages_btn',
	clickTimecardsNavBtn: 'timecards_btn',
	clickProfileNavBtn: 'profile_btn',
	clickContactMyRecruiterNavBtn: 'contact-my-recruiter_btn',
	clickReferATravelerNavBtn: 'refer-a-traveler_btn',
	clickChangePasswordNavBtn: 'change-password_btn',
	clickLogoutNavBtn: 'logout_btn',
	clickContactUsNavBtn: 'contact-us_btn',
	clickPrivacyPolicyNavBtn: 'privacy-policy_btn',
	clickCompleteYourProfileNavBtn: 'complete-your-profile_btn',
	clickSettingsNavBtn: 'settings_btn',
	clickFacebookNavBtn: 'facebook_btn',
	clickInstagramNavBtn: 'instagram_btn',
	clickJobsBtnDiscoverAvailable: 'jobs-discover_btn_available',
	clickJobsBtnDiscoverTrending: 'jobs-discover_btn_trending',
	clickDiscoverLastSearchsBtn: 'discover-last-searchs_btn',
	clickGuidanceStepsToCompleteBtn: 'guidance-steps-to-complete_btn',
	clickGuidanceExtendBtn: 'guidance-extend_btn',
	clickGuidanceOtherSimilarJobsBtn: 'guidance-other-similar-jobs_btn',
	clickSeeMoreShortcutListLink: 'see-more-shortcut-list_link',
	clickLikeShortcutListBtn: 'like_btn_shortcut-list',
	clickDislikeShortcutListBtn: 'dislike_btn_shortcut-list',
	clickShareShortcutListBtn: 'share_btn_shortcut-list',
	clickAccessJobDetailShortcutListBtn: 'access-job-detail_btn_shortcut-list',
	clickSignupToViewDetailBtnShortcutList: 'signup-to-view-detail_btn_shortcut-list',
	clickLikeBtnLandingList: 'like_btn_landing-list',
	clickDislikeBtnLandingList: 'dislike_btn_landing-list',
	clickShareBtnLandingList: 'share_btn_landing-list',
	clickAccessJobDetailBtnLandingList: 'access-job-detail_btn_landing-list',
	clickSignupToViewDetailBtnLandingList: 'signup-to-view-detail_btn_landing-list',
	clickFilterIconBtnLandingList: 'filter-icon_btn_landing-list',
	clickSeeMoreShortcutListLinkPopularCities: 'see-more-shortcut-list_link_popular-cities',
	clickAccessShortcutListPopularCitiesBtn: 'access-shortcut-list_popular-cities_btn',
	clickAccessLandingListPopularCitiesBtn: 'access-popular-cities-landing-list_btn',
	backJobDetailBtn: 'back-job-detail_btn',
	clickSignupToViewJobDetailLink: 'signup-to-view-job-detail_link',
	clickSignupToApplyJobDetailBtn: 'signup-to-apply-job-detail_btn',
	clickLikeJobDetailBtn: 'like_btn_job-detail',
	clickDislikeJobDetailBtn: 'dislike_btn_job-detail',
	clickShareJobDetailBtn: 'share_btn_job-detail',
	clickViewMapJobDetailLink: 'view-map_link_job-detail',
	clickViewMoreJobDetailLink: 'view-more_link_job-detail',
	clickApplyJobDetailBtn: 'apply-job-detail_btn',
	clickPreviousJobDetailBtn: 'previous-job-detail_btn',
	clickNextJobDetailBtn: 'next-job-detail_btn',
	clickCompleteOnboardingToApplyJobDetailBtn: 'complete-onboarding-to-apply-job-detail_btn',
	clickExtendPlacementJobDetailBtn: 'extend-placement-job-detail_btn',
	clickContactMyRecruiterJobDetailBtn: 'contact-my-recruiter-job-detail_btn',
	clickConfirmExtendPlacementJobDetailBtn: 'confirm-extend-placement-job-detail_btn',
	cancelExtendPlacementJobdetailBtn: 'cancel-extend-placement-job-detail_btn',
	clickReferNowReferralBtn: 'refer-now-referral_btn',
	clickTermConditionsReferralLink: 'term-conditions-referral_link',
	clickConfirmChangePassBtn: 'confirm-change-pass_btn',
	clickTabFavoritesBtn: 'tab-favorites_btn',
	clickTabAppliedBtn: 'tab-applied_btn',
	clickTabActiveBtn: 'tab-active_btn',
	clickSearchByJobIdToggle: 'search-by-job-id_toggle',
	clickSearchJobsWithJobIdBtn: 'search-jobs_btn_with-job-id',
	clickShowOnlyExclusiveJobsSearchJobsCb: 'show-only-exclusive-jobs_cb_search-jobs',
	clickSearchJobsWithoutJobIdBtn: 'search-jobs_btn_without-job-id',
	clickSortBtn: 'sort_btn',
	clickFilterTagBtn: 'filter-tag_btn',
	clickShowOnlyExclusiveJobsFilterJobsCb: 'show-only-exclusive-jobs_cb_filter-jobs',
	clickSearchFilterJobsBtn: 'search-filter-jobs_btn',
	clickSaveSearchFilterJobsBtn: 'save-search-filter-jobs_btn',
	clickSaveFilterJobsBtn: 'save-filter-jobs_btn',
	clickCancelFilterJobsBtn: 'cancel-filter-jobs_btn',
	clickCurrentTabBtn: 'current-tab_btn',
	clickHistoryTabBtn: 'history-tab_btn',
	clickSubmitTimecardBtn: 'submit-timecard_btn',
	clickUploadResumeApplicationBtn: 'upload-resume_btn_application',
	clickSkillSpecialtyApplicationBtn: 'skill-specialty-application_btn',
	clickProvideReferencesApplicationBtn: 'provide-references_btn_application',
	clickReferenceApplicationBtn: 'reference-application_btn',
	clickConfirmationApplicationBtn: 'confirmation-application_btn',
	clickStartUploadingResumeBtn: 'start-uploading-resume_btn',
	clickStep1Btn: 'step-1_btn',
	clickStep2Btn: 'step-2_btn',
	clickStep3Btn: 'step-3_btn',
	clickStep4Btn: 'step-4_btn',
	clickStep5Btn: 'step-5_btn',
	clickBackApplicationBtn: 'back-application_btn',
	clickSkipApplicationBtn: 'skip-application_btn',
	clickSubmitResumeStep1: 'submit-resume-step-1_btn',
	clickUploadResumeBtnStep1: 'upload-resume_btn_step-1',
	clickProvideReferencesBtnStep2: 'provide-references_btn_step-2',
	clickBackBtnStep2: 'back_btn_step-2',
	clickSpecialtyCategoryBtnStep2: 'specialty-category_btn_step-2',
	clickSpecialtyBtnStep2: 'specialty_btn_step-2',
	clickSkillChecklistActionMenuBtnStep2: 'skill-checklist-action-menu_btn_step-2',
	clickDocumentTypeBtnStep2: 'document-type_btn_step-2',
	clickUploadDocumentBtnStep2: 'upload-document_btn_step-2',
	clickSetPrimarySpecialtyBtnStep2: 'set-primary-specialty_btn_step-2',
	clickUpdateSkillChecklistBtnStep2: 'update-skill-checklist_btn_step-2',
	clickDeleteSkillChecklistBtnStep2: 'delete-skill-checklist_btn_step-2',
	clickProficiencyBtnStep2: 'proficiency_btn_step-2',
	clickFrequencyBtnStep2: 'frequency_btn_step-2',
	clickSubmitChecklistBtnStep2: 'submit-checklist_btn_step-2',
	clickCancelChecklistBtnStep2: 'cancel-checklist_btn_step-2',
	clickSubmitBtnStep3: 'submit_btn_step-3',
	clickBackBtnStep3: 'back_btn_step-3',
	clickSubmitBtnStep4: 'submit_btn_step-4',
	clickBackBtnStep4: 'back_btn_step-4',
	clickQuestionYesBtnStep5: 'question-yes-step-5_btn',
	clickQuestionNoBtnStep5: 'question-no-step-5_btn',
	clickAgreeBtnStep5: 'agree-step-5_cb',
	clickSubmitBtnStep5: 'submit_btn_step-5',
	clickBackBtnStep5: 'back_btn_step-5',
	clickInfoTabBtn: 'info-tab_btn',
	clickSkillsChecklistTabBtn: 'skills-checklist-tab_btn',
	clickResumeTabBtn: 'resume-tab_btn',
	clickReferencesTabBtn: 'references-tab_btn',
	clickEditBtnInfoTab: 'edit_btn_info-tab',
	updateEditBtnInfoTab: 'update_btn_info-tab',
	clickEditBtnSkillsChecklistTab: 'edit_btn_skills-checklist-tab',
	clickUpdateBtnSkillsChecklistTab: 'update_btn_skills-checklist-tab',
	clickCancelBtnSkillsChecklistTab: 'cancel_btn_skills-checklist-tab',
	clickSpecialtyCategoryBtnSkillsChecklistTab: 'specialty-category_btn_skills-checklist-tab',
	clickSpecialtyBtnSkillsChecklistTab: 'specialty_btn_skills-checklist-tab',
	clickSkillChecklistActionMenuBtnSkillsChecklistTab: 'skill-checklist-action-menu_btn_skills-checklist-tab',
	clickDocumentTypeBtnSkillsChecklistTab: 'document-type_btn_skills-checklist-tab',
	clickUploadDocumentBtnSkillsChecklistTab: 'upload-document_btn_skills-checklist-tab',
	clickSetPrimarySpecialtyBtnSkillsChecklistTab: 'set-primary-specialty_btn_skills-checklist-tab',
	clickUpdateSkillChecklistBtnSkillsChecklistTab: 'update-skill-checklist_btn_skills-checklist-tab',
	clickDeleteSkillChecklistBtnSkillsChecklistTab: 'delete-skill-checklist_btn_skills-checklist-tab',
	clickProficiencyBtnSkillsChecklistTab: 'proficiency_btn_skills-checklist-tab',
	clickFrequencyBtnSkillsChecklistTab: 'frequency_btn_skills-checklist-tab',
	clickSubmitChecklistBtnSkillsChecklistTab: 'submit-checklist_btn_skills-checklist-tab',
	clickCancelChecklistBtnSkillsChecklistTab: 'cancel-checklist_btn_skills-checklist-tab',
	clickUploadResumeBtnResumeTab: 'upload-resume_btn_resume-tab',
	clickAddWorkExperienceBtnResumeTab: 'add-work-experience_btn_resume-tab',
	clickAddEducationBtnResumeTab: 'add-education_btn_resume-tab',
	clickEditWorkExperienceBtnResumeTab: 'edit-work-experience_btn_resume-tab',
	clickEditEducationBtnResumeTab: 'edit-education_btn_resume-tab',
	clickSaveWorkExperienceBtnResumeTab: 'save-work-experience_btn_resume-tab',
	clickCancelWorkExperienceBtnResumeTab: 'cancel-work-experience_btn_resume-tab',
	clickSaveEducationBtnResumeTab: 'save-education_btn_resume-tab',
	clickCancelEducationBtnResumeTab: 'cancel-education_btn_resume-tab',
	clickEditBtnReferencesTab: 'edit_btn_references-tab',
	clickUpdateBtnReferencesTab: 'update_btn_references-tab',
	clickCancelBtnReferencesTab: 'cancel_btn_references-tab',
}

export const CORE_BEHAVIOR_TRACKING = {
	resumeUploadSuccess: 'RESUME_UPLOAD_SUCCESS',
	resumeUploadFailed: 'RESUME_UPLOAD_FAILED',
	applicationSentSuccess: 'APPLICATION_SENT_SUCCESS',
	applicationSentFailed: 'APPLICATION_SENT_FAILED',
	removeSavedSearchesDeleteSuccess: 'REMOVE-SAVED-SEARCHES_DELETE_SUCCESS',
	removeSavedSearchesDeleteFailed: 'REMOVE-SAVED-SEARCHES_DELETE_FAILED',
	addNewSearchAddSuccess: 'ADD-NEW-SEARCH_ADD_SUCCESS',
	addNewSearchAddFailed: 'ADD-NEW-SEARCH_ADD_FAILED',
	editSearchSaveSuccess: 'EDIT-SEARCH_SAVE_SUCCESS',
	editSearchSaveFailed: 'EDIT-SEARCH_SAVE_FAILED',
}

export const TYPE_JOB = {
	searchResults: 'search-results',
	actives: 'actives',
	applied: 'applied',
	favorites: 'favorites',
	exploreMatches: 'explore-matches',
	availableJobs: 'available-jobs',
	popularCitiesJobs: 'popular-cities-jobs',
	lastSearchJobs: 'last-search-jobs',
	lastViewJobs: 'last-view-jobs',
	hotJobs: 'hot-jobs',
}

export const EVENT_DEFAULT_ID = {
	defaultSearchResult: 'search-job-result',
	loadInitialLanding: 'load-initial_job_landing',
	loadMoreLanding: 'load-more-job_landing',
}
