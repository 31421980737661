import styled, { css } from 'styled-components'
import { ellipsis } from '~/themes/mixins'
import { Accordion, AccordionDetails, AccordionSummary, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const downloadContainer = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	.upload {
    width: 100%;
		> p {
			font-weight: 700;
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.upload-edit {
		width: 70%;
		> p {
			font-weight: 700;
			color: ${({ theme }) => theme.palette.content.veryDark};
		}
		.filename-pdf {
			${ellipsis}
			white-space:nowrap;
			width: min(35ch, 52vw);
		}
	}
	}
`

export const StyledIframeWrapper = styled.div`
	text-align: center;
	height: 100%;
	.formFooter {
		display: none;
	}
	.MuiCircularProgress-colorPrimary {
		position: absolute;
		top: 40%;
		left: 48%;
	}
`
export const formWrapperStyle = css`
	.MuiFormLabel-root {
		color: ${({ theme }) => theme.colors.primary.main} !important;
	}
`

export const StyledAccordionSummary = withStyles((theme) => ({
	root: {
		flexDirection: 'row-reverse',
		padding: 0,
		gap: '12px',
		'@media (max-width: 800px)': {
			alignItems: 'flex-start',
			marginBottom: '10px',
			'& .MuiAccordionSummary-content': {
				marginTop: 0,
			},
			'& .credential-container': {
				flexDirection: 'column',
				gap: '5px',
			},
		},
		'@media (max-width: 600px)': {
			'& .credential-label': {
				flexDirection: 'column',
				gap: '5px',

				'& .credential-status-label': {
					maxWidth: 'max-content',
				},
			},
		},

		'&$expanded': {
			minHeight: 32,
			margin: 0,
		},
		'& .can-expand': {
			width: 24,
			height: 24,
			borderRadius: 6,
			backgroundColor: theme.palette.background.element.light,
			color: theme.palette.secondary.main,
			cursor: 'pointer',
		},
		'& .disabled': {
			cursor: 'none',
			width: 24,
			height: 24,
			borderRadius: 6,
			backgroundColor: theme.palette.content.veryLight,
			color: theme.palette.secondary.main,
		},
	},
	expandIcon: {
		width: 24,
		height: 24,
		borderRadius: 6,
		backgroundColor: theme.palette.background.element.light,
		color: theme.palette.secondary.main,

		'&.disabled': {
			color: theme.palette.grey[400],
			backgroundColor: theme.palette.background.element.light,
			cursor: 'default',
		},

		'&:hover': {
			backgroundColor: theme.palette.background.element.light,
		},
	},
	expanded: {
		'& .MuiAccordionSummary-expandIcon': {
			backgroundColor: theme.palette.background.element.light,
			color: theme.palette.secondary.main,
		},
	},
	content: {
		'&$expanded': {
			margin: '0',
		},
	},
}))(AccordionSummary)

export const StyledAccordion = withStyles((theme) => ({
	root: {
		boxShadow: 'none',
		'& .MuiAccordionSummary-content': {
			margin: '12px',
		},
		'&.Mui-expanded': {
			margin: 0,
		},

		'&:before': {
			display: 'none',
		},
	},
}))(Accordion)

export const StyledAccordionDetails = withStyles((theme) => ({
	root: {
		padding: '0',
	},
}))(AccordionDetails)

export const useStyleForJotformDialog = makeStyles((theme) => ({
	paperRoot: {
		width: 'min(818px,100%)',
		height: '780px',
		borderRadius: '1rem',
		'& ::-webkit-scrollbar': {
			display: 'none',
		},
	},

	jotForm: {
		borderRadius: '8px',
		border: '1px solid #E5E5E5',
		flexGrow: '1',
	},
}))

export const skillChecklistStyle = css`
	display: flex;
	justify-content: space-between;
	.MuiSvgIcon-root {
		cursor: pointer;
		margin-right: 10px;
		path {
			stroke: ${({ theme }) => theme.palette.content.veryDark};
		}
	}
`
