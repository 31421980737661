import { orderBy } from 'lodash'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { createWorkerCredentials, getWorkerCredentials } from '../../care-activation.service'

@store()
class CredentialsTabStore {
	@observable workerCredentials

	@observable credentialsCreating = false

	@observable credentialsUpdating = false
	@observable credentialsCurrentId = ''
	@action
	setCredentialsCurrentId = (flag) => {
		this.credentialsCurrentId = flag
	}
	@action
	setCredentialsUpdating = async (flag) => {
		this.credentialsUpdating = flag
	}
	@computed
	get credentials() {
		const credentials = []

		return orderBy(credentials, ['createdAt'], ['asc'])
	}

	@action
	fetchWorkerCredentials = async () => {
		this.workerCredentials = await getWorkerCredentials(authStore.id)
	}

	@action
	handleSubmitCredentials = async ({ location, ...data }) => {
		try {
			const credentials = { ...data, city: location.city, state: location.state }
			await createWorkerCredentials(credentials)
			await this.fetchWorkerCredentials()
			this.setCredentialsCreating(false)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	setCredentialsCreating = async (flag) => {
		this.credentialsCreating = flag
	}
	@action
	handleRemoveClick = async (flag) => {
		return null
	}
}

export const credentialsTabStore = new CredentialsTabStore()
