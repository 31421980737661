import { isEmpty, orderBy } from 'lodash'
import { generateTempId, isTempId } from '~/common/helpers/uid.helper'
import { INTEGRATION_OBJECTS } from './constants'

export const convertPartToLicenses = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart

	const workerCertifications = isEmpty(integration?.workerCertifications) ? null : integration?.workerCertifications

	return {
		workerCertifications: orderBy(workerCertifications, 'createdAt'),
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToExperiences = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	const workExperiences = isEmpty(integration?.workExperiences) ? [{ id: generateTempId() }] : integration.workExperiences

	const mapData = workExperiences?.map(({ city, state, country, ...rest }) => ({
		...rest,
		location: { city, state, country },
	}))

	return {
		...integration,
		workExperiences: mapData,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToEducations = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	const workerEducations = isEmpty(integration?.workerEducations)
		? [{ id: generateTempId() }]
		: integration.workerEducations?.map(({ city, state, country, ...education }) => ({
				...education,
				location: { city, state, country },
		  }))

	return {
		workerEducations,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToReferences = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	let workerReferences = isEmpty(integration?.workerReferences) ? [{ id: generateTempId() }] : integration.workerReferences

	const mapData = workerReferences?.map(({ ...rest }) => ({
		...rest,
		referenceType: !isTempId(rest?.id) ? rest?.referenceType === 'former' : undefined,
		location: !isTempId(rest?.id) ? { city: rest?.city, state: rest?.state } : undefined,
	}))

	return {
		workerReferences: orderBy(mapData, 'createdAt'),
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToTerms = (workerOnboardingPart) => {
	const { integration, integrationConfigurations } = workerOnboardingPart
	const workerQuizAnswers = integrationConfigurations?.quiz?.quizQuestions?.map(({ quizAnswers }) => {
		const quizAnswer = quizAnswers?.find((answer) => !!answer.workerAnswer)

		return {
			quizAnswerId: quizAnswer?.id,
			reason: quizAnswer?.workerAnswer?.reason,
			required: quizAnswer?.requireReason,
		}
	})

	const termsOfServiceId = integration?.termsOfServiceId && integration?.termsOfServiceId === integrationConfigurations?.termsOfService?.id

	return {
		termsOfServiceId,
		workerQuizAnswers,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToProfile = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	const worker = integration?.worker
	return {
		...worker,
		specialty: worker?.workerSpecialties?.[0]?.specialtyLongName,
		discipline: worker?.workerSpecialties?.[0]?.category,
		workerAddress: worker?.workerAddress || {},
		workingPreferredLocations: worker?.workingPreferredLocations?.map((item) => item?.preferredWorkingState),
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const CONVERT_PART_TO_FORM_DATA = {
	[INTEGRATION_OBJECTS.licenses]: convertPartToLicenses,
	[INTEGRATION_OBJECTS.experiences]: convertPartToExperiences,
	[INTEGRATION_OBJECTS.educations]: convertPartToEducations,
	[INTEGRATION_OBJECTS.references]: convertPartToReferences,
	[INTEGRATION_OBJECTS.terms]: convertPartToTerms,
	[INTEGRATION_OBJECTS.profile]: convertPartToProfile,
}
