import { makeStyles } from '@material-ui/core'
import { css } from 'styled-components'

export const useClipClasses = makeStyles((theme) => ({
	root: {
		...theme.typography.subtitle2,
		border: '1px solid #E5E5E5',
		backgroundColor: '#fff',
		color: '#333335',
	},
}))

export const chipClearIconCss = css`
	width: 16px;
	height: 16px;
	font-size: 12px;
`

export const viewLabelStyle = css`
	.MuiFormLabel-root {
		color: ${({ theme }) => theme.colors.primary.main};
	}
`
