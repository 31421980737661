import { NotifyDialog } from '~/components/notify'
import { Box, Typography } from '@material-ui/core'
import { appStore } from '~/stores'
import OtpInput from 'react-otp-input'
import { Button } from '~/components/button'
import { Formik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import { emailOtpDialogStore } from '~/components/email-otp-dialog/email-otp-dialog.store'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import styled from 'styled-components'
import { ERROR_CODE } from '~/common/constants'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { careStore } from '~/companies/care/care.store'
import { ACTIATION_TRACKING_CONSTANT } from '~/features/care-activation/care-activation.constant'

const Form = styled.form`
	.title {
		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		line-height: 20px;

		letter-spacing: 0.1px;
		text-align: center;
		color: #0e3f6c;
	}

	.description {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		letter-spacing: 0.2px;

		color: #0e3f6c;

		margin: 12px 0;
	}

	.text {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 28px;

		text-align: center;

		color: #000000;
		margin: 12px 0;
	}

	.timer {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 26px;
		line-height: 32px;
		/* identical to box height, or 123% */

		text-align: center;
		letter-spacing: 0.2px;

		/* Jackson Color/primary/main */

		color: #255e8e;

		&.end {
			color: #ff7d74;
		}
	}

	.send-button {
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;

		text-align: center;
		letter-spacing: 0.2px;
		text-decoration-line: underline;

		color: #868686;
		cursor: pointer;
		margin: 40px 0;
		text-transform: uppercase;
		width: 100%;

		&:disabled {
			cursor: not-allowed;
		}
	}

	.inputStyle {
		${({ theme }) => theme.typography.h3};
		text-align: center;
		color: ${({ theme }) => theme.colors.content.veryDark};
		width: 48px !important;
		height: 56px;
		background-color: #f8f8f8;
		border: 1px solid #e6e6e6;
		border-radius: 8px;

		&:disabled {
			background: unset;
			cursor: not-allowed;
		}

		&.errorStyle {
			border: 1px solid #ff2727;
		}
	}

	.containerStyle {
		display: flex;
		justify-content: center;
		margin-top: 16px;
	}

	.separatorStyle {
		width: 16px;
	}
`
export const EmailOtpDialogInput = observer(({ onSubmitOtp, onClose, email, resendTimeLeft, firstResend, onClickResend }) => {
	const { t } = useTranslation()
	const { remainingOtpSessionRetries, remainingOtpTotalRetries, setShowDialogOtp, errorCode } = emailOtpDialogStore

	const { companyInfo } = careStore

	const contactInfo = useMemo(() => {
		const supportInfo = companyInfo?.tenantContacts?.find((item) => item.contactType === 'support')
		return {
			email: supportInfo?.email,
			phone: supportInfo?.phone,
		}
	}, [companyInfo])

	useEffect(() => {
		setShowDialogOtp(true)

		return () => {
			setShowDialogOtp(false)
		}
	}, [setShowDialogOtp])

	const displayCount = useMemo(
		() => (remainingOtpSessionRetries <= 0 && remainingOtpTotalRetries < 3 ? remainingOtpTotalRetries : remainingOtpSessionRetries),
		[remainingOtpTotalRetries, remainingOtpSessionRetries]
	)

	return (
		<Formik initialValues={{}} onSubmit={onSubmitOtp}>
			{({ handleSubmit, resetForm, handleReset, values, errors, setFieldValue, setErrors }) => {
				return (
					<NotifyDialog
						eventName={ACTIATION_TRACKING_CONSTANT.VERIFY_PHONE}
						showButtonClose={true}
						onClose={() => {
							logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_VERIFY_PHONE)
							typeof onClose === 'function' && onClose()
						}}
						title={t('EMAIL_VERIFICATION')}
						okText={'Continue'}
						onOk={() => {
							logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CONFIRM_BTN_VERIFY_PHONE)
							onSubmitOtp(values, { setErrors })
						}}
						okProps={{ disabled: (values.otpCode?.length ?? 0) < 6 }}
						onCancel={() => {
							logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_VERIFY_PHONE)
							typeof onClose === 'function' && onClose()
						}}
					>
						<Form onSubmit={handleSubmit} onReset={handleReset}>
							<Box maxWidth="100%" width="400px" display="flex" flexDirection="column" alignItems="center">
								<Typography
									color="textSecondary"
									css={{
										textAlign: 'center',
									}}
								>
									Please enter the 6 digit verification code we sent to
								</Typography>
								<Typography
									css={{
										color: appStore.getTheme.colors.content.veryDark,
										fontWeight: 800,
									}}
								>
									{email}
								</Typography>

								<OtpInput
									value={values?.otpCode}
									onChange={(value) => {
										setFieldValue('otpCode', value)
									}}
									numInputs={6}
									isInputNum={true}
									hasErrored={!!errors?.otpCode}
									errorStyle="errorStyle"
									containerStyle="containerStyle"
									inputStyle="inputStyle"
									separator={<span className="separatorStyle" />}
									shouldAutoFocus
									isDisabled={errorCode === ERROR_CODE.PERMANENT_LOCK || errorCode === ERROR_CODE.INVALID_PHONE}
								/>

								{errors.otpCode && errorCode !== ERROR_CODE.INVALID_PHONE && (
									<Box mt={1}>
										<Typography color="error">{errors.otpCode}</Typography>
									</Box>
								)}

								<Box mt={2}>
									{errorCode === '' && (
										<Box>
											{resendTimeLeft === 0 || firstResend ? (
												<Button
													size="small"
													variant="text"
													color="primary"
													onClick={async () => {
														void onClickResend({ setErrors })
														resetForm()
													}}
												>
													Resend verification code
												</Button>
											) : (
												<Typography
													color="textSecondary"
													css={{
														textAlign: 'center',
													}}
												>
													Resend the verification code: <strong style={{ color: '#333335' }}>{resendTimeLeft / 1000}s</strong>
												</Typography>
											)}

											<Typography
												color="textSecondary"
												css={{
													textAlign: 'center',
													marginTop: '8px',
												}}
											>
												You have {displayCount} time{`${displayCount > 1 ? 's' : ''}`} remaining
											</Typography>
										</Box>
									)}

									{(errorCode === ERROR_CODE.SESSION_LOCK || errorCode === ERROR_CODE.EMAIL_VERIFICATION_REQUEST_LIMITED) && (
										<Typography
											css={{
												textAlign: 'center',
												padding: '12px 0',
												color: appStore.getTheme.palette.status.warning.medium,
											}}
										>
											You have requested the verification code for 3 times this session. Try again later or contact us at
											<a
												style={{
													textDecoration: 'none',
													color: '#2C6EEE',
												}}
												href={`mailto:${contactInfo.email}`}
											>
												{' '}
												{contactInfo.email}
											</a>{' '}
											or <br />
											<a
												style={{
													textDecoration: 'none',
													color: '#2C6EEE',
												}}
												href={`tel:${contactInfo.phone}`}
											>
												{contactInfo.phone?.replace('+1', '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
											</a>
										</Typography>
									)}
								</Box>
							</Box>
						</Form>
					</NotifyDialog>
				)
			}}
		</Formik>
	)
})
