import { makeStyles } from '@material-ui/core'
import { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '48px',
	},
	contained: {
		borderRadius: '48px',
		'&.Mui-disabled': {
			color: ({ loading }) => (loading ? '#ffffff' : theme.palette.content.veryLight),
			backgroundColor: ({ loading }) => (loading ? '#C2DAF0' : '#C4C4C4'),
		},
	},
	circularSvg: {
		'& .MuiCircularProgress-circleIndeterminate': {
			strokeDasharray: '30px, 200px',
		},
	},
}))

export const circularProgressStyle = css`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: grid;

	> * {
		border-radius: 100%;
		width: 20px;
		height: 20px;
		grid-area: 1/-1;
	}

	.inner-circle {
		border: 2px solid;
	}
`
