import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
	title: {
		fontStyle: 'normal',
		fontWeight: 800,
		fontSize: '20px',
		lineHeight: '28px',
		color: theme.palette.primary.main,
		letterSpacing: '0.1px',
		paddingLeft: '16px',
		wordBreak: 'break-word',
		maxWidth: 'calc(600px - 24px)',
	},
	box: {
		padding: '12px 24px',
		maxWidth: '100vw',
		overflow: 'auto',
		maxHeight: 'calc(100vh - 20px)',
		flex: 1,
	},
}))
