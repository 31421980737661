import { apolloClient, APPLY_JOB_MUTATION, EXTEND_JOB_APPLICANT_MUTATION, GET_WORKER_DETAIL_QUERY } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { GET_JOB_DETAIL_QUERY } from '../care-jobs.graphql'
import { isEmpty } from 'lodash'
import { redirectEventTrackingBookMark } from '~/common/helpers/bookMark.helper'
import { LIKE_DISLIKE_JOB_MUTATION } from '~/common/apollo/mutations/job-applicant.mutation'
import { GET_ALL_CLOSE_JOB_QUERY, GET_APPLICATION_JOB_QUERY } from '~/common/apollo/queries/job-filter.query'
import { BOOKMARK_A_JOB_MUTATION } from '~/common/apollo/mutations/jobs-filter.mutation'
import { quickApplyStore } from '~/features/quick-apply/quick-apply.store'
import { careProfileStore } from '~/features/care-profile/care-profile.store'
import { referenceTabStore } from '~/components/additional-info-apply-dialog/tabs/reference/reference-tab.store'

@store()
class CareJobDetailStore {
	@observable job
	@observable jobItem = {}
	@observable worker = {}
	@observable showError = false
	@observable showDialog = false
	@observable allCloseJobs = []
	@observable jobApplicant = {}
	@observable showApplyDialog = false
	@observable showApplyUpdateDialog = false
	@observable title = ''
	@observable jobFavoriteAnoSync = null
	@observable isShowCheckout = false
	@observable isShowCheckoutSuccess = false
	@observable showDialogExtend = false
	@observable showExtendSuccess = false
	@observable showCheckinError = false

	@computed
	get isActivatedWorker() {
		return authStore.activated
	}

	@computed
	get phoneNotVerified() {
		return isEmpty(this.worker?.phoneVerifiedAt)
	}

	@computed
	get phoneNotExisted() {
		return isEmpty(this.worker?.phone)
	}

	@computed
	get jobTitle() {
		return this.title
	}

	@action
	resetJobApplicationStore = () => {
		this.jobApplicant = {}
	}

	@action
	setJobItem = async (jobData) => {
		this.jobItem = jobData
	}

	@action
	setShowDialog = async (state) => {
		this.showDialog = state
	}

	@action
	fetchWorkerDetail = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_DETAIL_QUERY,
			variables: { id: authStore.id },
		})
		this.worker = data.worker
	}

	@action
	setJob = (job) => {
		this.job = job
	}

	@action
	setShowApplyDialog = async (show) => {
		this.showApplyDialog = show
	}
	@action
	setShowApplyUpdateDialog = async (show) => {
		this.showApplyUpdateDialog = show
	}

	@action
	fetchJob = async (id) => {
		try {
			this.showError = false

			const response = await apolloClient.query({
				query: GET_JOB_DETAIL_QUERY,
				variables: { id },
				context: {
					clientName: authStore.id ? null : 'public',
				},
			})
			this.job = response.data?.job
			this.title = response.data?.job?.jobGeneral?.title

			if (!response.data?.job?.id) {
				this.showError = true
			}
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchJobApplicant = async (id) => {
		try {
			const response = await apolloClient.query({
				query: GET_APPLICATION_JOB_QUERY,
				variables: { jobId: id },
			})

			if (!response.data?.job?.id) {
				this.showError = true
			}
			this.jobApplicant = response.data?.job?.jobApplicant
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchAllCloseJob = async (ids) => {
		try {
			const response = await apolloClient.query({
				query: GET_ALL_CLOSE_JOB_QUERY,
				variables: { ids: ids },
			})
			this.allCloseJobs = response.data?.closedJobsList?.map((item) => item?.id)
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	actionApplyJob = async () => {
		try {
			const valuesData = {}
			const { resumes } = careProfileStore
			const { references } = referenceTabStore
			const { fetchWorkerResumes } = careProfileStore
			const { fetchWorkerReferences } = referenceTabStore
			if (quickApplyStore?.currentJobID) {
				valuesData.jobId = quickApplyStore?.currentJobID
			}
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { ...valuesData },
			})
			const jobApplicant = response.data?.createJobApplicant
			await this.setShowApplyDialog(false)
			await this.setShowApplyUpdateDialog(false)

			await quickApplyStore.setSuccessApplyDialog(true)
			this.job = { ...this.job, jobApplicant: { ...jobApplicant, status: 'applied' } }
			if (isEmpty(resumes)) {
				await fetchWorkerResumes()
			}
			if (isEmpty(references)) {
				await fetchWorkerReferences()
			}
			return response.data?.createJobApplicant.job
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}
	@action
	onApplyJob = async (values) => {
		try {
			if (authStore.id) {
				if (!isEmpty(values)) {
					await quickApplyStore.setCurrentJob(values)
					await careJobDetailStore.setShowApplyDialog(true)
				}
			} else {
				await quickApplyStore.setCurrentJob(values)
				setTimeout(() => quickApplyStore.setOpenQuickApply(true), 300)
			}
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}

	@action
	onExtendPlaceJob = async (values) => {
		try {
			if (!isEmpty(values)) {
				const { note, listDayExtends, jobApplicantId } = values
				const applicantTimeOffs =
					!isEmpty(listDayExtends) &&
					// eslint-disable-next-line
					listDayExtends?.map((item) => {
						if (!isEmpty(item?.extendDay)) {
							const [startDate, endDate] = item.extendDay
							return {
								startDate,
								endDate,
							}
						}
					})
				const valuesData = {}
				if (jobApplicantId) {
					valuesData.workerAssignmentId = jobApplicantId
				}
				if (note) {
					valuesData.note = note
				}

				if (applicantTimeOffs) {
					valuesData.applicantTimeOffs = applicantTimeOffs
				}

				await apolloClient.mutate({
					mutation: EXTEND_JOB_APPLICANT_MUTATION,
					variables: { ...valuesData },
				})

				notifyStore.success('$MESSAGES.SUCCESSFUL')
			}
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onClickFavorite = async (values) => {
		const { jobId, action } = values?.variables

		try {
			await apolloClient.mutate({
				mutation: LIKE_DISLIKE_JOB_MUTATION,
				variables: {
					jobId: jobId,
					action: action,
				},
			})
			if (action) {
				await redirectEventTrackingBookMark(action, {
					job_id: jobId,
					is_bookmarked: !action,
					worker_email: authStore.workerEmail,
				})
			}

			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
	@action
	onClickBookmark = async (job, jobDetailCtaStatus, activeJobId) => {
		try {
			if (!authStore.id) {
				const bookMarkJobs = JSON.parse(localStorage.getItem('bookMarkJobs')) || []
				//check if job is already in the list. If not then add it to the list
				if (!bookMarkJobs.some((item) => item.jobId === job.id)) {
					bookMarkJobs.push({ jobId: job.id, bookMarkedAt: new Date() })

					this.job = { ...job, isBookmarked: true }
					localStorage.setItem('bookMarkJobs', JSON.stringify(bookMarkJobs))
				} else {
					//if job is already in the list then remove it from the list
					const newBookMarkJobs = bookMarkJobs.filter((item) => item.jobId !== job.id)
					this.job = { ...job, isBookmarked: false }
					localStorage.setItem('bookMarkJobs', JSON.stringify(newBookMarkJobs))
				}
			} else {
				await apolloClient.mutate({
					mutation: BOOKMARK_A_JOB_MUTATION,
					variables: {
						jobId: job.id,
						action: !!jobDetailCtaStatus.isBookmarked ? 'unbookmark' : 'bookmark',
					},
				})
			}
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}
	@action
	setIsShowCheckout = async (values) => {
		this.isShowCheckout = values
	}
	@action
	setIsShowCheckoutSuccess = async (values) => {
		this.isShowCheckoutSuccess = values
	}
	@action
	setShowDialogExtend = async (values) => {
		this.showDialogExtend = values
	}
	@action
	setShowExtendSuccess = async (values) => {
		this.showExtendSuccess = values
	}
	@action
	setShowCheckInError = async (values) => {
		this.showCheckinError = values
	}
	@action
	setShowError = async (values) => {
		this.showError = values
	}
}

export const careJobDetailStore = new CareJobDetailStore()
