export const EDUCATION_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-educations',
	SCREEN_EDIT_TRACKING: 'edit-education',
	SCREEN_ADD_TRACKING: 'add-education',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-education',
	SCREEN_DENY_TRACKING: 'DENY-REMOVE-EDUCATION',
	SUBMIT_EDIT_SUCCESS: 'EDIT-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-EDUCATIONS_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-EDUCATIONS_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-EDUCATIONS_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn',
	CLICK_EDIT_PROFILE_BTN: 'edit_btn',
	CLICK_REMOVE_EDUCATION_BTN: 'remove_btn',
	CLICK_SAVE_EDIT_EDUCATION_BTN: 'save_btn_edit',
	CLICK_CLOSE_EDIT_EDUCATION_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_EDUCATION_BTN: 'save_btn_add-education',
	CLICK_CLOSE_ADD_EDUCATION_BTN: 'close-screen_btn_add-education',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE_EDUCATION: 'cancel_btn_confirm',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE_EDUCATION: 'confirm_btn_confirm',
	CLICK_OK_BTN_DENY_REMOVE_EDUCATION: 'ok_btn_deny-remove-education',
	SCREEN_CONFIRM_REMOVE_EDUCATION: 'confirm-remove-education',
	SCREEN_DENY_REMOVE_EDUCATION: 'deny-remove-education',
}

export const WORK_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-work-experiences',
	SCREEN_EDIT_TRACKING: 'edit-work-experience',
	SCREEN_ADD_TRACKING: 'add-work-experience',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-work-experience',
	SCREEN_DENY_TRACKING: 'deny-remove-work-experience',
	SUBMIT_EDIT_SUCCESS: 'EDIT-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-WORK-EXPERIENCES_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-WORK-EXPERIENCES_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-WORK-EXPERIENCES_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn_profile',
	CLICK_EDIT_PROFILE_BTN: 'edit_btn_profile',
	CLICK_REMOVE_BTN: 'remove_btn_profile',
	CLICK_SAVE_EDIT_BTN: 'save_btn_edit',
	CLICK_CLOSE_EDIT_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_BTN: 'save_btn_add',
	CLICK_CLOSE_ADD_BTN: 'close-screen_btn_add',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE: 'cancel_btn_confirm',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE: 'confirm_btn_remove',
	CLICK_OK_BTN_DENY_REMOVE: 'ok_btn_deny',
	SCREEN_DENY_REMOVE_WORK_EXPERIENCE: 'deny-remove-work-experience',
	SCREEN_CONFIRM_REMOVE_WORK_EXPERIENCE: 'confirm-remove-work-experience',
}

export const REFERENCE_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-references',
	SCREEN_EDIT_TRACKING: 'edit-reference',
	SCREEN_ADD_TRACKING: 'add-reference',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-reference',
	SCREEN_DENY_TRACKING: 'deny-remove-reference',
	SUBMIT_EDIT_SUCCESS: 'EDIT-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-REFERENCES_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-REFERENCES_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-REFERENCES_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn',
	CLICK_EDIT_PROFILE_BTN: 'edit-reference_btn',
	CLICK_REMOVE_BTN: 'remove-reference_btn',
	CLICK_SAVE_EDIT_BTN: 'save_btn_edit-reference',
	CLICK_CLOSE_EDIT_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_BTN: 'save_btn_add',
	CLICK_CLOSE_ADD_BTN: 'close-screen_btn_add',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE: 'cancel_btn',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE: 'confirm_btn',
	CLICK_OK_BTN_DENY_REMOVE: 'ok_btn_deny',
	SCREEN_DENY_REMOVE_REFERENCE: 'deny-remove-reference',
	SCREEN_CONFIRM_REMOVE_REFERENCE: 'confirm-remove-reference',
}

export const CHECK_LIST_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-skill-check-lists',
	SCREEN_POPUP_TRACKING: 'confirm-open-una-linke',
	CLICK_OPEN_UNA_LINK_BTN: 'open-una-link_btn_profile-management-skill-check-lists',
}

export const TERM_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-term-signed',
}

export const INFO_PROFILE_TRACKING_EVENT = {
	PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_SUCCESS: 'PROFILE-MANAGEMENT-PERSONAL-INFO_SUBMIT_SUCCESS',
	PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_FAILED: 'PROFILE-MANAGEMENT-PERSONAL-INFO_SUBMIT_FAILED',

	CONFIRM_KEEP_EMAIL_SUBMIT_SUCCESS: 'CONFIRM_KEEP_EMAIL_SUBMIT_SUCCESS',
	CONFIRM_KEEP_EMAIL_SUBMIT_FAILED: 'CONFIRM_KEEP_EMAIL_SUBMIT_FAILED',

	CONFIRM_UPDATE_EMAIL_SUBMIT_SUCCESS: 'CONFIRM_UPDATE_EMAIL_SUBMIT_SUCCESS',
	CONFIRM_UPDATE_EMAIL_SUBMIT_FAILED: 'CONFIRM_UPDATE_EMAIL_SUBMIT_FAILED',

	SCREEN_TRACKING: 'profile-management-personal-info',
	SCREEN_CHANGE_PHONE: 'change-phone',
	SCREEN_CONFIRM_VERIFY_PHONE: 'screen-confirm-verify-phone',
	SCREEN_VERIFY_PHONE: 'screen-verify-phone',
	SCREEN_PHONE_EXISTED: 'screen-phone-existed',

	SCREEN_EDIT_PERSONAL_INFO: 'edit-personal-info',
	SCREEN_EDIT_EMERGENCY_CONTACT: 'edit-emergency-contact',
	SCREEN_CONFIRM_UPDATE_EMAIL: 'confirm-update-email',

	CLICK_EDIT_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'edit_btn',
	CLICK_ADD_SPECIALTIES_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'add-specialties_btn',
	CLICK_ADD_DISCIPLINE_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'add-discipline_btn',
	CLICK_EDIT_EMERGENCY_CONTACT_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'edit-emergency-contact_btn',

	CLICK_VERIFY_PHONE_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'verify-phone_btn',
	CLICK_CHANGE_PHONE_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'change-phone_btn',
	CLICK_SAVE_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'save_btn',
	CLICK_CLOSE_EDIT_PERSONAL_INFO_BTN_PROFILE_MANAGEMENT_PERSONAL_INFO: 'close-personal-info_btn',
}
