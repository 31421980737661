import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core'
import { useField } from 'formik'
import React, { useEffect } from 'react'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { appStore, authStore } from '~/stores'

const WrapperSwitch = styled.div`
	.MuiFormControlLabel-root {
		padding: 0 !important;
		margin: 0 !important;
	}

	//.MuiFormControlLabel-root.Mui-disabled {
	//  .MuiSwitch-track{
	//    background-color: #bdbdbd !important;
	//  }
	//}
`

const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
		marginLeft: 0,
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: appStore.getTheme.colors.primary.main,
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			// color: appStore.getTheme.colors.secondary.main,
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[200],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))

export const SwitchField = ({ handleOnchange, name, validate, label, mode, defaultChecked = false, callBackFunction, isDrawer = false, ...props }) => {
	const { disabled, id, callBackHandler, nameAttribute, callBackSwitchFieldCheck } = props

	const { t } = useTranslation()
	const classes = useStyles()

	const [field, meta] = useField({
		name,
		validate: mode === FIELD_MODE.edit && validate,
	})
	const error = useFieldError(meta)
	const handleChange = (event, checked) => {
		event.target.value = checked
		if (callBackHandler) {
			callBackHandler(event.target.id, nameAttribute)
		}
		if (mode === FIELD_MODE.edit) {
			field.onChange(event)
		}
		if (checked && isDrawer && authStore.isAnonymous) {
			callBackFunction()
		}
		if (callBackSwitchFieldCheck) {
			callBackSwitchFieldCheck(checked, event.target.name, event.target.id)
		}
	}

	useEffect(() => {
		if (handleOnchange) {
			handleOnchange()
		}
		// eslint-disable-next-line
	}, [field.value])
	return (
		<WrapperSwitch>
			<FormControl error={!!error}>
				<FormControlLabel
					control={
						<Switch
							id={id}
							disabled={disabled}
							onChange={handleChange}
							checked={field.value || false}
							name={name}
							focusVisibleClassName={classes.focusVisible}
							disableRipple
							classes={{
								root: classes.root,
								switchBase: classes.switchBase,
								thumb: classes.thumb,
								track: classes.track,
								checked: classes.checked,
							}}
							{...props}
						/>
					}
					label={t(label)}
				/>
				{error && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		</WrapperSwitch>
	)
}

SwitchField.defaultProps = {
	mode: FIELD_MODE.edit,
}
