import { Box, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import React from 'react'
import BannerUrl from '../assests/banner.png'
import { signupBtnStyle } from '~/features/common-authorization/common-authorization.style'
import { Button } from '~/components/button'
import { routerStore } from '~/stores'
import { PATHS } from '~/common/constants'
import { Typography } from '~/components/typography'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { careStore } from '~/companies/care/care.store'

const useStyle = makeStyles(({ theme }) => ({
	paper: {
		borderRadius: '1rem',
	},
	dialogAction: {
		marginTop: '24px',
		padding: '0 24px 24px 24px',
	},
}))

export const CompleteOnboardingDialog = ({ open, onClose }) => {
	const classes = useStyle()
	const { t } = useTranslation()
	return (
		<Dialog open={open} onClose={onClose} PaperProps={{ classes: { root: classes.paper } }}>
			<DialogContent>
				<Box width="100%" minHeight="230px">
					<img src={BannerUrl} alt="banner" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
				</Box>

				<Box display="flex" flexDirection="column" gridGap="1rem" mt={3}>
					<Typography variant="h3" color="primary">
						{t('COMPLETE_ONBOARDING_TITLE')}
					</Typography>
					<Typography variant="body1" color="content.dark">
						{t('COMPLETE_ONBOARDING_TEXT')}
					</Typography>
				</Box>
			</DialogContent>

			<DialogActions classes={{ root: classes.dialogAction }}>
				<Button
					css={signupBtnStyle}
					fullWidth={true}
					variant="contained"
					color="primary"
					onClick={async () => {
						await careStore.fetchWorkerBasicInfo()
						routerStore.goPage(PATHS.care.profile)
					}}
				>
					<Typography>View profile</Typography>
				</Button>
			</DialogActions>
		</Dialog>
	)
}
