import { Box, CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { useWindowScrollTop } from '~/common/hooks'
import { loadingState, observer } from '~/common/mobx.decorator'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { referenceTabStore } from './reference-tab.store'
import { Button } from '~/components/button'
import { Typography } from '~/components/typography'
import { masterStore } from '~/stores'
import { logClickEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { REFERENCE_TRACKING_EVENT } from '~/common/constants'
import HintBox from '~/features/care-activation/components/hint-box'
import { infoTabStore } from '../info/info-tab.store'
import { onboardingProgressStore } from '~/components/onboarding-progress/onboarding-progress.store'
import { careActivationStore } from '../../care-activation.store'

const ReferenceViewForm = observer(() => {
	const { referenceCheckUrl } = referenceTabStore
	const { t } = useTranslation()

	const loading = loadingState('referenceTabStore/fetchReferenceCheckUrl')

	if (loading) {
		return (
			<Box mt={5} mb={5} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		)
	}
	if (!infoTabStore.workerPhone) {
		return (
			<Box mt={5} mb={5}>
				<Typography variant="h3" color="status.warning.medium" style={{ fontWeight: 900, marginBottom: 8 }}>
					{t('PHONE_NUMBER_NOT_PROVIDED')}
				</Typography>

				<Typography variant="body1" color="content.dark">
					{t('PROVIDE_PHONE_NUMBER_DESC')}
				</Typography>
			</Box>
		)
	}

	return (
		<Box>
			{referenceCheckUrl ? (
				<Box mt={2}>
					<iframe src={referenceCheckUrl} width="100%" height="600px" frameBorder="0" title="Reference Check" />
				</Box>
			) : (
				<Box mt={5} mb={5} display="flex" flexDirection="column" alignItems="center">
					<CircularProgress />
					<Typography variant="h4" color="primary.main" style={{ marginTop: 16 }}>
						{t('PROCESSING_ACCOUNT_INFO_TITLE')}
					</Typography>
					<Typography style={{ marginTop: 3 }} variant="body1" color="content.dark">
						{t('PROCESSING_ACCOUNT_INFO')}
					</Typography>
				</Box>
			)}
		</Box>
	)
})

const ReferenceSection = observer(({ onNextTab, onPreviousTab }) => {
	const { t } = useTranslation()

	useEffectOnce(() => {
		Promise.all([infoTabStore.fetchWorkerBasicInfo(), referenceTabStore.fetchReferenceCheckUrl()])
	})

	return (
		<>
			<Box>
				<ReferenceViewForm />
				<Box mt={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								onClick={() => {
									onPreviousTab()
								}}
							>
								{t('PREVIOUS_STEP')}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							onClick={() => {
								if (!infoTabStore.workerPhone) {
									careActivationStore.changeActiveTab(onboardingProgressStore.profileStepId)
								} else {
									onNextTab()
								}
							}}
						>
							<Button type="button" fullWidth variant="contained" color="primary">
								{!infoTabStore.workerPhone ? t('PROVIDE_PHONE_NUMBER') : infoTabStore.referenceCheckUrl ? t('NEXT_STEP') : t('DO_IT_LATER')}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	)
})

export const ExternalReferenceTab = observer(({ hintText, onNextTab, onPreviousTab }) => {
	const { fetchWorkerReferences, addNewReference, references } = referenceTabStore
	const { fetchReferenceRelationship } = masterStore
	useWindowScrollTop()

	useEffectOnce(() => {
		logTrackingScreenEvent(REFERENCE_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
		fetchWorkerReferences()
		fetchReferenceRelationship()
	})

	const loading = loadingState('referenceTabStore/fetchWorkerReferences')

	useEffect(() => {
		if (loading === false && !references.length) {
			addNewReference()
		}
	}, [loading, references.length, addNewReference])

	return (
		<Box p={2}>
			<HintBox
				text={hintText}
				onSkipTab={() => {
					logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_SKIP_STEP_4, {})
					onNextTab()
				}}
			/>

			<ReferenceSection onNextTab={onNextTab} onPreviousTab={onPreviousTab} />
		</Box>
	)
})
