import React, { forwardRef, useCallback } from 'react'
import { Button as MuiButton, Box, CircularProgress } from '@material-ui/core'
import { circularProgressStyle, useStyles } from './button.style'
import { eventClient } from '@opus/web.core.lib.event-tracking'

export const CustomCircularProgress = () => {
	const classes = useStyles()
	return (
		<Box css={circularProgressStyle}>
			<Box className="inner-circle" />
			<CircularProgress color="secondary" classes={{ svg: classes.circularSvg }} size={20} disableShrink />
		</Box>
	)
}

export const Button = forwardRef(({ loading, children, disabled, startIcon, ...props }, ref) => {
	const classes = useStyles({ loading })
	const handleClick = useCallback(() => {
		if (props.onTrackingEvent) {
			props.onTrackingEvent()
		} else if (props.eventId) {
			eventClient.logClickEvent(props.eventId, props.eventParams).catch((error) => console.log(`Error tracking data ${props.eventId}`, error.message))
		}
	}, [props])

	return (
		<MuiButton
			ref={ref}
			disabled={loading || disabled}
			startIcon={loading ? <CustomCircularProgress /> : startIcon}
			classes={{ contained: classes.contained, root: classes.root }}
			onClick={handleClick}
			{...props}
		>
			{children}
		</MuiButton>
	)
})

Button.defaultProps = {
	fullWidth: true,
	color: 'primary',
	variant: 'contained',
	size: 'large',
}
