import styled, { css } from 'styled-components'
import { InputLabel, Typography } from '@material-ui/core'

export const inputStyle = css`
	display: none;
`

export const uploadButtonStyle = css`
	height: 74px;
	width: 100%;
	cursor: pointer;

	display: flex;
	padding: 10px 12px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #ddefff;
	background-color: #f6fbff;

	&:hover {
		background-color: #f6fbff;
		border-radius: 8px;
	}

	justify-content: start;
	text-transform: unset;

	.content {
		margin-left: 52px;
		.MuiTypography-caption {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
		}
	}
`

export const imageBoxStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	.container {
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		gap: 10px;
		height: 74px;
		.icon {
			background: ${({ theme }) => theme.colors.primary.main};
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		svg {
			fill: none;
			font-size: 20px;
		}
		.upload-comp-title {
			color: ${({ theme }) => theme.colors.primary.main};
			font-size: 14px;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: 0.1px;
		}
		.upload-comp-sub-title {
			color: ${({ theme }) => theme.colors.content.dark};
			${({ theme }) => theme.typography.p1};
			font-weight: 500;
			margin-top: 4px;
		}
	}
`

export const imageBoxUploadStyle = css`
	margin-left: 4px;
	cursor: pointer;
	.container {
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		svg {
			font-size: 25px;
		}
	}
`

export const wrapperStyle = css`
	width: 100%;
`
export const itemBoxBorderedStyle = css`
	border: 1px solid #e5e5e5;
`

export const itemBoxStyle = css`
	//padding: 4px 0;
	align-items: center;
	position: relative;
	background: #ffffff;
	color: #0e3f6c;
	border-radius: 8px;
	cursor: pointer;
	.download-btn {
		padding-right: 0 !important;
		padding-bottom: 0 !important;
		.MuiIconButton-label {
			gap: 3px;
		}
		.text {
			font-size: 14px;
			font-style: normal;
			font-weight: 800;
			line-height: 22px;
		}
		.download-icon {
			width: 16px;
			height: 16px;
			overflow: unset;
			aspect-ratio: auto 16 / 16;
		}

		:hover {
			background: white !important;
		}
	}
	.MuiIconButton-root {
		margin-left: auto;
	}

	p {
		overflow: hidden;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.preview-icon {
		display: flex;
	}

	.lazyload-wrapper {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.upload {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 8px;
	}

	div:has(> .upload-icon) {
		display: flex;
		aling-items: center;
		flex-grow: 1;
	}

	.upload-icon {
		display: flex;
		gap: 4px;
		align-items: center;
		margin-left: auto;
		color: ${({ theme }) => theme.palette.status.success.medium};
	}
`

export const titleStyle = css`
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 18px;

	letter-spacing: 0.1px;
	color: #0e3f6c;
`

export const messageStyle = css`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;

	letter-spacing: 0.1px;

	color: #0e3f6c;
`

export const filenameStyle = css`
	${({ theme }) => theme.typography.h4}
	white-space: pre-wrap;
	word-break: break-word;
	color: #333335;
`

export const reviewUploadStyled = css`
	.removeIcon {
		position: absolute;
		top: 0px;
		right: 0px;
		svg {
			width: 15px;
			height: 15px;
		}
	}

	.preview-image {
		img {
			height: 80px;
			width: 80px;
			overflow: hidden;
			border-radius: 8px;
		}

		.upload-list {
			width: 100%;
			display: flex;
			align-items: center;
		}

		.upload-list:not(:first-child) {
			margin-top: 1rem;
		}
	}
`

export const uploadInputStyled = css`
	position: relative;
	width: 100%;
`
export const headingStyle = css`
	${({ theme }) => theme.typography.p1}
	color: ${({ theme }) => theme.palette.content.veryDark};
`
export const labelStyle = css`
	${({ theme }) => theme.typography.subtitle2}
	margin-bottom: 8px;
	color: ${({ theme }) => theme.palette.content.dark};
`

export const hintStyle = css`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.content.dark};
`

export const loadingButtonStyle = css`
	height: 80px;
	width: 80px;
	background: white;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	border-radius: 8px;
	justify-content: center;
	display: flex;
	align-items: center;
`

export const pdfCarouselButton = css`
	height: 100%;
	width: 100%;
	background: white;
	justify-content: center;
	display: flex;
	align-items: center;
`

// export const imageBoxStyle = css`
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	transform: translateY(50%);
//
// 	.container {
// 		width: 40px;
// 		height: 40px;
// 		border-radius: 50%;
//
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
//
// 		background: linear-gradient(180.56deg, #7bcdc8 0.48%, rgba(17, 156, 175, 0) 273.77%), #7aa2af;
//
// 		svg {
// 			font-size: 16px;
// 		}
// 	}
// `

// export const wrapperStyle = css`
// 	width: 100%;
// `

// export const itemBoxStyle = css`
// 	padding: 4px 0;
// 	display: flex;
// 	align-items: center;
//
// 	height: 30px;
// 	background: #ffffff;
// 	border: 1px solid #c2d6dc;
// 	box-sizing: border-box;
// 	border-radius: 32px;
//
// 	font-weight: 400;
// 	font-size: 14px;
// 	line-height: 18px;
// 	letter-spacing: 0.2px;
//
// 	color: #0e3f6c;
// 	margin: 3px;
//
// 	.MuiIconButton-root {
// 		margin-left: auto;
// 	}
//
// 	p {
// 		overflow: hidden;
// 	}
//
// 	.preview-icon {
// 		display: flex;
// 		margin: 0 13px;
//
// 		svg {
// 			font-size: 18px;
// 		}
// 	}
//
// 	.lazyload-wrapper {
// 		width: 28px;
// 		height: 28px;
// 		border-radius: 50%;
// 		overflow: hidden;
//
// 		img {
// 			width: 100%;
// 			height: 100%;
// 			object-fit: contain;
// 		}
// 	}
// `

export const fileNameElisips = css`
	font-size: 12px;
	line-height: 18px;
	text-align: center;
`

export const WrapperSliderBox = styled.div`
	display: flex;
	overflow: auto;
	max-width: calc(100vw - 450px);
	@media (max-width: 1065px) {
		max-width: calc(100vw - 350px);
	}
	@media (max-width: 960px) {
		max-width: 90vw;
	}
	@media (max-width: 650px) {
		max-width: 87vw;
	}
	@media (max-width: 600px) {
		max-width: 85vw;
	}
`

export const WrapperInputLabel = styled(InputLabel)`
	padding: 5px 0;
`

export const WrapperSubtitile = styled(Typography)`
	font-size: 12px;
	font-weight: 400;
	color: #808080;
	line-height: 18px;
	padding: 5px 0;
`
export const WrapperButton = styled.div`
	padding: 10px 10px 10px 0;
`

export const uploadTextStyle = css`
	${({ theme }) => theme.typography.p1};
	font-weight: 500;
`
