import {ICanParams, IPermissionService} from '~/core/interfaces'

export class PermissionService implements IPermissionService {
	async can(params: ICanParams) {
		console.log('🚀 ~ file: PermissionService.ts:6 ~ PermissionService ~ can ~ params:', params)

		return Promise.resolve({ success: true })
	}
}

export const permissionService = new PermissionService()
