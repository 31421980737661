import { concat, isEmpty, omit, pick } from 'lodash'
import { isTempId } from '~/common/helpers/uid.helper'
import { INTEGRATION_OBJECTS } from './constants'
import { authStore } from '~/stores'
import { removeFalsyProps, updateArray } from '~/common/helpers/mapping.helper'
import { careActivationStore } from '~/features/care-activation/care-activation.store'

export const convertLicenseToSaveData = (formData) => {
	const { workerCertifications, __workerOnboardingPart } = formData

	const workerCertificationsAttributes = workerCertifications?.map(({ id, licenseImages, ...certification }) => {
		const data = certification

		data.licenseImagesSignedBlobIds = licenseImages?.filter((image) => !image._destroy && image.signedBlobId).map((file) => file.signedBlobId)
		if (!isTempId(id)) {
			data.id = id
		}

		return data
	})

	const updatedIds = workerCertificationsAttributes.map((cer) => cer.id)

	const deletedCertifications = __workerOnboardingPart?.integration?.workerCertifications
		?.filter((cer) => !updatedIds.includes(cer.id))
		?.map(({ id }) => ({ id, _destroy: true }))

	return {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerCertificationsAttributes: !!deletedCertifications ? concat(workerCertificationsAttributes, deletedCertifications) : workerCertificationsAttributes,
		},
	}
}

export const convertExperiencesToSaveData = (formData) => {
	const { workExperiences, __workerOnboardingPart, __hasResumes, ...rest } = formData

	const workExperiencesAttributes = workExperiences
		?.map(({ id, location, endDate, currentlyEmployed, ...experience }) => {
			const data = experience
			if (!isTempId(id)) {
				data.id = id
			}

			if (!experience.facilityName) {
				data._destroy = true
			}
			if (!!location) {
				data.city = location?.city
				data.state = location?.state
				data.country = location?.country
			} else {
				data.city = null
				data.state = null
				data.country = null
			}

			data.endDate = currentlyEmployed ? null : endDate
			data.currentlyEmployed = currentlyEmployed

			return data
		})
		.filter((data) => data.id || !data._destroy)

	const updatedIds = workExperiencesAttributes?.map((exp) => exp.id)

	const deletedExperiences = __workerOnboardingPart?.integration?.workExperiences
		?.filter((exp) => !updatedIds.includes(exp.id))
		?.map(({ id }) => ({ id, _destroy: true }))

	return {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			...rest,
			id: __workerOnboardingPart?.integration?.id,
			workExperiencesAttributes: concat(workExperiencesAttributes, deletedExperiences),
		},
	}
}

export const convertEducationsToSaveData = (formData) => {
	const { workerEducations, __workerOnboardingPart } = formData

	const workerEducationsAttributes = workerEducations
		?.map(({ id, location, ...education }) => {
			const data = education
			if (!isTempId(id)) {
				data.id = id
			}

			if (!education?.schoolName) {
				data._destroy = true
			}

			data.city = location?.city || null
			data.state = location?.state || null
			data.country = location?.country || null

			return data
		})
		.filter((data) => data.id || !data._destroy)

	const updatedIds = workerEducationsAttributes?.map((education) => education.id)

	const deletedWorkerEducations = __workerOnboardingPart?.integration?.workerEducations
		?.filter((education) => !updatedIds.includes(education.id))
		?.map(({ id }) => ({ id, _destroy: true }))

	return {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerEducationsAttributes: !!deletedWorkerEducations ? concat(workerEducationsAttributes, deletedWorkerEducations) : workerEducationsAttributes,
		},
	}
}

export const convertReferencesToSaveData = (formData) => {
	const { workerReferences, __workerOnboardingPart } = formData
	const workerReferencesAttributes = workerReferences
		?.map(({ id, referenceType, location, ...references }) => {
			const data = references
			if (!isTempId(id)) {
				data.id = id
			}
			if (!references?.facilityName) {
				data._destroy = true
			}
			data.city = location.city
			data.state = location.state
			data.referenceType = referenceType ? 'former' : 'current'
			delete data.location

			return data
		})
		?.filter((reference) => reference.id || !reference._destroy)

	const updatedIds = workerReferencesAttributes?.map((reference) => reference.id)
	const deletedWorkerReferences = __workerOnboardingPart?.integration?.workerReferences
		?.filter((reference) => !updatedIds.includes(reference.id))
		?.map(({ id }) => ({ id, _destroy: true }))
	return {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerReferencesAttributes: !!deletedWorkerReferences ? concat(workerReferencesAttributes, deletedWorkerReferences) : workerReferencesAttributes,
		},
	}
}

export const convertTermsToSaveData = (formData) => {
	const { workerQuizAnswers, termsOfServiceId, __workerOnboardingPart } = formData
	return {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			termsOfServiceId: termsOfServiceId && __workerOnboardingPart?.integrationConfigurations?.termsOfService?.id,
			workerQuizAnswerAttributes: workerQuizAnswers,
		},
	}
}

export const convertProfileToSaveData = (formData) => {
	const { workerAddress, workingPreferredLocations, __workerOnboardingPart, id, emergencyContact, ...rest } = formData
	const preferState = careActivationStore.preferState
	const availableStartDate = rest?.availableStartDate || ''
	const shiftRequested = rest?.shiftRequested || ''
	const facilityTypes = rest?.facilityTypes || ''
	const updateLocationFill = updateArray(preferState, workingPreferredLocations)
	const workerSpecialties = !isEmpty(rest.workerSpecialties)
		? {
				category: rest?.discipline,
				specialty: rest?.specialty,
				isPrimary: true,
				workerSkillChecklistSectionsAttributes: [],
				id: rest.workerSpecialties?.[0]?.id,
		  }
		: {
				category: rest?.discipline,
				specialty: rest?.specialty,
				isPrimary: true,
				workerSkillChecklistSectionsAttributes: [],
		  }
	const workerAttributes = removeFalsyProps({
		...rest,
		availableStartDate: availableStartDate,
		shiftRequested: shiftRequested,
		facilityTypes: facilityTypes,
		id: authStore.id,
		workerAddressAttributes: pick(workerAddress, ['id', 'street', 'city', 'state', 'country', 'zipcode', 'latitude', 'longitude']),
		workingPreferredLocationsAttributes: updateLocationFill,
		workerSpecialtiesAttributes: [workerSpecialties],
	})
	const removeProps = omit(workerAttributes, ['specialty', 'discipline', 'workerSpecialties'])
	const finalData = {
		id: __workerOnboardingPart.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.worker?.id,
			workerAttributes: removeProps,
		},
	}

	return finalData
}

export const CONVERT_FORM_TO_SAVE_DATA = {
	[INTEGRATION_OBJECTS.licenses]: convertLicenseToSaveData,
	[INTEGRATION_OBJECTS.experiences]: convertExperiencesToSaveData,
	[INTEGRATION_OBJECTS.educations]: convertEducationsToSaveData,
	[INTEGRATION_OBJECTS.references]: convertReferencesToSaveData,
	[INTEGRATION_OBJECTS.terms]: convertTermsToSaveData,
	[INTEGRATION_OBJECTS.profile]: convertProfileToSaveData,
}
