import { useFormikContext } from 'formik'
import { useCallback, useState } from 'react'

export const useFieldFocused = (field) => {
	const { setFieldTouched } = useFormikContext()

	const [focused, setFocused] = useState(false)
	const onFocus = useCallback(() => setFocused(true), [setFocused])
	const onBlur = useCallback(() => {
		setFieldTouched(field.name)
		setFocused(false)
	}, [setFocused, setFieldTouched, field.name])

	return [focused, { onFocus, onBlur }]
}
