import { css } from 'styled-components'

export const selectFieldStyle = css`
	width: 100%;
	&.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
		cursor: default;
	}

	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}

	.MuiInputBase-root {
		height: 43px;
	}
`
export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main} !important;
	text-transform: capitalize;
	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}
	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`
