import { useEffectOnce } from 'react-use'
import { RECAPTCHA_CLIENT_KEY, handleLoadReCaptcha } from '../helpers'
import { useCallback } from 'react'

export const useRecaptcha = () => {
	useEffectOnce(() => {
		handleLoadReCaptcha()
	})

	const execute = useCallback((action) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action }).then(resolve).catch(reject)
			})
		})
	}, [])

	return {
		execute,
	}
}
