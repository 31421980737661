export const captureException = (scope, error) => {
	if (window?.Sentry?.captureMessage && typeof window?.Sentry?.captureMessage === 'function') {
		// window.Sentry.captureMessage(scope, { level: 'warning', extra: error })
	} else {
		console.warn('Missing Sentry Config!!')
		console.warn(scope, error)
	}
}

export const captureFatalException = (scope, error) => {
	if (window?.Sentry?.captureMessage && typeof window?.Sentry?.captureMessage === 'function') {
		window.Sentry.captureMessage(scope, { level: 'fatal', extra: error })
	} else {
		console.warn('Missing Sentry Config!!')
		console.warn(scope, error)
	}
}
