export const FIELD_MODE = {
	view: 'view',
	edit: 'edit',
}

export const PROFESSION_DIALOG_STEP = {
	professions: 'professions',
	specialties: 'specialties',
}

export const TRAVEL_EMPLOYMENT_TYPE = 'Travel'
