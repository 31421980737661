import { gql } from '@apollo/client'
import {
	JOB_ASSIGMENT_FRAGMENT,
	JOB_BASED_FRAGMENT,
	JOB_DETAIL_FRAGMENT,
	JOB_FRAGMENT,
	WORKER_ASSIGMENT_FRAGMENT,
	WORKER_ASSIGNMENT_FRAGMENT,
} from '../apollo.fragment'

export const GET_GENERAL_RANKED_JOBS_QUERY = gql`
	query popularCities {
		popularCities {
			city
			state
			totalJobs
			highWeeklyRate
			lowWeeklyRate
			locationImage {
				id
				webImage {
					fileUrl
				}
				thumbnailImage {
					fileUrl
				}
			}
		}
	}
`

export const GET_POPULAR_CITY_JOBS = gql`
	query popularCityJobsQuery($state: String!, $city: String!, $after: String) {
		popularCityJobs(state: $state, city: $city, after: $after) {
			nodes {
				...jobBasedFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}
	${JOB_BASED_FRAGMENT}
`

export const GET_SUGGESTION_JOBS_QUERY = gql`
	query getSuggestionJobsQuery($sortBy: JobManagementSort, $after: String, $first: Int, $last: Int, $filter: JobFilter) {
		suggestionJobs(sortBy: $sortBy, after: $after, first: $first, last: $last, filter: $filter) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_CHECKOUT_TIME_WORKER = gql`
	query getListWorkerCheckout($filter: WorkerAssignmentFilter) {
		searchWorkerAssignments(filter: $filter) {
			nodes {
				acceptedAt
				actualEndTime
				actualStartTime
				externalId
				id
				permittedActions
				scheduledEndTime
				scheduledStartTime
				shiftTypes
				status
				payType
				payRate
				salary
				job {
					...jobDetailFragment
				}
				completedAssignment
			}
			totalCount
		}
	}
	${JOB_DETAIL_FRAGMENT}
`

export const GET_CHECKOUT_TIME_DETAIL_WORKER = gql`
	query getListWorkerCheckout($filter: WorkerAssignmentFilter) {
		searchWorkerAssignments(filter: $filter) {
			nodes {
				acceptedAt
				actualEndTime
				actualStartTime
				externalId
				id
				permittedActions
				scheduledEndTime
				scheduledStartTime
				shiftTypes
				status
				payType
				payRate
				salary
				completedAssignment
				job {
					workerAssignment {
						id
						status
					}
				}
			}
			totalCount
		}
	}
`

export const GET_SIMILAR_JOBS_QUERY = gql`
	query getSimilarSearchJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		similarSearchJobs(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_ASSIGMENT_JOB_QUERY = gql`
	query getActiveWorkerAssignments {
		workerAssignments {
			completed {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
			current {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
			upcoming {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
		}
	}
	${JOB_DETAIL_FRAGMENT}
	${WORKER_ASSIGMENT_FRAGMENT}
`

export const GET_HOT_JOBS_QUERY = gql`
	query getHotJobsQuery($filter: JobFilter, $after: String, $sortBy: JobManagementSort, $first: Int, $last: Int) {
		searchJobs(filter: $filter, after: $after, sortBy: $sortBy, last: $last, first: $first) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_ACTIVE_WORKER_ASSIGNMENT_QUERY = gql`
	query getActiveWorkerAssignment {
		activeListWorkerAssignments {
			completed {
				...workerAssignmentFragment
			}
			current {
				...workerAssignmentFragment
			}
			next {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const GET_JOB_APPLICATIONS_COUNT_QUERY = gql`
	query getJobApplicantsCount {
		jobApplicantsCount {
			byJobStatus
			byStatus
		}
	}
`

export const SEARCH_WORKER_ASSIGNMENTS_QUERY = gql`
	query getWorkerAssignments($after: String, $before: String, $first: Int, $last: Int, $filter: WorkerAssignmentFilter) {
		searchWorkerAssignments(after: $after, before: $before, first: $first, last: $last, filter: $filter) {
			nodes {
				...workerAssignmentFragment
			}
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}
	${WORKER_ASSIGMENT_FRAGMENT}
`

export const GET_MY_JOBS_QUERY = gql`
	query getMyJobApplicants($jobStatus: JobStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		jobApplicants(jobStatus: $jobStatus, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				...jobAssigmentFragment
			}
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
		}
	}
	${JOB_ASSIGMENT_FRAGMENT}
`

export const GET_FAVORITED_JOBS_QUERY = gql`
	query getfavoritedJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		likedJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_JOB_DISCOVERS_QUERY = gql`
	query getJobDiscoversQuery {
		discovers {
			available
			favoritedJobs
			lastSearch {
				count
				filterConditions
				type
			}
			lastViewedJobs
			recommendation
		}
	}
`

export const GET_FAVORITE_JOB_DISCOVERS_QUERY = gql`
	query getListFavoriteJobDiscoversQuery {
		discovers {
			favoritedJobs
		}
	}
`

export const GET_BULLENTINS_QUERY = gql`
	query getBullentinsQuery {
		bulletins {
			id
			active
			allowDismiss
			content
			createdAt
			data
			expiredAt
			kind
			mediaUrl
			name
			scope
			targetedClass
			title
		}
	}
`

export const DISMISS_BULLETIN_MUTATION = gql`
	mutation dismissBulletinMutation($bulletinId: String!) {
		dismissBulletin(bulletinId: $bulletinId) {
			bulletinId
			success
		}
	}
`

export const REPLAY_BULLETIN_MUTATION = gql`
	mutation ReplayDismissedBulletin($bulletinIds: [String!]!) {
		replayDismissedBulletin(bulletinIds: $bulletinIds) {
			success
		}
	}
`

export const GET_TRENDING_JOBS_QUERY = gql`
	query getTrendingJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		trendingJobs(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`
export const GET_LAST_JOBS_DISCOVER_COUNT = gql`
	query getLastJobsDiscoverCount {
		discovers {
			lastViewedJobs
		}
	}
`

export const GET_LIST_JOBS_SEO_QUERY = gql`
	query getListJobsSeoQuery($after: String, $before: String, $first: Int, $last: Int, $seoPath: String!) {
		listJobs(after: $after, before: $before, first: $first, last: $last, seoPath: $seoPath) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_LAST_JOBS_QUERY = gql`
	query getLastViewedJobs($filter: JobFilter, $after: String, $first: Int, $last: Int) {
		lastViewedJobs(filter: $filter, after: $after, last: $last, first: $first) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`
