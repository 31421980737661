import { gql } from '@apollo/client'

export const CHANGE_PASSWORD_USER_MUTATION = gql`
	mutation changePasswordUser($oldPassword: String!, $password: String!, $passwordConfirmation: String!) {
		changePassword(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
			authToken {
				accessToken
				expiresIn
				refreshToken
				tokenType
			}
		}
	}
`

export const SIGN_OUT_WORKER_MUTATION = gql`
	mutation signOutWorkerMutation {
		workerSignOut {
			success
		}
	}
`
