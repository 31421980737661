import { css } from 'styled-components'

export const iconButtonStyle = css`
	margin-right: -6px;
`
export const labelStyle = css`
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
`
