import { ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { authStore, routerStore } from '~/stores'
import { EVENTS, PATHS } from '../constants'
import { eventBus } from 'mobx-event-bus2'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { captureException, captureFatalException } from '../helpers'
import { toJS } from '~/common/mobx.decorator'

const timeout = new ApolloLinkTimeout(60000)

const publicHttpLink = createHttpLink({
	uri: `${process.env.REACT_APP_PROXY_GRAPHQL_URL}${PATHS.common.public_graphql}`,
	fetchOptions: {},
})

const httpLink = createHttpLink({
	uri: `${process.env.REACT_APP_PROXY_GRAPHQL_URL}${PATHS.common.candidate_graphql}`,
	fetchOptions: {},
})

const authLink = setContext((_, { headers = {} }) => {
	const token = toJS(authStore.authorization)
	const tokenType = authStore.tokenType
	const customHeaders = {}

	if (token) {
		customHeaders['Authorization'] = `${tokenType === 'bearer' ? 'Bearer' : ''} ${token}`
	}

	customHeaders['tenant-id'] = process.env.REACT_APP_COMPANY_ID
	return { headers: { ...customHeaders, ...headers } }
})

const errorLink = onError((error) => {
	const { graphQLErrors, forward, operation, networkError } = error

	const tokenType = authStore.tokenType

	if (graphQLErrors?.[0]?.message === 'Invalid Token') {
		eventBus.post(EVENTS.authStore.logout)
	}
	if (graphQLErrors?.[0]?.extensions?.error === 'maintenance_mode' || networkError?.statusCode === 503) {
		authStore.setMaintenanceMessage(graphQLErrors?.[0]?.message || '')
		routerStore.goPage(PATHS.common.maintenance)
	}
	if (graphQLErrors?.[0]?.extensions?.code === 403) {
		eventBus.post(EVENTS.authStore.logout)
	}
	if (graphQLErrors?.[0]?.extensions?.code === 428) {
		authStore.setShowChangePasswordDialog(true)
	}

	if (networkError?.statusCode === 401) {
		console.log('🚀 ~ file: apollo.client.js:49 ~ errorLink ~ graphQLErrors?.[0]:', error)

		if (operation.operationName === 'refreshToken') return

		return fromPromise(
			authStore.refreshAuthToken().then((authToken) => {
				if (authToken?.accessToken) {
					let headers = {
						...operation.getContext().headers,
						//switch out old access token for new one
						Authorization: `${tokenType === 'bearer' ? 'Bearer' : ''} ${authToken.accessToken}`,
					}
					operation.setContext({ headers })
					return forward(operation)
				} else {
					void authStore.logout(false, false)
				}
			})
		)
	}

	if (graphQLErrors?.[0]?.extensions?.code === 500) {
		captureFatalException('[API Response Error]', error)
	} else {
		captureException('Apollo Client', error)
	}
})

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

const baseConfig = {
	cache: new InMemoryCache({ addTypename: false }),
	defaultOptions: defaultOptions,
	connectToDevTools: true,
}

export const apolloClient = new ApolloClient({
	// link: timeout.concat(authLink.concat(errorLink.concat(httpLink))),
	link: ApolloLink.split(
		(operation) => operation.getContext().clientName === 'public',
		ApolloLink.from([timeout, authLink, errorLink, publicHttpLink]), // if above
		ApolloLink.from([timeout, authLink, errorLink, httpLink])
	),
	...baseConfig,
})
