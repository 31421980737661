import { gql } from '@apollo/client'

export const GET_WORKER_REMINDER = gql`
	query getWorkerReminders {
		workerReminders {
			channels
			enabled
			frequency
			id
			reminder {
				channels
			}
			reminderType
		}
	}
`

export const GET_NOTIFICATIONS = gql`
	query getNotifications($after: String, $first: Int, $before: String, $last: Int, $events: [String!], $exceptEvents: [String!]) {
		notifications(after: $after, first: $first, before: $before, last: $last, events: $events, exceptEvents: $exceptEvents) {
			nodes {
				createdAt
				event
				heading
				id
				message
				objectId
				objectType
				additionalData
				status
				updatedAt
				url
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			totalCount
			totalRead
			totalUnread
		}
	}
`

export const GET_JOB_ALERTS_NOTIFICATIONS = gql`
	query getJobAlertNotifications($after: String, $first: Int, $before: String, $last: Int) {
		jobAlerts(after: $after, first: $first, before: $before, last: $last) {
			nodes {
				createdAt
				dataEvent
				dataId
				dataType
				heading
				id
				message
				status
				updatedAt
				url
				job {
					id
					title
				}
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			totalCount
			totalRead
			totalUnread
		}
	}
`
