import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
	professionBox: {
		borderRadius: '8px',
		padding: '24px',
		backgroundColor: '#222F881A',
	},
	primaryChip: {
		...theme.typography.body3,
		color: theme.palette.content.veryLight,
		fontWeight: 800,
	},
	specialtyItem: {
		borderRadius: '8px',
		padding: '12px',
		backgroundColor: '#FFFFFF',
		height: '48px',
	},
	dialog: {
		'& .MuiPaper-root': {
			width: '600px',
			maxWidth: '100%',
		},
	},
	addProfessionBtn: {
		borderStyle: 'solid',
		borderRadius: '8px',
		borderColor: theme.colors.background.element.medium,
		borderWidth: '1px',
		backgroundColor: theme.colors.background.element.veryLight,
		color: theme.colors.primary.main,
		'& .MuiButton-label': {
			fontWeight: 700,
		},
		'&:hover': {
			color: theme.colors.primary.main,
			backgroundColor: theme.colors.background.element.medium,
		},
	},
	addSpecialtyBtn: {
		color: theme.colors.primary.main,
	},
	addSectionBtn: {
		borderStyle: 'solid',
		borderWidth: '1px',
		backgroundColor: theme.colors.background.element.veryLight,
		color: theme.colors.primary.main,
		borderRadius: '8px',
		borderColor: theme.colors.background.element.medium,
		'& .MuiButton-label': {
			fontWeight: 700,
		},
		'&:hover': {
			color: theme.colors.primary.main,
			backgroundColor: theme.colors.background.element.medium,
		},
	},
}))
