import { gql } from '@apollo/client'

export const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
	mutation UpdateNotificationSetting(
		$jobsRecommendation: Boolean
		$completeOnboarding: Boolean
		$assignmentEndingReminder: Boolean
		$jobAlert: Boolean
		$documentExpired: Boolean
		$licenseExpired: Boolean
		$timecardSubmissionReminder: Boolean
		$interviewInvitationExpired: Boolean
	) {
		updateNotificationSetting(
			interviewInvitationExpired: $interviewInvitationExpired
			jobsRecommendation: $jobsRecommendation
			completeOnboarding: $completeOnboarding
			assignmentEndingReminder: $assignmentEndingReminder
			jobAlert: $jobAlert
			documentExpired: $documentExpired
			licenseExpired: $licenseExpired
			timecardSubmissionReminder: $timecardSubmissionReminder
		) {
			id
		}
	}
`

export const MARK_NOTIFICATION_STATUS_MUTATION = gql`
	mutation MarkNotificationsStatus($notificationIds: [String!]!, $status: NotificationStatusEnum) {
		markNotificationsStatus(notificationIds: $notificationIds, status: $status) {
			success
		}
	}
`
export const MARK_ALL_NOTIFICATION_STATUS_MUTATION = gql`
	mutation MarkAllNotificationsStatus($status: NotificationStatusEnum) {
		markAllNotificationsStatus(status: $status) {
			success
		}
	}
`

export const DELETE_NOTIFICATION_MUTATION = gql`
	mutation DeleteNotification($notificationIds: [String!]!) {
		destroyNotifications(notificationIds: $notificationIds) {
			success
		}
	}
`

export const DELETE_ALL_READ_NOTIFICATION_MUTATION = gql`
	mutation DeleteAllReadNotification {
		destroyNotifications {
			success
		}
	}
`
