import { Box, Grid, SvgIcon } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { useWindowScrollTop } from '~/common/hooks'
import { loadingState, observer } from '~/common/mobx.decorator'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { PlusBlueSvg } from '~/components/icons'
import { FieldArray, Form, Formik } from 'formik'
import { FormMode } from '@opus/web.core.form.utils'
import { referenceTabStore } from './reference-tab.store'
import { Button } from '~/components/button'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import SectionBox from '~/components/section-box'
import { Typography } from '~/components/typography'
import { masterStore } from '~/stores'
import { ReferenceForm } from '~/features/care-activation/components/reference-form'
import { differenceBy, isEmpty } from 'lodash'
import { logDefaultActionEvent, logClickEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { REFERENCE_TRACKING_EVENT } from '~/common/constants'
import HintBox from '~/features/care-activation/components/hint-box'
import * as yup from 'yup'
import validator from 'validator'
import { useStyles } from '~/features/care-profile/tabs/info/info-tab.style'

const validateSchema = yup.object({
	references: yup
		.array()
		.of(
			yup.object({
				firstName: yup.string().trim().required('Please enter first name').typeError('Please enter first name').max(60, 'Maximum is 60 characters'),
				lastName: yup.string().trim().required('Please enter last name').typeError('Please enter last name').max(60, 'Maximum is 60 characters'),
				email: yup.string().trim().email('Invalid email format, please recheck').required('Please enter email address'),
				phone: yup
					.string()
					.trim()
					.required('Please enter phone number')
					.typeError('Please enter phone number')
					.test('valid-phone-test', 'Invalid phone number format, please recheck', function (value) {
						if (!value) {
							return false
						}
						return validator.isMobilePhone(value)
					}),
				jobTitle: yup.string().trim().required('Please enter job title').typeError('Please enter job title').max(60, 'Maximum is 60 characters'),
				relationship: yup.string().trim().required('Please select reference relationship').typeError('Please select reference relationship'),
			})
		)
		.min(1, 'Please add at least one reference'),
})

const ReferenceViewForm = observer(({ initialValues = {}, onSubmit, onPreviousTab }) => {
	const { t } = useTranslation()
	const addButtonRef = useRef()
	const formRef = useRef()

	const classes = useStyles()
	const loading = loadingState('referenceTabStore/updateProfileReference')

	return (
		<Formik innerRef={formRef} enableReinitialize={true} initialValues={{ references: initialValues }} onSubmit={onSubmit} validationSchema={validateSchema}>
			{({ handleSubmit, values, errors }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<FieldArray name="references">
							{({ push, remove }) => (
								<Box>
									{values.references && values.references.length > 0 ? (
										values.references.map((item, index) => (
											<SectionBox
												minimumRemove={values.references?.length}
												minimumRemoveDialogTitle={t('$MESSAGES.MINIMUM_REFERENCE_REMOVE')}
												header={`${t('REFERENCE')}`}
												onRemove={() => {
													const newValues = values.references.map((item, idx) => ({
														id: item.id || null,
														email: item.email,
														firstName: item.firstName,
														lastName: item.lastName,
														phone: item.phone,
														relationship: item.relationship,
														jobTitle: item.jobTitle,
														state: item.location?.stateCode ?? null,
														city: item.location?.name ?? null,
														_destroy: index === idx,
													}))

													logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_REMOVE_REFERENCE_BTN, {
														workerReferences: [...newValues],
													})
													remove(index)
												}}
												showItemRemove
												hasBorder
											>
												<Box m={2}>
													<ReferenceForm mode={FormMode.Edit} formName="references" index={index} />
												</Box>
											</SectionBox>
										))
									) : (
										<Box display="flex" justifyContent="center" my={1}>
											<Typography color="textSecondary">{t('$ERRORS.EMPTY_REFERENCE')}</Typography>
										</Box>
									)}

									<Button
										ref={addButtonRef}
										variant="contained"
										color="secondary"
										startIcon={<SvgIcon component={PlusBlueSvg} viewBox="0 0 18 18"></SvgIcon>}
										onClick={() => {
											const nullReference = {
												email: '',
												firstName: '',
												lastName: '',
												phone: '',
												jobTitle: '',
												relationship: '',
												city: null,
												state: null,
											}

											push(nullReference)
											eventClient.logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_ADD_REFERENCE_BTN, {})
										}}
										className={classes.addSectionBtn}
									>
										{t('ADD_REFERENCE')}
									</Button>
								</Box>
							)}
						</FieldArray>

						<Box mt={2}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											eventClient.logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_PREVIOUS_BTN, {})
											onPreviousTab()
										}}
									>
										{t('PREVIOUS_STEP')}
									</Button>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									onClick={() => {
										eventClient.logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_NEXT_STEP_BTN, {})
									}}
								>
									<Button loading={loading} type="submit" fullWidth variant="contained" color="primary">
										{t('SAVE_AND_NEXT_STEP')}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Form>
				)
			}}
		</Formik>
	)
})

const ReferenceSection = observer(({ onNextTab, onPreviousTab }) => {
	const { workerReferences, references, updateProfileReference } = referenceTabStore

	const handleSubmitReference = async (values) => {
		const newValues = values.references.map((item) => ({
			id: item.id || null,
			email: item.email,
			firstName: item.firstName,
			lastName: item.lastName,
			phone: item.phone,
			relationship: item.relationship,
			jobTitle: item.jobTitle,
			state: !isEmpty(item?.state) ? item?.state : null,
			city: item?.city?.name ?? null,
		}))
		const removedValues = differenceBy(workerReferences, newValues, (item) => item.id)

		await updateProfileReference([...newValues, ...removedValues?.map((item) => ({ ...item, _destroy: true }))])
			.then(() => {
				logDefaultActionEvent(REFERENCE_TRACKING_EVENT.PROFILE_COMPLETION_REFERENCES_SUBMIT_SUCCESS, {
					workerReferences: [...newValues, ...removedValues.map((item) => ({ ...item, _destroy: true }))],
				})
			})
			.catch(() => {
				logDefaultActionEvent(REFERENCE_TRACKING_EVENT.PROFILE_COMPLETION_REFERENCES_SUBMIT_FAILED, {
					workerReferences: [...newValues, ...removedValues.map((item) => ({ ...item, _destroy: true }))],
				})
			})

		onNextTab()
	}

	return (
		<>
			<Box>
				<ReferenceViewForm onSubmit={handleSubmitReference} initialValues={references} onPreviousTab={onPreviousTab} />
			</Box>
		</>
	)
})

export const ReferenceTab = observer(({ hintText, onNextTab, onPreviousTab }) => {
	const { fetchWorkerReferences, addNewReference, references } = referenceTabStore
	const { fetchReferenceRelationship } = masterStore
	useWindowScrollTop()

	useEffectOnce(() => {
		logTrackingScreenEvent(REFERENCE_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
		fetchWorkerReferences()
		fetchReferenceRelationship()
	})

	const loading = loadingState('referenceTabStore/fetchWorkerReferences')

	useEffect(() => {
		if (loading === false && !references.length) {
			addNewReference()
		}
	}, [loading, references.length, addNewReference])

	return (
		<Box p={2}>
			<HintBox
				text={hintText}
				onSkipTab={() => {
					logClickEvent(REFERENCE_TRACKING_EVENT.CLICK_SKIP_STEP_4, {})
					onNextTab()
				}}
			/>

			<ReferenceSection onNextTab={onNextTab} onPreviousTab={onPreviousTab} />
		</Box>
	)
})
