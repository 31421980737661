import moment from 'moment'
import { DATE_FORMAT_SAVE, DATE_FORMAT_VIEW, DATE_TIME_FORMAT_VIEW, TIME_12_FORMAT_VIEW, TIME_FORMAT_SAVE } from '../constants'
import 'moment-duration-format'
import momentTimezone from 'moment-timezone'

export const dateFormat = (value, format = DATE_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const datetimeFormat = (value, format = DATE_TIME_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const toDate = (value, format = DATE_FORMAT_SAVE) => {
	return moment(value, format).toDate()
}

export const checkTimeValue = (time, format = TIME_FORMAT_SAVE) => moment(time, format).isValid()

export function calculateDuration(startTime, endTime) {
	if (!!startTime && !!endTime) {
		const startMoment = moment(startTime)
		const endMoment = moment(endTime)
		const duration = moment.duration(endMoment.diff(startMoment))
		let formattedDuration = ''

		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.asMinutes()) % 60

		if (hours > 0) {
			formattedDuration += `${hours} hour`
			if (hours > 1) {
				formattedDuration += 's'
			}
		}

		if (minutes > 0) {
			if (formattedDuration.length > 0) {
				formattedDuration += ' '
			}
			formattedDuration += `${minutes} minute`
			if (minutes > 1) {
				formattedDuration += 's'
			}
		}

		if (formattedDuration.length === 0) {
			formattedDuration = '0 seconds'
		}

		return formattedDuration
	} else {
		return 'Invalid time'
	}
}

export function convertToFormattedString(startTime) {
	if (!!startTime) {
		const startMoment = moment(startTime)

		const startDateString = startMoment.format('dddd MMM D, YYYY')
		const startTimeString = startMoment.format('h:mmA')

		return `${startDateString} ${startTimeString}`
	} else {
		return 'Invalid time'
	}
}

export function convertToFormatteDate(startDate) {
	if (!!startDate) {
		const startMoment = moment(startDate)

		return {
			day: startMoment.format('dddd'),
			month: startMoment.format('MMM'),
			numberDay: startMoment.format('D'),
			year: startMoment.format('YYYY'),
		}
	} else {
		return 'Invalid time'
	}
}

export function replaceDateInTimestamp(originalDate, newDate) {
	// Create a new Date object from the original date string
	if (!!originalDate && !!newDate) {
		const dateObj = new Date(originalDate.toString())

		// Create a new Date object from the desired date
		const desiredDate = new Date(newDate)

		// Update the year, month, and day of the date object
		dateObj.setFullYear(desiredDate.getFullYear())
		dateObj.setMonth(desiredDate.getMonth())
		dateObj.setDate(desiredDate.getDate())
		return dateObj
	}
}

export function calculateInterviewDuration(interviewStartTime, interviewEndTime) {
	const startTime = moment(interviewStartTime)
	const endTime = moment(interviewEndTime)

	return endTime.isBefore(startTime)
}

export const convertToISOString = (dateTime, timezone) => {
	return momentTimezone.tz(dateTime, timezone).toISOString()
}
export const convertToHoursAndMinutes = (workedHours) => {
	if (!workedHours) {
		return
	}
	const duration = moment.duration(workedHours, 'hours')
	const hours = Math.floor(duration.asHours())
	const minutes = Math.round(duration.asMinutes() % 60)
		.toString()
		.padStart(2, '0')
	return `${hours}:${minutes}`
}

export function convertTimeFormat(inputDuration) {
	if (!inputDuration) {
		return
	}
	const durationInHours = parseFloat(inputDuration)

	// Use moment.duration to handle the duration
	const duration = moment.duration(durationInHours, 'hours')

	// Extract hours and minutes
	const hours = Math.floor(duration.asHours())
	const minutes = Math.round(duration.asMinutes() % 60)
		.toString()
		.padStart(2, '0')

	// Format the result
	const formattedDuration = `${hours} hours ${minutes} mins`

	return formattedDuration
}

export function calculateDurationTime(startTime, endTime) {
	if (!startTime || !endTime) {
		return
	}
	const start = moment(startTime)
	const end = moment(endTime)

	return moment.duration(end.diff(start)).asHours()
}

export function calculateDurationAndFormatSchedule(startTime, endTime) {
	if (!startTime || !endTime) {
		return
	}
	const start = moment(startTime)
	const end = moment(endTime)

	const duration = moment.duration(end.diff(start))

	// Calculate the total minutes
	const totalMinutes = duration.asMinutes()

	// Calculate the hours and minutes
	const hours = Math.floor(totalMinutes / 60)
	const minutes = Math.floor(totalMinutes % 60)

	// Return the duration in the desired format
	return `${hours}:${minutes.toString().padStart(2, '0')}`
}

export function calculatePercentTime(time, totalDuration) {
	if (!time || !totalDuration) {
		return
	}
	return (time / totalDuration) * 100
}

export function formatTimestamp(timestamp, timezone) {
	if (!timestamp) {
		return
	}
	const parsedTimestamp = momentTimezone.tz(timestamp, timezone)

	const formattedTime = parsedTimestamp.format(TIME_12_FORMAT_VIEW)
	const formattedDate = parsedTimestamp.format('MMM D, YYYY')

	return `${formattedTime} - ${formattedDate}`
}

export function fillDayOfMonth(startDate, endDate) {
	const momentStartDate = moment(startDate)
	const momentEndDate = moment(endDate)
	const firstDayOfMonth = momentStartDate
		.clone()
		.subtract(momentStartDate.days() + 1, 'days')
		.toDate()
	const endDayOfMonth = momentEndDate
		.clone()
		.add(7 - momentEndDate.days(), 'days')
		.toDate()

	return { firstDayOfMonth, endDayOfMonth }
}

export const formatDayHeaderCalendar = ({ start, end }) => {
	let startDateFormat = 'MMM D'
	let endDateFormat = 'D, YYYY'
	if (moment(end).isAfter(moment(start), 'year')) {
		startDateFormat = 'MMM D, YYYY'
		endDateFormat = 'MMM D, YYYY'
	} else if (moment(end).isAfter(moment(start), 'month')) {
		startDateFormat = 'MMM D'
		endDateFormat = 'MMM D, YYYY'
	}
	return moment(start).format(startDateFormat) + ' - ' + moment(end).format(endDateFormat)
}
