import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, masterStore, notifyStore } from '~/stores'
import {
	createWorkerReference,
	getWorkerReferences,
	getWorkExperiences,
	UPDATE_PROFILE_REFERENCE_MUTATION,
} from '~/features/care-activation/care-activation.service'

import { isEmpty, omit } from 'lodash'
import { apolloClient } from '~/common/apollo'

@store()
class ReferenceTabStore {
	@observable workerReferences = []
	@observable workExperiences
	@observable referenceCreating = false
	@observable referenceUpdating = false
	@observable referenceCurrentId = ''
	@observable nullReferenceList = [
		{
			email: '',
			facilityName: '',
			firstName: '',
			lastName: '',
			phone: '',
			jobTitle: '',
			referenceType: '',
			fromDate: null,
			toDate: null,
			relationship: '',
			city: null,
			state: null,
		},
		{
			email: '',
			facilityName: '',
			firstName: '',
			lastName: '',
			phone: '',
			jobTitle: '',
			referenceType: '',
			fromDate: null,
			toDate: null,
			relationship: '',
			city: null,
			state: null,
		},
	]

	@computed
	get references() {
		return (
			this.workerReferences?.map(({ referenceType, jobTitle, city, state, country, ...rest }) => ({
				...rest,
				jobTitle: jobTitle,
				location: city && state ? { country, stateCode: state, name: city } : null,
				city: city,
				state: state,
			})) || []
		)
	}

	@computed
	get salutationOptions() {
		const arr = masterStore.salutations
		return Object.keys(arr).map((key) => ({ value: key, label: arr[key] }))
	}

	@computed
	get facilityOptions() {
		return this.workExperiences?.map((facility) => ({ label: facility.facilityName, value: facility.facilityName }))
	}

	@action
	fetchWorkerReferences = async () => {
		const workerReferences = await getWorkerReferences(authStore.id)
		this.workerReferences =
			workerReferences?.map(({ phone, ...rest }) => {
				// Regular expression to match the phone number pattern
				const phoneRegex = /\((\d{3})\) (\d{3})-(\d{4})/
				let transformedPhone = phone

				// Check if the phone matches the pattern
				const match = phone.match(phoneRegex)
				if (match) {
					// Construct the phone number in the new format
					transformedPhone = `+1${match[1]}${match[2]}${match[3]}`
				}

				return {
					...rest,
					phone: transformedPhone, // Use the transformed phone number
				}
			}) || []
	}

	@action
	fetchWorkExperiences = async () => {
		this.workExperiences = await getWorkExperiences(authStore.id)
	}

	@action
	handleSubmitReference = async ({ jobTitle, ...data }) => {
		try {
			const dataItem = { ...data }
			dataItem.referenceType = data?.referenceType ? 'former' : 'current'
			dataItem.state = !isEmpty(data?.state) ? data?.state : null
			dataItem.city = data?.city?.name
			await createWorkerReference({
				id: authStore.id,
				workerReferences: omit({ ...dataItem, jobTitle: jobTitle }, ['location', 'id']),
			})
			await this.fetchWorkerReferences()

			this.setReferenceCreating(false)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action updateProfileReference = async (values) => {
		try {
			await apolloClient.mutate({
				mutation: UPDATE_PROFILE_REFERENCE_MUTATION,
				variables: {
					workerReferences: [...values],
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@computed
	get relationshipOptions() {
		const arr = masterStore.relationships
		return Object.keys(arr).map((key) => ({ value: key, label: arr[key] }))
	}

	@action
	setReferenceCreating = (flag) => {
		this.referenceCreating = flag
	}
	@action
	setReferenceUpdating = (flag) => {
		this.referenceUpdating = flag
	}
	@action
	setReferenceCurrentId = (flag) => {
		this.referenceCurrentId = flag
	}

	@action
	addNewReference = () => {
		const nullReference = {
			email: '',
			facilityName: '',
			firstName: '',
			lastName: '',
			phone: '',
			jobTitle: '',
			referenceType: '',
			fromDate: null,
			toDate: null,
			relationship: '',
			city: null,
			state: null,
		}

		this.workerReferences = [...this.workerReferences, nullReference]
	}
}

export const referenceTabStore = new ReferenceTabStore()
