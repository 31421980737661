import { Box, Collapse, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core'
import { TextField } from '@opus/web.core.form.text-field'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useField } from 'formik'
import { find } from 'lodash'
import React, { useMemo } from 'react'
import { css } from 'styled-components'
import { useFieldError } from '~/common/hooks'
import { CheckField } from '~/components/fields'
import { Typography } from '~/components/typography'

export const verficiationStyled = css`
	span > p {
		${({ theme }) => theme.typography.p1};
		color: ${({ theme }) => theme.colors.content.dark};
		font-weight: 500;
		letter-spacing: 0.28px;
	}
	.MuiFormControlLabel-label {
		color: black;
		${({ theme }) => theme.typography.p1};
		line-height: 19px;
	}
`

export const radioStyle = css`
	margin: 2px 9px;
	padding: 0;

	+ span {
		color: ${({ theme }) => theme.colors.background.element.veryDark}
		font-size: 14px;
	}

	&.Mui-checked {
		${({ theme }) => theme.colors.background.element.dark}
		+ span {
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}
`

export const checkboxStyle = css`
	.MuiFormControlLabel-label {
		font-size: 14px;
		color: ${({ theme }) => theme.colors.background.element.veryDark};
	}
`

const QuestionGroup = ({ name, title, description, quizAnswers }) => {
	const { t } = useTranslation()
	const [quizField, reasonField] = useMemo(() => [`${name}.quizAnswerId`, `${name}.reason`, `${name}.required`], [name])

	const [field, meta] = useField({ name: quizField })

	const error = useFieldError(meta)

	const handleChange = (_, value) => {
		field.onChange({ target: { name: quizField, value } })
	}

	const quizAnswerId = useMemo(() => field.value || '', [field])

	const answer = useMemo(() => find(quizAnswers, { id: quizAnswerId }), [quizAnswers, quizAnswerId])

	const label = useMemo(() => (answer?.name === 'No' ? t('IF_NO_EXPLAIN') : t('IF_YES_EXPLAIN')), [answer, t])

	return (
		<Box mb={1} id={name}>
			<Typography>{t(title)}</Typography>
			<Box mt={1} mb={1} id={quizField}>
				<FormControl error={!!error}>
					<RadioGroup value={quizAnswerId} defaultValue={quizAnswerId} onChange={handleChange}>
						<Box display="flex">
							{quizAnswers?.map((answer) => (
								<Box key={answer.id} mr={1}>
									<FormControlLabel value={answer.id} label={answer.name} control={<Radio size="small" css={radioStyle} />} />
								</Box>
							))}
						</Box>
					</RadioGroup>
					<FormHelperText>{error}</FormHelperText>
				</FormControl>
			</Box>
			<Collapse in={answer?.requireReason}>
				<TextField name={reasonField} placeholder={label} multiline />
				<FormHelperText style={{ color: '#FF2727' }}>{description}</FormHelperText>
			</Collapse>
		</Box>
	)
}

export const VerificationForm = ({ questions, termsOfService }) => {
	return (
		<>
			<Grid container spacing={3}>
				{questions?.map(({ question, description, quizAnswers }, index) => (
					<Grid key={index} item xs={12}>
						<QuestionGroup name={`workerQuizAnswers.${index}`} title={question} description={description} quizAnswers={quizAnswers} />
					</Grid>
				))}
			</Grid>

			<Box css={verficiationStyled} mb={2}>
				<span dangerouslySetInnerHTML={{ __html: termsOfService?.description }} />
			</Box>

			<Box mb={2} css={checkboxStyle} className="terms-of-service-checkbox">
				<CheckField name="termsOfServiceId" label={termsOfService?.confirmationMessage} />
			</Box>
		</>
	)
}
