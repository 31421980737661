import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Lottie from 'react-lottie'
import { useEffectOnce } from 'react-use'
import { LOTTIES } from '~/common/constants'
import { containerStyle } from './error-500.style'

export const Error500 = ({ delay }) => {
	const [show, setShow] = useState(false)
	useEffectOnce(() => {
		setTimeout(() => {
			setShow(true)
		}, [delay])
	})

	if (!show) {
		return null
	}

	return (
		<Grid container justify="center" alignItems="center" css={containerStyle}>
			<Lottie
				width="100%"
				height="auto"
				options={{
					path: LOTTIES.internal_error,
					loop: true,
					autoplay: true,
					rendererSettings: {
						preserveAspectRatio: 'xMidYMid slice',
					},
				}}
			/>
		</Grid>
	)
}

Error500.defaultProps = {
	delay: 500,
}
