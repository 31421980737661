import React, { FC, ReactNode, createContext } from 'react'
import { IPermissionService } from '../interfaces'

export const PermissionContext = createContext<Partial<IPermissionService>>({})

export interface IPermissionProviderProps {
	permissionService: IPermissionService
	children?: ReactNode
}

export const PermissionProvider: FC<IPermissionProviderProps> = ({ permissionService, children }) => {
	return <PermissionContext.Provider value={permissionService}>{children}</PermissionContext.Provider>
}
