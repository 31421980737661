import { Box, Chip, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { LabelField } from '@opus/web.core.form.label-field'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useField, useFormikContext } from 'formik'
import { drop, find, isEmpty, isNil, uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useEffectOnce } from 'react-use'
import { css } from 'styled-components'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import { TextField } from '~/components/fields'
import { observer } from 'mobx-react-lite'
import { masterStore } from '~/stores'
import { Typography } from '~/components/typography'
import { CheckBoxSvg, CheckedBoxSvg } from '~/components/icons'
import { chipClearIconCss, useClipClasses } from '~/components/fields/autocomplete-field/autocomplete-field.style'
import { Clear } from '@material-ui/icons'

export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main} !important;
	margin-bottom: 4px;

	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}

	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`

const useStyles = makeStyles((theme) => ({
	clearIndicator: {
		backgroundColor: 'transparent',

		'&.MuiAutocomplete-clearIndicatorDirty': {
			visibility: 'visible',
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'& span': {
			'& svg': {
				fill: theme.palette.status.error.medium,
				fontSize: '16px',
			},
		},
	},
}))

export const CityStateCombinedField = observer(
	({
		name,
		validate,
		label,
		placeholder,
		disabled,
		freeSolo,
		mode = FIELD_MODE.edit,
		multiple = true,
		disableClearable,
		onSelect,
		defaultValue = [],
		textFieldVariant,
		endLabel,
		maxItemSelected,
		excludedStates = [],
		hideState,
	}) => {
		const { t } = useTranslation()
		const { cityStatesCombined, licenseStates, fetchCityState, originalLocations } = masterStore
		const classes = useStyles()
		const chipClasses = useClipClasses()
		const { setFieldValue } = useFormikContext()
		const [inputValue, updateInputValue] = useState('')
		const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
		const [, focusProps] = useFieldFocused(field)

		const [options, setOptions] = useState([])

		useEffect(() => {
			if (!cityStatesCombined || !licenseStates) {
				return
			}
			setOptions((prevState) =>
				uniqBy(
					[
						...prevState,
						...originalLocations.map((item) => ({
							label: `${item.name}${item.state_code ? `, ${item.state_code}` : ''}`,
							value: `${item.name}${item.state_code ? `, ${item.state_code}` : ''}`,
						})),
						...cityStatesCombined,
						...licenseStates,
					],
					'label'
				)
					?.filter((item) => !excludedStates.includes(item.value))
					.sort((a, b) => a.label.localeCompare(b.label))
			)
		}, [cityStatesCombined, licenseStates, originalLocations, excludedStates])

		useEffectOnce(() => {
			if (!isEmpty(defaultValue)) {
				setFieldValue(name, [...defaultValue])
			}
		})

		const valueTransform = useCallback(
			(value) =>
				value
					? isNil(value.city) && value.state
						? { label: licenseStates.find((item) => item.value === value.state)?.label, value: value.state }
						: { label: `${value.city}, ${value.state}`, value: `${value.city}, ${value.state}` }
					: {},
			[licenseStates]
		)

		const selectedOption = useMemo(() => {
			if (multiple) {
				if (!field.value) {
					return []
				}
				return field.value?.map(valueTransform)
			}

			return find(options, (option) => option.value === field.value)
		}, [options, field.value, multiple, valueTransform])

		const errorMsg = meta?.error
		const errorFlat = useFieldError(meta)
		const errorNest = useFieldError(meta, 'city')
		const error = typeof errorMsg === 'string' ? errorFlat : errorNest

		const handleChange = useCallback(
			(_, options) => {
				if (!options.length) {
					setFieldValue(name, [])

					return
				}

				const addresses = options.map((option) => {
					const addressData = option.value?.split(',')?.map((item) => item?.trim())

					if (addressData.length === 1) {
						return {
							city: null,
							state: addressData[0],
						}
					}

					return {
						city: addressData[0],
						state: addressData[1],
					}
				})

				setFieldValue(name, maxItemSelected && addresses.length > maxItemSelected ? drop(addresses, 1) : addresses)
			},
			[setFieldValue, name, maxItemSelected]
		)

		if (mode === FIELD_MODE.view) {
			if (multiple) {
				return (
					<Box>
						<Box mb={1} css={labelStyle}>
							<label> {t(label)} </label>
						</Box>
						{selectedOption?.length > 0 ? (
							<Box display="flex" gridGap={8} flexWrap="wrap">
								{selectedOption?.map((item) => (
									<Chip size="small" classes={chipClasses} label={`${item.label}`} />
								))}
							</Box>
						) : (
							'--'
						)}
					</Box>
				)
			} else {
				return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => selectedOption} />
			}
		}

		return (
			<Autocomplete
				key={name}
				id={name}
				freeSolo={freeSolo}
				options={options}
				getOptionSelected={(option, value) => option.value === value.value}
				getOptionLabel={(option) => option.label}
				ChipProps={{ classes: chipClasses, deleteIcon: <Clear css={chipClearIconCss} />, size: 'small' }}
				renderOption={(option, { selected }) => (
					<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
						<Typography variant="h4">{option.label}</Typography>
						{selected ? <CheckedBoxSvg /> : <CheckBoxSvg />}
					</Box>
				)}
				fullWidth
				disableCloseOnSelect={multiple}
				multiple={multiple}
				{...field}
				{...focusProps}
				disabled={disabled}
				onChange={handleChange}
				disableClearable={disableClearable}
				classes={classes}
				value={selectedOption}
				defaultValue={defaultValue}
				popupIcon={<MdExpandMore />}
				inputValue={inputValue}
				onInputChange={(event, newInputValue, reason) => {
					if (reason === 'reset') {
						return
					}
					updateInputValue(newInputValue)

					void fetchCityState(true, newInputValue)
				}}
				renderInput={(params) => {
					return (
						<>
							<TextField
								{...params}
								label={t(label)}
								disabled={disabled}
								placeholder={multiple && selectedOption?.length > 0 ? undefined : placeholder && t(placeholder)}
								error={!!error}
								helperText={error}
								variant={textFieldVariant}
								endLabel={endLabel}
								InputProps={{
									...params.InputProps,
								}}
							/>
						</>
					)
				}}
			/>
		)
	}
)
