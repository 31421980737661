import React, { useState } from 'react'
import { FC } from 'react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AuthProvider, IAuthProviderProps } from './AuthProvider'
import { DataProvider, IDataProviderProps } from './DataProvider'
import { IPermissionProviderProps, PermissionProvider } from './PermissionProvider'
import { AuditProvider, IAuditProviderProps } from './AuditProvider'

export interface ICoreProviderProps extends IAuthProviderProps, IDataProviderProps, IPermissionProviderProps, IAuditProviderProps {}

export const CoreProvider: FC<ICoreProviderProps> = ({ authService, dataService, permissionService, auditService, children }) => {
	const [queryClient] = useState(new QueryClient())

	return (
		<QueryClientProvider client={queryClient}>
			<PermissionProvider permissionService={permissionService}>
				<AuditProvider auditService={auditService}>
					<AuthProvider authService={authService}>
						<DataProvider dataService={dataService}>{children}</DataProvider>
					</AuthProvider>
				</AuditProvider>
			</PermissionProvider>
		</QueryClientProvider>
	)
}
