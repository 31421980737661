import { css } from 'styled-components'

export const termCheckBoxStyle = css`
	&.terms-of-service-checkbox {
		.MuiFormControlLabel-label {
			${({ theme }) => theme.typography.p1};
			color: ${({ theme }) => theme.palette.content.dark};
			font-weight: 500;
		}
	}

	.MuiFormControlLabel-root {
		.MuiCheckbox-colorSecondary.Mui-checked {
			color: ${({ theme }) => theme.colors.secondary.sub};
		}
	}

	.MuiFormControlLabel-root:has(+ .MuiFormHelperText-root.Mui-error) {
		.MuiCheckbox-colorSecondary {
			color: red;
		}
	}
`
