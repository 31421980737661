import { Typography } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { NotifyDialog } from '../notify'

export const DiscardDialog = ({ children, onOk, onCancel, open }) => {
	const { t } = useTranslation()
	const [showDialog, updateShowDialog] = useState(false)

	const handleOk = useCallback(async () => {
		if (typeof onOk === 'function') {
			await onOk()
		}
		updateShowDialog(false)

		if (typeof onCancel === 'function') {
			onCancel()
		}
	}, [onOk, onCancel])

	const handleCancel = useCallback(async () => {
		if (typeof onCancel === 'function') {
			await onCancel()
		}

		updateShowDialog(false)
	}, [onCancel])

	const handleClick = useCallback(() => {
		updateShowDialog(true)
	}, [])

	return (
		<>
			{children && React.cloneElement(children, { onClick: handleClick })}
			<NotifyDialog open={open || showDialog} okText="Discard" onOk={handleOk} title={t('$MESSAGES.DISCARD_CONFIRM_TITLE')} onCancel={handleCancel}>
				<Typography variant="body1" color="textSecondary">
					{t('$MESSAGES.CONFIRM_DISCARD')}
				</Typography>
			</NotifyDialog>
		</>
	)
}
