import { css } from 'styled-components'

export const signinLogoStyle = css`
	text-align: center;
	img {
		max-width: 182px;
	}
`

export const signinLogoJTPStyle = css`
	text-align: center;
	img {
		height: 100px;
		width: auto;
	}
`

export const signupTextStyle = css`
	font-size: 14px;
	padding-right: 5px;
`

export const socialBtnContainerStyle = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 30px;

	.apple-btn,
	.google-btn,
	.linkedin-btn,
	.facebook-btn {
		border-radius: 18px !important;
		min-width: 64px !important;
		height: 64px;
		& > div {
			display: initial !important;
		}
	}

	${({ theme }) => theme.breakpoints.down('xs')} {
		flex-wrap: wrap;
		gap: 10px;
		.apple-btn,
		.google-btn,
		.linkedin-btn,
		.facebook-btn {
			height: 42px;
		}
	}
`

export const gridCotainerStyle = css`
	gap: 32px;
`
export const welcomeTitleStyle = css`
	${({ theme }) => theme.typography.h1};
	color: ${({ theme }) => theme.palette.primary.main};
`

export const proceedStyle = css`
	${({ theme }) => theme.typography.h4};
	color: ${({ theme }) => theme.colors.primary.main};
	font-weight: 800;
`
export const h4Text = css`
	${({ theme }) => theme.typography.h4};
	color: ${({ theme }) => theme.palette.content.dark};
	font-weight: 500;
`
export const signupBtnStyle = css`
	height: 48px;
	padding: 12px 24px;
	border-radius: 48px;
	.MuiTypography-root {
		text-transform: none;
		font-size: 16px !important;
		font-style: normal !important;
		font-weight: 800 !important;
		line-height: 24px !important;
	}
`

export const signinBtnStyle = css`
	z-index: 100;
	height: 48px;
	padding: 12px 24px;
	border-radius: 48px;
	.MuiTypography-root {
		text-transform: none;
		font-size: 16px !important;
		font-style: normal !important;
		font-weight: 800 !important;
		line-height: 24px !important;
	}
`
