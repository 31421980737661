import { action, computed, observable, store } from '~/common/mobx.decorator'
import { differenceBy, differenceWith, flatMap, groupBy, isEmpty, isEqual, isNil, map, mapValues, omit, pick, reduce } from 'lodash'
import {
	apolloClient,
	GET_DISCIPLINES_SPECIALTIES_QUERY,
	GET_EMERGENCY_CONTACTS_QUERY,
	GET_PROFILE_BASIC_INFO_QUERY,
	GET_PROFILE_PHONE_VERIFIED_QUERY,
	UPDATE_DISCIPLINES_SPECIALTIES_MUTATION,
	UPDATE_EMERGENCY_CONTACTS_MUTATION,
	UPDATE_WORKER_INFO_AND_CONTACTS_MUTATION,
} from '~/common/apollo'
import { authStore, notifyStore } from '~/stores'
import { captureException, mutateWithTrimmedVariables } from '~/common/helpers'
import { logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { ACTIATION_TRACKING_CONSTANT } from '../../care-activation.constant'
import { careStore } from '~/companies/care/care.store'

const VALID_ADDRESS_FIELDS = ['id', 'street', 'city', 'zipcode', 'country', 'state', 'default', 'latitude', 'longitude']

@store()
class InfoTabStore {
	@observable worker = {}
	@observable contacts = []
	@observable submitPersonalInfo = {}
	@observable submitPersonalInfoValid = false
	@observable workerNewEmail

	@observable groupedSpecialties = {}
	@observable groupedSpecialtiesPure = {}
	@observable primarySpecialty = {}
	@observable trackingParams = {}
	@observable professionInfo = []
	@observable disciplineSpecialties = []
	@observable showVerifyPhoneModal = false

	@action
	setTrackingParams = (params) => {
		this.trackingParams = params
	}

	@action
	setWorkerNewEmail = (value) => {
		this.workerNewEmail = value
	}

	@computed
	get hasAssignedRecruiterOrManager() {
		return this.worker?.hasAssignedRecruiterOrManager
	}

	@computed
	get workerPhone() {
		return this.worker?.phone
	}

	@computed
	get emailVerified() {
		return !!this.worker?.emailVerifiedAt
	}

	@computed
	get phoneVerified() {
		return !!this.worker?.phoneVerifiedAt
	}

	@computed
	get workerEmail() {
		return this.worker?.email
	}

	@computed
	get phoneNotExisted() {
		return isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
	}

	@computed
	get groupedSpecialtiesExcludePrimary() {
		return omit(this.groupedSpecialties, [this.primarySpecialty.profession])
	}

	@computed
	get groupedSpecialtiesPrimary() {
		return Object.keys(this.groupedSpecialties).reduce((accumulative, currentValue) => {
			accumulative[currentValue] = this.groupedSpecialties[currentValue].filter((item) => item.id !== this.primarySpecialty.specialty)

			return accumulative
		}, {})
	}

	@computed
	get workingPreferredLocations() {
		return this.worker?.workingPreferredLocations?.find((item) => item.isPrimary)
	}

	@computed
	get otherPreferredLocations() {
		return this.worker?.workingPreferredLocations?.filter((item) => !item.isPrimary) ?? []
	}

	@computed
	get primaryAddress() {
		return this.worker?.addresses?.find((item) => item.default)
	}

	@computed
	get temporaryAddress() {
		return this.worker?.addresses?.find((item) => !item.default)
	}


	@computed
	get infoInitialValues() {
		return {
			...omit(this.worker, 'phoneVerifiedAt'),
			workerAddress: this.primaryAddress && pick(this.primaryAddress, VALID_ADDRESS_FIELDS),
			temporaryAddress: this.temporaryAddress && pick(this.temporaryAddress, VALID_ADDRESS_FIELDS),
			preferredLocation: this.workingPreferredLocations?.state,
			otherPreferredLocations: this.otherPreferredLocations?.map((item) => item.state),
		}
	}

	@computed
	get professionInitialValues() {
		return {
			specialty: [],
			discipline: null,
		}
	}

	@computed
	get contactInitialValues() {
		return [...(this.contacts ?? [])]
			.filter((item) => !item._destroy)
			.map((contact) => ({
				...contact,
				phone: contact?.phone?.replace(/-/g, ''),
			}))
	}

	@action
	addNewContact = () => {
		const nullContact = {
			firstName: null,
			lastName: null,
			phone: null,
			relationship: null,
		}
		this.contacts = [...this.contacts, nullContact]
	}

	@action
	syncPersonalInfo = (newInfo) => {
		this.submitPersonalInfo = { ...newInfo }
	}

	@action
	syncContactsValid = (id, isValid) => {
		this.submitContactsValid = { ...this.submitContactsValid, [id]: isValid }
	}

	@action
	syncContact = (newContact) => {
		this.contacts = this.contacts.map((item) => {
			if (!isNil(item.tempId) && !isNil(newContact.tempId) && item.tempId === newContact.tempId) {
				return {
					...newContact,
				}
			}

			if (!isNil(item.id) && !isNil(newContact.id) && item.id === newContact.id) {
				return {
					...newContact,
				}
			}

			return item
		})
	}

	@action
	updatePersonalInfo = async () => {
		return Promise.all([this.handleSubmitInfo(this.submitPersonalInfo), this.updateEmergencyContacts(this.contacts.map((item) => omit(item, 'tempId')))])
	}

	@action
	addProfessions = async (profession, specialties) => {
		if (specialties.length)
			await Promise.all(
				specialties.map((specialty, index) => {
					const submitData = {
						disciplineId: profession,
						specialtyId: specialty,
						isPrimary: index === 0 && !Object.keys(this.groupedSpecialties).length,
					}

					return this.updateDisciplinesSpecialties(submitData)
				})
			).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
		else {
			const submitData = {
				disciplineId: profession,
				specialtyId: null,
				isPrimary: !Object.keys(this.groupedSpecialties).length,
			}

			await this.updateDisciplinesSpecialties(submitData).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
		}

		void this.fetchDisciplinesSpecialties()
	}

	@action
	forceAddProfessions = async (professions) => {
		professions.map(async (profession) => {
			if (profession.isPrimary) {
				await this.updateDisciplinesSpecialties({
					disciplineId: profession.profession,
					isPrimary: true,
				})
			}
			if (profession.specialties?.length) {
				await Promise.all(
					profession.specialties.map((specialty) => {
						const submitData = {
							disciplineId: profession.profession,
							specialtyId: specialty,
							isPrimary: profession.primarySpecialty === specialty,
						}

						return this.updateDisciplinesSpecialties(submitData)
					})
				).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
			} else {
				const submitData = {
					disciplineId: profession.profession,
					specialtyId: null,
					isPrimary: profession.isPrimary,
				}
				await this.updateDisciplinesSpecialties(submitData).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
			}
		})

		void this.fetchDisciplinesSpecialties()
	}

	@action
	removeProfession = async (profession) => {
		const removeProfession = this.groupedSpecialties[profession]?.map((specialty) => {
			return {
				id: specialty.parentId,
				_destroy: true,
			}
		})
		await this.setProfessionInfo([...this.professionInfo, removeProfession])

		// void notifyStore.success('$MESSAGES.SUCCESSFUL')
		//
		// void this.fetchDisciplinesSpecialties()
	}

	@action
	removeSpecialty = async (profession, specialty) => {
		const submitData = {
			id: specialty.parentId,
			_destroy: true,
		}
		await this.setProfessionInfo([...this.professionInfo, submitData])
	}

	@action
	setPrimaryProfession = async (profession) => {
		const currentPrimaryData = {
			id: this.primarySpecialty.parentId,
			isPrimary: false,
		}
		const submitData = {
			id: this.groupedSpecialties[profession]?.[0]?.parentId,
			isPrimary: true,
		}

		await this.setProfessionInfo([...this.professionInfo, submitData, currentPrimaryData])
	}

	@action
	setPrimarySpecialty = async (profession, specialty) => {
		const currentPrimaryData = {
			id: this.primarySpecialty.parentId,
			isPrimary: false,
		}
		const submitData = {
			id: specialty.parentId,
			isPrimary: true,
		}

		await this.setProfessionInfo([...this.professionInfo, submitData, currentPrimaryData])
	}
	@action
	setProfessionInfo = async (value) => {
		if (!!value) {
			this.professionInfo = value
		} else {
			this.professionInfo = []
		}
	}

	@action
	fetchWorkerBasicInfo = async () => {
		const { data } = await apolloClient.query({
			query: GET_PROFILE_BASIC_INFO_QUERY,
		})
		this.worker = data?.worker
		this.workerNewEmail = this.worker?.email

		// this.verifyPhoneState = isEmpty(this.worker?.phoneVerifiedAt)
		// this.addPhoneState = isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
	}

	@action
	fetchWorkerPhoneVerified = async (setValues) => {
		const { data } = await apolloClient.query({
			query: GET_PROFILE_PHONE_VERIFIED_QUERY,
		})
		this.worker.phoneVerifiedAt = data?.worker?.phoneVerifiedAt
		this.worker.phone = data?.worker?.phone

		if (typeof setValues === 'function') {
			setValues(
				omit({
					...this.submitPersonalInfo,
					phone: data?.worker?.phone,
					phoneVerifiedAt: data?.worker?.phoneVerifiedAt,
				})
			)
		}
	}

	@action
	fetchDisciplinesSpecialties = async () => {
		const { data } = await apolloClient.query({
			query: GET_DISCIPLINES_SPECIALTIES_QUERY,
			variables: {
				id: authStore.id,
			},
		})

		const results = data?.worker?.workerDisciplineSpecialties ?? []

		this.groupedSpecialties = mapValues(
			groupBy(results, (specialty) => specialty?.discipline?.id),
			(values) => values.map((value) => ({ ...value.specialty, parentId: value.id, isPrimary: value.isPrimary }))
		)
		this.groupedSpecialtiesPure = mapValues(
			groupBy(results, (specialty) => specialty?.discipline?.id),
			(values) => values.map((value) => ({ ...value.specialty, parentId: value.id, isPrimary: value.isPrimary }))
		)
		const matchedResult = results?.find((specialty) => specialty.isPrimary)
		if (matchedResult) {
			this.primarySpecialty = {
				profession: matchedResult?.discipline?.id,
				professionName: matchedResult?.discipline?.disciplineName,
				specialty: matchedResult?.specialty?.id,
				specialtyName: matchedResult?.specialty?.name,
				parentId: matchedResult?.id,
				hasSkillChecklist: matchedResult.specialty?.hasSkillChecklist,
			}
		}
	}

	@action
	updateDisciplinesSpecialties = async (values) => {
		try {
			await apolloClient.mutate({
				mutation: UPDATE_DISCIPLINES_SPECIALTIES_MUTATION,
				variables: {
					id: authStore.id,
					workerDisciplineSpecialties: { ...values },
				},
			})
			await careStore.fetchWorkerBasicInfo()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchEmergencyContacts = async () => {
		const { data } = await apolloClient.query({
			query: GET_EMERGENCY_CONTACTS_QUERY,
			variables: {
				id: authStore.id,
			},
		})
		this.contacts = data?.worker?.workerEmergencyContacts ?? []
	}

	@action
	updateEmergencyContacts = async (values) => {
		try {
			await mutateWithTrimmedVariables({
				mutation: UPDATE_EMERGENCY_CONTACTS_MUTATION,
				variables: {
					id: authStore.id,
					workerEmergencyContacts: values,
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleSubmitInfo = async (values) => {
		try {
			const updateData = { ...values }

			updateData.addresses = []
			const oldProfessionsSpecialties = this.groupedSpecialtiesPure
			const newProfessionsSpecialties = this.professionInfo //this.submitPersonalInfo.professionInfo

			if (updateData.workerAddress?.city || updateData.workerAddress?.street || updateData.workerAddress?.state) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...pick(updateData.workerAddress, VALID_ADDRESS_FIELDS),
						default: true,
						zipcode: updateData.workerAddress.zipcode?.toString() ?? '',
					},
				]
			}

			if (updateData.temporaryAddress?.city || updateData.temporaryAddress?.street || updateData.temporaryAddress?.state) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...pick(updateData.temporaryAddress, VALID_ADDRESS_FIELDS),
						default: false,
						zipcode: updateData.temporaryAddress.zipcode?.toString() ?? '',
					},
				]
			}

			if (
				this.infoInitialValues.temporaryAddress?.id &&
				!updateData.temporaryAddress?.city &&
				!updateData.temporaryAddress?.street &&
				!updateData.temporaryAddress?.state
			) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...this.infoInitialValues.temporaryAddress,
						_destroy: true,
					},
				]
			}
			if (values.preferredLocation !== this.workingPreferredLocations?.state) {
				if (this.workingPreferredLocations?.id) {
					updateData.workingPreferredLocations = [
						{
							...this.workingPreferredLocations,
							_destroy: true,
						},
					]
				}
				updateData.workingPreferredLocations = [
					...(updateData.workingPreferredLocations ?? []),
					{
						state: values.preferredLocation,
						isPrimary: true,
					},
				]
			}

			if (!isEqual(values.otherPreferredLocations, this.otherPreferredLocations)) {
				const mappedOtherPreferredLocations =
					values.otherPreferredLocations?.map((item) => ({
						state: item,
						isPrimary: false,
					})) ?? []

				const newItems = differenceWith(mappedOtherPreferredLocations, this.otherPreferredLocations, (item1, item2) => item1.state === item2.state)

				const removedItems = differenceWith(
					this.otherPreferredLocations,
					mappedOtherPreferredLocations,
					(item1, item2) => item1.state === item2.state
				).map((item) => ({ id: item.id, _destroy: true }))

				updateData.workingPreferredLocations = [...(updateData.workingPreferredLocations ?? []).filter((item) => item.isPrimary), ...newItems, ...removedItems]
			}

			if (values?.workerEmergencyContacts?.length > 0) {
				if (Object.values(values.workerEmergencyContacts?.[0]).every((value) => isEmpty(value))) {
					delete updateData.workerEmergencyContacts
				} else {
					const removedContactValues = differenceBy(this.contacts, values.workerEmergencyContacts, (item) => item.id)
					updateData.workerEmergencyContacts = [
						...values.workerEmergencyContacts,
						...removedContactValues?.map((item) => ({
							...item,
							_destroy: true,
						})),
					]
				}
			}

			if (!isNil(newProfessionsSpecialties)) {
				const mappingOldSpecialty = flatMap(Object.entries(oldProfessionsSpecialties || {}), ([key, value]) => {
					if (value.length === 0 && !!key) {
						return [{ key, ...value }]
					}
					return value.map((item) => ({ ...item, key }))
				})

				const mappingNewSpecialty = Object.entries(newProfessionsSpecialties || {}).flatMap(([key, value]) => {
					if (value.length === 0 && !!key) {
						return [{ key, ...value }]
					}
					return value.map((item) => ({ ...item, key }))
				})

				const oldCheckSpecialty = mappingOldSpecialty?.find((item) => item.isPrimary === true)
				const newCheckSpecialty = mappingNewSpecialty?.find((item) => item.isPrimary === true)
				const checkIsChangePrimarySpecialtyIdOld =
					oldCheckSpecialty?.hasOwnProperty('id') && !isEmpty(oldCheckSpecialty) ? oldCheckSpecialty?.id : oldCheckSpecialty?.parentId
				const checkIsChangePrimarySpecialtyIdNew =
					newCheckSpecialty?.hasOwnProperty('id') && !isEmpty(newCheckSpecialty) ? newCheckSpecialty?.id : newCheckSpecialty?.parentId
				const isChangePrimarySpecialty =
					checkIsChangePrimarySpecialtyIdOld !== checkIsChangePrimarySpecialtyIdNew &&
					!!checkIsChangePrimarySpecialtyIdOld &&
					!!checkIsChangePrimarySpecialtyIdNew

				if (isChangePrimarySpecialty) {
					const oldPrimarySpecialty = mappingOldSpecialty?.find((item) => item.isPrimary === true)
					const newPrimarySpecialty = mappingNewSpecialty?.find((item) => item.isPrimary === true)

					const updatedSpecialties = [...(updateData.workerDisciplineSpecialties ?? [])]

					if (oldPrimarySpecialty) {
						updatedSpecialties.push({
							id: oldPrimarySpecialty?.parentId,
							disciplineId: oldPrimarySpecialty?.key,
							specialtyId: oldPrimarySpecialty?.id,
							isPrimary: false,
						})
					}

					if (newPrimarySpecialty) {
						updatedSpecialties.push({
							id: newPrimarySpecialty?.parentId,
							disciplineId: newPrimarySpecialty?.key || newPrimarySpecialty?.parentValue,
							specialtyId: newPrimarySpecialty?.value,
							isPrimary: true,
						})
					}

					updateData.workerDisciplineSpecialties = updatedSpecialties
				}

				const newItems = differenceBy(mappingNewSpecialty, mappingOldSpecialty, (item) => item?.id).map((item) => ({
					disciplineId: item.key,
					specialtyId: item.id || null,
					isPrimary: item.isPrimary || false,
					id: item.parentId,
				}))

				const removedItems = differenceBy(mappingOldSpecialty, mappingNewSpecialty, (item) => item?.id).map((item) => ({
					id: item.parentId,
					disciplineId: item.key,
					specialtyId: item.id,
					isPrimary: false,
					_destroy: true,
				}))

				const submitItems = [...newItems, ...removedItems]
				const workerDisciplineSpecialties = map(groupBy(submitItems, 'specialtyId'), (specObjects) =>
					reduce(
						specObjects,
						(merged, obj) => ({
							...merged,
							...obj,
						}),
						{}
					)
				)

				if (!!newProfessionsSpecialties) {
					updateData.workerDisciplineSpecialties = [...workerDisciplineSpecialties]
				}
			} else {
				//remove propperrty workerDisciplineSpecialties inside updateData
				delete updateData.workerDisciplineSpecialties
			}

			const response = await mutateWithTrimmedVariables({
				mutation: UPDATE_WORKER_INFO_AND_CONTACTS_MUTATION,
				variables: omit(updateData, ['workerAddress', 'temporaryAddress', 'preferredLocation', 'otherPreferredLocations', 'id']),
			})
			logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.PERSONAL_INFO_SUBMIT_SUCCESS, this.trackingParams)
			void careStore.fetchWorkerBasicInfo()
			void this.fetchDisciplinesSpecialties()
			return response?.data?.updateProfile?.id
		} catch (error) {
			captureException('Care Profile', error)

			logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.PERSONAL_INFO_SUBMIT_FAILED, this.trackingParams)
			const { graphQLErrors } = error
			if (graphQLErrors?.[0]?.message) {
				notifyStore.error(graphQLErrors?.[0]?.message)
			} else {
				notifyStore.error(error?.message)
			}
		}
	}

	@action
	handleSubmitInfoApply = async (values) => {
		try {
			const updateData = { ...values }
			await mutateWithTrimmedVariables({
				mutation: UPDATE_WORKER_INFO_AND_CONTACTS_MUTATION,
				variables: omit(updateData, ['workerAddress', 'temporaryAddress', 'preferredLocation', 'otherPreferredLocations', 'phone', 'id']),
			})
			void careStore.fetchWorkerBasicInfo()
		} catch (error) {
			captureException('Care Profile', error)
			const { graphQLErrors } = error
			if (graphQLErrors?.[0]?.message) {
				notifyStore.error(graphQLErrors?.[0]?.message)
			} else {
				notifyStore.error(error?.message)
			}
		}
	}
	@action
	toggleShowVerifyPhoneModal = (value) => {
		this.showVerifyPhoneModal = value
	}

	@action
	updateWorkerInfo = (payload) => {
		this.worker = { ...this.worker, ...payload }
	}
}

export const infoTabStore = new InfoTabStore()
