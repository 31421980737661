import React, { useEffect, useState } from 'react'
import { Drawer as MuiDrawer, Grid, IconButton, Typography } from '@material-ui/core'
import { DiscardDialog } from '~/components/discard-dialog'
import { useStyles } from './drawer.style'
import { logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'

export const Drawer = ({ children, title, closeIcon, onClose, showDiscard, innerRef, isShowTitle = true, onScroll, eventName, open, ...props }) => {
	const styles = useStyles()
	const [showDiscardAction, setShowDiscardAction] = useState(false)

	useEffect(() => {
		if (eventName && open) {
			logTrackingScreenEvent(eventName, { is_start_screen_session: true })
		}
	}, [eventName, open])

	const backIcon = closeIcon ? (
		showDiscard ? (
			<DiscardDialog onOk={onClose}>
				<IconButton aria-label="back" style={{ padding: 0 }}>
					{closeIcon}
				</IconButton>
			</DiscardDialog>
		) : (
			<IconButton aria-label="back" style={{ padding: 0 }} onClick={onClose}>
				{closeIcon}
			</IconButton>
		)
	) : null

	const backDrop = showDiscard
		? () => (
				<DiscardDialog onOk={onClose}>
					<div style={{ width: '100vw', height: '100vh', background: '#C4C4C4', opacity: 0.7 }} />
				</DiscardDialog>
		  )
		: () => <div style={{ width: '100vw', height: '100vh', background: '#C4C4C4', opacity: 0.7 }} onClick={onClose} />

	return (
		<MuiDrawer open={open} anchor="right" BackdropComponent={backDrop} {...props}>
			{isShowTitle && (
				<Grid container alignItems="center" style={{ padding: '12px 8px' }}>
					<Grid item>{backIcon}</Grid>
					<Grid item>
						<Typography className={styles.title}>{title}</Typography>
					</Grid>
					<Grid item>
						<div style={{ width: '10px' }} />
					</Grid>
				</Grid>
			)}

			<div ref={innerRef} className={styles.box} onScroll={onScroll}>
				{typeof children === 'function' ? children({ setShowDiscard: setShowDiscardAction }) : children}
				{showDiscardAction && <DiscardDialog open={showDiscardAction} onOk={onClose} onCancel={() => setShowDiscardAction(false)} />}
			</div>
		</MuiDrawer>
	)
}
