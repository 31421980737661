import { BaseAdapter } from '@opus/web.core.lib.event-tracking/dist/adapters'
// import { AnonymousTrackingHelper } from '@utils'
import { httpRequestTracking } from '~/common/tracking/http.tracking'
import { createSessionId, getSessionId } from '~/common/helpers/session.helper'

class OpusTrackingAdapter extends BaseAdapter {
	logEvent(params) {
		const { type, payload, uiKey, componentName } = params

		const sessionId = getSessionId()
		const compareSessionId = !!sessionId ? sessionId : createSessionId()
		const mergePayload = {
			...payload,
			ui_key: uiKey,
			component_name: componentName,
			session_id: compareSessionId,
		}

		const mergeData = { ...mergePayload, ...this.basicInfo }
		const cusParams = {
			data: mergeData,
			name: type,
		}
		// if (authStore.isAnonymousLogin) {
		// 	AnonymousTrackingHelper.trackAnonymousClick(type, params)
		// }

		return httpRequestTracking.record(cusParams)
	}

	setUserId(_id) {
		return new Promise(() => {})
	}

	setUserProperties() {
		return new Promise(() => {})
	}
	setBasicInfo(info) {
		this.basicInfo = info
	}
}

export { OpusTrackingAdapter }
