import React from 'react'
import { useSnackbar } from 'notistack'
import { observer, autorun } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores'
import { Button } from '~/components/button'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { NotifyDialog } from './notify-dialog.component'
import { defaultTo } from 'lodash'
import { Typography } from '../typography'
import { SnackbarCustomization } from '../snackbar'

export const GlobalNotify = observer(() => {
	const { t } = useTranslation()
	const { waitDialogs, doneDialogs, waitMessages, doneMessages } = notifyStore

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	autorun(() => {
		waitMessages.forEach((note) => {
			const { key, message, options } = note
			if (doneMessages[key]) {
				return
			}

			const actions = options.persist ? [...options.actions, { label: 'DISMISS' }] : options.actions

			enqueueSnackbar(t(message), {
				key,
				...(options?.customization && {
					content: (key) => <SnackbarCustomization variant={options?.variant} key={key} message={t(message)} />,
				}),
				...options,
				action: () =>
					actions?.map(({ onClick, label, keepSnackbar, ...rest }) => {
						const handleClick = () => {
							if (typeof onClick === 'function') {
								onClick()
							}

							if (!keepSnackbar) {
								closeSnackbar(key)
							}
						}

						return (
							<Button key={key} {...rest} onClick={handleClick}>
								{t(label)}
							</Button>
						)
					}),
			})

			notifyStore.closeMessage(key)
		})
	})

	return (
		<>
			{waitDialogs.map(({ key, message, options }) => (
				<NotifyDialog
					eventName={options?.eventName}
					key={key}
					title={options?.title}
					open={defaultTo(doneDialogs[key], true)}
					showButtonClose={true}
					onClose={() => notifyStore.closeDialog(key)}
					onOk={options?.onOk ? () => options.onOk(key) : () => notifyStore.closeDialog(key)}
					okText={options.okText}
					cancelText={options.cancelText}
					onCancel={options?.onCancel ? () => options.onCancel(key) : undefined}
					containerStyle={options?.containerStyle}
					titleStyle={options?.titleStyle}
				>
					<Typography whiteSpace="pre-line" style={{ textAlign: 'start' }} align="center" variant="body1" color="content.dark">
						{options?.keepOriginal ? message : t(message)}
					</Typography>
				</NotifyDialog>
			))}
		</>
	)
})
