import { Grid, Typography } from '@material-ui/core'
import { FormMode } from '@opus/web.core.form.utils'
import React from 'react'
import { AutoCompleteField, PhoneField, TextField } from '~/components/fields'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import { masterStore } from '~/stores'

const EmergencyContactForm = observer(({ mode = FormMode.Edit, singleColumn, formName, index }) => {
	const { t } = useTranslation()
	const { contactRelationships } = masterStore

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<TextField
					name={`${formName}.${index}.firstName`}
					label={t('FIRST_NAME')}
					placeholder={t('$PLACEHOLDERS.FIRST_NAME')}
					mode={mode}
					endLabel={
						<Typography color="textSecondary" variant="p1">
							{t('OPTIONAL')}
						</Typography>
					}
				/>
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<TextField
					name={`${formName}.${index}.lastName`}
					label={t('LAST_NAME')}
					placeholder={t('$PLACEHOLDERS.LAST_NAME')}
					mode={mode}
					endLabel={
						<Typography color="textSecondary" variant="p1">
							{t('OPTIONAL')}
						</Typography>
					}
				/>
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<PhoneField
					name={`${formName}.${index}.phone`}
					mode={mode}
					label={t('PHONE_NUMBER')}
					placeholder="$PLACEHOLDERS.PHONE"
					endLabel={
						<Typography color="textSecondary" variant="p1">
							{t('OPTIONAL')}
						</Typography>
					}
				/>
			</Grid>

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<AutoCompleteField
					mode={mode}
					name={`${formName}.${index}.relationship`}
					options={contactRelationships}
					label={t('CONTACT_RELATIONSHIP')}
					placeholder={t('$PLACEHOLDERS.CONTACT_RELATIONSHIP')}
					endLabel={
						<Typography color="textSecondary" variant="p1">
							{t('OPTIONAL')}
						</Typography>
					}
					allowCustomValue
				/>
			</Grid>
		</Grid>
	)
})

export default EmergencyContactForm
