import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { loadingState, observer } from '~/common/mobx.decorator'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { checklistsStore } from '~/features/care-profile/tabs/checklists/checklists.store'
import { useEffectOnce } from 'react-use'
import ChecklistItem from '~/features/care-profile/components/checklist-item'
import { EmptyStateSvg } from '~/components/icons'
import { Button } from '~/components/button'
import HintBox from '~/features/care-activation/components/hint-box'
import { infoTabStore } from '~/features/care-activation/tabs/info/info-tab.store'
import { useWindowScrollTop } from '~/common/hooks'
import { logClickEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { SKILL_CHECKLIST_TRACKING_EVENT } from '~/common/constants'

export const ChecklistsTab = observer(({ hintText, onNextTab, onPreviousTab }) => {
	const { t } = useTranslation()
	const { checklists, fetchChecklists, resetFetchedData, hasNextPage, getUnaLink, unaLink } = checklistsStore
	const { primarySpecialty, fetchDisciplinesSpecialties } = infoTabStore

	const loading = loadingState('checklistsStore/fetchChecklists')

	const handleNextTab = () => {
		logClickEvent(SKILL_CHECKLIST_TRACKING_EVENT.CLICK_NEXT_STEP_BTN, {})
		onNextTab()
	}

	useWindowScrollTop()

	useEffectOnce(() => {
		getUnaLink()
		logTrackingScreenEvent(SKILL_CHECKLIST_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
		resetFetchedData()
		void Promise.all([fetchChecklists(), fetchDisciplinesSpecialties()])
	})

	return (
		<Box p={2}>
			<HintBox
				text={hintText}
				onSkipTab={() => {
					logClickEvent(SKILL_CHECKLIST_TRACKING_EVENT.CLICK_SKIP_STEP_5, {})
					onNextTab()
				}}
			/>

			<Box mt={2}>
				{checklists.length > 0 && (
					<Typography variant="subtilte2" color="textSecondary">
						{t('$MESSAGES.SKILLS_CHECKLIST_HINT')}
					</Typography>
				)}
			</Box>

			<Box mt={3} display="flex" flexDirection="column" gridGap={8}>
				{!loading &&
					checklists.length > 0 &&
					checklists.map((checklist) => (
						<ChecklistItem
							id={checklist.id}
							name={checklist.name}
							resultFile={checklist.resultFile}
							completed={checklist.completed}
							url={unaLink}
							onTrackingEvent={() => {
								logClickEvent(SKILL_CHECKLIST_TRACKING_EVENT.CLICK_OPEN_UNA_LINK_BTN, {})
							}}
						/>
					))}

				{!loading && checklists.length === 0 && (
					<Box display="flex" alignItems="center" flexDirection="column" gridGap={8}>
						<EmptyStateSvg />
						{primarySpecialty.hasSkillChecklist ? (
							<>
								<Typography variant="h4" color="textSecondary">
									{t('$MESSAGES.WAIT_CHECKLIST_1')}
								</Typography>
								<Typography variant="h4" color="textSecondary">
									{t('$MESSAGES.WAIT_CHECKLIST_2')}
								</Typography>
							</>
						) : (
							<Typography variant="h4" color="textSecondary">
								{t('$MESSAGES.EMPTY_CHECKLIST')}
							</Typography>
						)}
					</Box>
				)}

				{hasNextPage && (
					<Box pt={2} pb={2} display="flex" justifyContent="center">
						<Button fullWidth={false} loading={loading} color="primary" variant="outlined" onClick={() => fetchChecklists()}>
							{t('VIEW_MORE')}
						</Button>
					</Box>
				)}
			</Box>

			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							onClick={() => {
								logTrackingScreenEvent(SKILL_CHECKLIST_TRACKING_EVENT.CLICK_PREVIOUS_BTN, { is_start_screen_session: true })
								onPreviousTab()
							}}
						>
							{t('PREVIOUS_STEP')}
						</Button>
					</Grid>
					<Grid item xs={12} md={6}>
						<Button
							// disabled={!isFormValid}
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => handleNextTab()}
						>
							{t('SAVE_AND_NEXT_STEP')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
})
