import { gql } from '@apollo/client'
import {
	EMERGENCY_CONTACT_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORK_EXPERIENCE_OVERVIEW_FRAMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_FRAGMENT,
	WORKER_REFERENCE_FRAGMENT,
} from '../apollo.fragment'

export const VERIFY_WORKER_QUERY = gql`
	query verifyWorker($token: String!) {
		workerToken(token: $token) {
			token
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
				company {
					address
					id
					country
					phone
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_WORKER_DETAIL_QUERY = gql`
	query getWorkerDetailQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workExperienceOverview {
				...workExperienceOverview
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			workerAddress {
				...workerAddressFragment
			}
			supportDocuments {
				id
				documentType
				document {
					id
					contentType
					fileUrl
					filename
					thumbnails
					blobId
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
			workerReferences {
				...workerReferenceFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingState
			}
			emergencyContact {
				...emergencyContactFragment
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
`

export const GET_REFERENCE_CHECK_URL_QUERY = gql`
	query getReferenceCheckUrlQuery {
		referenceCheckUrl
	}
`

export const GET_PROFILE_BASIC_INFO_QUERY = gql`
	query getProfileQuery {
		worker {
			id
			firstName
			lastName
			email
			emailVerifiedAt
			phone
			phoneVerifiedAt
			preferredShifts
			preferredWorkTypes
			hasAssignedRecruiterOrManager
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
					hasSkillChecklist
				}
				specialty {
					name
					id
					hasSkillChecklist
				}
				isPrimary
			}
			workingPreferredLocations {
				id
				city
				state
				isPrimary
			}
			addresses {
				id
				aptNumber
				city
				country
				default
				street
				state
				zipcode
			}
			resumes {
				documentType
				id
				file {
					contentType
					filename
					fileUrl
					blobId
					id
				}
			}
		}
	}
`

export const GET_WORKER_RESUMES_QUERY = gql`
	query getProfileQuery {
		worker {
			id
			resumes {
				documentType
				id
				file {
					contentType
					filename
					fileUrl
					blobId
					id
				}
			}
		}
	}
`

export const GET_PROFILE_PHONE_VERIFIED_QUERY = gql`
	query getProfileQuery {
		worker {
			phoneVerifiedAt
			phone
		}
	}
`

export const GET_EMERGENCY_CONTACTS_QUERY = gql`
	query getProfileQuery {
		worker {
			workerEmergencyContacts {
				id
				firstName
				lastName
				phone
				relationship
			}
		}
	}
`

export const GET_DISCIPLINES_SPECIALTIES_QUERY = gql`
	query getProfileQuery {
		worker {
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
					hasSkillChecklist
				}
				specialty {
					name
					id
					hasSkillChecklist
				}
				isPrimary
			}
		}
	}
`

export const GET_WORKER_BASIC_INFO_QUERY = gql`
	query getWorkerBasicInfoQuery {
		worker {
			customField1
			customField2
			customField3
			customField4
			verifiedPreferredLocations
			email
			emailVerifiedAt
			firstName
			id
			lastName
			phone
			isActiveStatus
			phoneVerifiedAt
			status
			workAuthorizedAt
			workerBadges {
				id
				badgeType
			}
			preferredShifts
			preferredWorkTypes
			workingPreferredLocations {
				id
				city
				state
				isPrimary
			}
			sclProvider {
				provider
				providerId
			}
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
				}
				specialty {
					name
					id
				}
				isPrimary
			}
		}
	}
`

export const GET_WORKER_ONBOARDING_INFO = gql`
	query getWorkerOnboardingInfoQuery {
		workerOnboarding {
			id
			state
			completedPercent
			onboardingSteps {
				id
				state
				stepName
				completedPercent
				stepInfo {
					fieldsDefinition
					id
					description
					stepOrder
					optional
				}
			}
		}
	}
`

export const GET_WORKER_EARNED_BADGES_QUERY = gql`
	query workerBadgesQuery($after: String, $before: String, $first: Int, $last: Int) {
		workerBadges(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				badge {
					id
					active
					badgeName
					badgeType
					description
					icon {
						id
						file {
							id
							thumbnails
						}
					}
				}
				badgeType
				completedPercent
				status
			}
		}
	}
`

export const GET_WORKER_EARNED_BADGES_ICON_QUERY = gql`
	query workerBadgesQuery($after: String, $before: String, $first: Int, $last: Int) {
		workerBadges(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				badge {
					id
					badgeName
					icon {
						id
						file {
							id
							thumbnails
						}
					}
				}
			}
		}
	}
`

export const GET_WORKER_ALL_BADGES_QUERY = gql`
	query allBadgesQuery($after: String, $before: String, $first: Int, $last: Int) {
		badges(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				active
				badgeName
				badgeType
				description
				icon {
					id
					file {
						id
						thumbnails
					}
				}
			}
		}
	}
`

export const GET_WORKER_SKILL_CHECKLISTS_QUERY = gql`
	query workerSkillChecklistsQuery($after: String, $before: String, $first: Int, $last: Int) {
		workerSkillChecklists(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				assignmentUrl
				completed
				name
				resultFile {
					file {
						...imageFileFragment
					}
					id
					note
				}
				expired
			}
		}
	}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			workAuthorized
			phone
			phoneVerifiedAt
		}
	}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery($filter: WorkerCredentialStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`

export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialAuthenticationsQuery($after: String, $before: String, $first: Int, $last: Int) {
		workerSocialAuthentications(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				email
				id
				name
				providerName
				providerUuid
			}
		}
	}
`

export const GET_WORKER_PARTIAL_SETTINGS_QUERY = gql`
	query getWorkerPartialSettings($workerId: String!) {
		appInit(workerId: $workerId) {
			worker {
				id
				email
			}
		}
	}
`

export const GET_SIGNED_URLS = gql`
	query getSignedUrls($blobIds: [String!]!) {
		signedUrls(blobIds: $blobIds) {
			id
			fileUrl
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`

export const GET_REMAINING_OTP_REQUESTS_QUERY = gql`
	query checkRemainingOtpRequestsQuery {
		checkRemainingOtpRequests {
			remainingSessionResendCode
			remainingTotalResendCode
		}
	}
`

export const GET_REMAINING_EMAIL_OTP_REQUESTS_QUERY = gql`
	query checkRemainingEmailVerificationRequestsQuery($email: String!) {
		checkRemainingEmailVerificationRequests(email: $email) {
			maxAttempts
			remainingEmailSessionResendCode
			windowMinutes
		}
	}
`

export const GET_RECRUITER_INFO = gql`
	query getRecruiterInfo {
		worker {
			recruiter {
				companyId
				email
				id
				name
				phone
				title
			}
		}
	}
`

export const GET_MASTER_DATA = gql`
	query getMasterData {
		appInit {
			masterData
		}
	}
`

export const GET_AUTHORIZED_WORKER = gql`
	query getAuthorizedWorker {
		worker {
			workAuthorized
			phone
			lastName
			firstName
			id
			phoneVerifiedAt
		}
	}
`
export const GET_WORKER_AVATAR = gql`
	query getProfileAvatarQuery {
		worker {
			avatar {
				file {
					thumbnails
				}
			}
		}
	}
`
export const WORKER_ONBOARDING_QUERY = gql`
	query workerOnboardingQuery {
		workerOnboarding {
			id
			termsOfService {
				id
				content
				description
				acceptTerms
			}
		}
	}
`
export const ACCEPTED_TERMS_OF_SERVICE_QUERY = gql`
	query acceptedOnboardingTermsOfServiceQuery {
		acceptedOnboardingTermsOfService {
			id
			createdAt
			termsOfService {
				id
				description
				content
				acceptTerms
			}
		}
	}
`

export const DOWNLOAD_RESULT_FILE = gql`
	query workerSkillChecklistQuery($id: String!) {
		workerSkillChecklist(id: $id) {
			resultFile {
				id
				file {
					fileUrl
				}
			}
		}
	}
`
