import { gql } from '@apollo/client'

export const PROCESS_INTERVIEW_MUTATION = gql`
	mutation workerProcessInterviewMutation(
		$workerProcessInterviewId: String!
		$event: String!
		$note: String
		$interviewStartTime: ISO8601DateTime
		$interviewEndTime: ISO8601DateTime
		$timezone: String
	) {
		workerProcessInterview(
			id: $workerProcessInterviewId
			event: $event
			note: $note
			interviewStartTime: $interviewStartTime
			timezone: $timezone
			interviewEndTime: $interviewEndTime
		) {
			id
			status
			statusName
		}
	}
`
export const PROCESS_RESCHEDULE_INTERVIEW_MUTATION = gql`
	mutation WorkerRescheduleInterviewMution(
		$workerRescheduleInterviewId: String!
		$interviewStartTime: ISO8601DateTime
		$interviewEndTime: ISO8601DateTime
		$timezone: String
	) {
		workerRescheduleInterview(
			id: $workerRescheduleInterviewId
			interviewStartTime: $interviewStartTime
			interviewEndTime: $interviewEndTime
			timezone: $timezone
		) {
			id
			status
			statusName
		}
	}
`

export const ACCEPT_AUTO_SUBMISSION_MUTATION = gql`
	mutation acceptAutoSubmission($token: String!, $jobIds: [String!]!, $confirmedSource: AutoSubmissionConfirmedSourceEnum) {
		acceptAutoSubmission(token: $token, jobIds: $jobIds, confirmedSource: $confirmedSource) {
			autoSubmissionToken {
				token
				status
			}
		}
	}
`

export const DECLINE_AUTO_SUBMISSION_MUTATION = gql`
	mutation declineAutoSubmission($token: String!, $jobIds: [String!]!, $confirmedSource: AutoSubmissionConfirmedSourceEnum) {
		declineAutoSubmission(token: $token, jobIds: $jobIds, confirmedSource: $confirmedSource) {
			autoSubmissionToken {
				token
				status
			}
		}
	}
`

export const FEEDBACK_AUTO_SUBMISSION_MUTATION = gql`
	mutation feedbackAutoSubmission($token: String!, $jobIds: [String!]!, $feedbackContent: AutoSubmissionFeedbackContentInput!) {
		feedbackAutoSubmission(token: $token, jobIds: $jobIds, feedbackContent: $feedbackContent) {
			success
		}
	}
`
