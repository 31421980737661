import { gql } from '@apollo/client'

export const EMPLOYER_FRAGMENT = gql`
	fragment employerFragment on Employer {
		id
		email
		firstName
		lastName
	}
`

export const EMERGENCY_CONTACT_FRAGMENT = gql`
	fragment emergencyContactFragment on EmergencyContact {
		id
		contactName
		contactPhone
		contactRelationship
	}
`

export const WORKER_ADDRESS_FRAGMENT = gql`
	fragment workerAddressFragment on WorkerAddress {
		id
		zipcode
		street
		city
		state
		country
		aptNumber
	}
`

export const WORKER_FRAGMENT = gql`
	fragment workerFragment on Worker {
		avatar {
			fileUrl
			thumbnails
		}
		noSms
		id
		email
		firstName
		lastName
		phone
		noJobMatches
		phoneVerifiedAt
		referralCode
		workAuthorized
		availableStartDate
		facilityTypes
		timeOffRequested
		remainingExpirationTime
		changePasswordRequired
		workerSpecialties {
			category
			completedChecklist
			id
			status
			specialtyLongName
			isPrimary
		}
		resumes {
			id
			filename
			fileUrl
			thumbnails
			contentType
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
		company {
			companyConfig {
				adpUrl
				companyId
				oneSignalAppId
			}
		}
	}
`

export const LOCATION_FRAGMENT = gql`
	fragment locationFragment on Location {
		city
		country
		id
		latitude
		longitude
		name
		state
		street
		zipcode
	}
`

export const JOB_GENERAL_FRAGMENT = gql`
	fragment JobGeneralFragment on JobGeneral {
		jobGeneralSpecialties {
			disciplineId

			id
			perDiemPayRate
			perDiemPayRateType
			specialtyId
		}
		address {
			id
			country
			state
			city
			latitude
			zipcode
			longitude
		}
		externalId
		facility {
			id
			name
		}
		hotJob
		id
		isPerDiem
		payCurrency
		settings
		title
		workTypes
		specialties {
			discipline {
				id
				disciplineName
				disciplineType
				externalId
			}
			externalId
			id
			jobsCount
			longName
			name
		}
	}
`

export const JOB_DETAIL_GENERAL_FRAGMENT = gql`
	fragment jobDetailGeneralFragment on JobGeneral {
		disciplines {
			disciplineName
			id
			disciplineType
		}
		jobGeneralSpecialties {
			id
			specialtyId
			disciplineId
			perDiemPayRate
			perDiemPayRateType
		}
		address {
			id
			country
			state
			city
			latitude
			zipcode
			longitude
			locationImage {
				webImage {
					fileUrl
				}
			}
		}
		benefitsText
		certificationsRequired
		customFields
		description
		externalId
		facility {
			id
			name
		}
		hotJob
		id
		isPerDiem
		licenseStateCompact
		licenseStateRequired
		payCurrency
		requirementsText
		settings
		title
		workTypes
		specialties {
			discipline {
				id
				disciplineName
				disciplineType
				externalId
			}
			externalId
			id
			jobsCount
			longName
			name
		}
	}
`

export const WORKER_ASSIGMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		acceptedAt
		actualEndTime
		actualStartTime
		availableToExtend
		customFields
		externalId
		id
		isCancelled
		isExtend
		note
		permittedActions
		reasonCancelled
		scheduledEndTime
		scheduledStartTime
		shiftTypes
		status
		payType
		payRate
		salary
	}
`

export const JOB_DETAIL_FRAGMENT = gql`
	fragment jobDetailFragment on Job {
		benefitsText
		billRate
		customFields
		externalFriendlyId
		isPerDiemLongTerm
		isPublish
		duration
		durationType
		endDatetime
		externalId
		hoursPerDay
		hoursPerWeek
		salary
		timezone
		id
		isFavorited
		atsSource
		metadata
		jobGeneral {
			...jobDetailGeneralFragment
		}
		matchingPercentage
		payAmountMax
		payAmountMin
		publishedAt
		payRate
		payType
		requirementsText
		shifts
		slotsNumber
		startDatetime
		viewedCount
		status
		jobApplicant {
			status
		}
		workerAssignment {
			payRate
			payType
			id
			status
			payRate
			payType
			salary
		}
	}
	${JOB_DETAIL_GENERAL_FRAGMENT}
`

export const JOB_BASED_FRAGMENT = gql`
	fragment jobBasedFragment on JobBase {
		duration
		durationType
		isPerDiemLongTerm
		isPublish
		externalFriendlyId
		endDatetime
		externalId
		timezone
		id
		isFavorited
		atsSource
		metadata
		publishedAt
		jobGeneral {
			...JobGeneralFragment
		}
		matchingPercentage
		payAmountMax
		payAmountMin
		payRate
		payType
		shifts
		slotsNumber
		startDatetime
		viewedCount
		status
		jobApplicant {
			status
		}
		workerAssignment {
			payRate
			payType
			id
			status
		}
	}
	${JOB_GENERAL_FRAGMENT}
`

export const JOB_FRAGMENT = gql`
	fragment jobFragment on Job {
		duration
		durationType
		externalFriendlyId
		endDatetime
		isPerDiemLongTerm
		isPublish
		externalId
		timezone
		id
		isFavorited
		publishedAt
		atsSource
		metadata
		jobGeneral {
			...JobGeneralFragment
		}
		matchingPercentage
		payAmountMax
		payAmountMin
		payRate
		payType
		shifts
		slotsNumber
		startDatetime
		viewedCount
		status
		jobApplicant {
			status
		}
		workerAssignment {
			id
			status
			timezone
			scheduledStartTime
			timezone
			payType
			payRate
			salary
		}
	}
	${JOB_GENERAL_FRAGMENT}
`

export const COMPANY_FRAGMENT = gql`
	fragment companyFragment on Company {
		address
		addressState
		city
		country
		description
		id
		mainContactEmail
		mainContactName
		name
		phone
		zip
	}
`

export const JOB_ASSIGMENT_FRAGMENT = gql`
	fragment jobAssigmentFragment on JobApplicant {
		id
		job {
			...jobFragment
		}
		workerAssignments {
			...workerAssignmentFragment
		}
	}
	${JOB_FRAGMENT}
	${WORKER_ASSIGMENT_FRAGMENT}
`

export const WORKER_CREDENTIAL_FRAGMENT = gql`
	fragment workerCredentialFragment on WorkerCredential {
		company {
			...companyFragment
		}
		createdAt
		expirationDate
		id
		name
		status
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${COMPANY_FRAGMENT}
`

export const WORKER_CREDENTIAL_DETAIL_FRAGMENT = gql`
	fragment workerCredentialFragment on WorkerCredential {
		id
		esignUrl
		requiredFields
		isLicense
		requiredFields
		requiredVisibleFields
		completeType
		credential {
			enableCompactOrMultiState
			completeProfileSkillChecklistUrl
			id
			category
			atsSource
			default
			name
			credentialCompleteUrl
			isLicense
			fileSample {
				id
				file {
					fileUrl
				}
			}
		}
		files {
			id
			file {
				id
				fileUrl
				filename
				contentType
			}
		}
		compactState
		credentialName
		effectiveDate
		expirationDate
		number
		status
		state
	}
`

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
	fragment workingAuthorizationFragment on WorkingAuthorization {
		id
		dateOfBirth
		socialSecurityNumber
	}
`

export const WORK_EXPERIENCE_FRAGMENT = gql`
	fragment workExperienceFragment on WorkExperience {
		agency
		chartingSoftwareUsed
		city
		country
		reasonForLeaving
		workedThroughStaffingFirm
		gapInEmployment
		gapReason
		gapStartDate
		gapEndDate
		reasonForLeavingNote
		title
		currentlyEmployed
		endDate
		externalId
		facilityName
		id
		notes
		reasonForLeaving
		startDate
		state
		title
		travelAssignment
		unitType
	}
`

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment salesforceIntegrationFragment on SalesforceIntegration {
		id
		companyId
		businessDay
		weekending
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
		haveDraft
	}
`

export const ONBOARDING_PART_FRAGMENT = gql`
	fragment onboardingPartFragment on OnboardingPart {
		id
		name
		description
	}
`
export const DIRECT_UPLOAD_FRAGMENT = gql`
	fragment directUploadFragment on DirectUpload {
		blobId
		headers
		preSignedUrl
		signedBlobId
		url
	}
`

export const WORKER_CERTIFICATION_FRAGMENT = gql`
	fragment workerCertificationFragment on WorkerCertification {
		id
		title
		licenseDiscipline
		licenseSpecialty
		licenseState
		licenseNumber
		compactLicense
		expirationDate
		certificationType
		createdAt
		licenseImages {
			fileUrl
			id
			contentType
			filename
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`

export const WORKER_REFERENCE_FRAGMENT = gql`
	fragment workerReferenceFragment on WorkerReference {
		city
		country
		email
		firstName
		id
		jobTitle
		lastName
		phone
		relationship
		state
	}
`
export const IMAGE_FILE_FRAGMENT = gql`
	fragment imageFileFragment on File {
		id
		fileUrl
		contentType
	}
`

export const FILE_FRAGMENT = gql`
	fragment fileFragment on File {
		id
		filename
		fileUrl
		contentType
		blobId
		imageUrls(size: ["100x100", "200x200", "500x500"])
	}
`

export const WORKER_EDUCATION_FRAGMENT = gql`
	fragment workerEducationFragment on WorkerEducation {
		id
		schoolName
		major
		state
		city
		country
		degreeName
		graduationDate
	}
`

export const LICENSE_IMAGES_FRAGMENT = gql`
	fragment licenseImagesFragment on File {
		id
		fileUrl
		contentType
	}
`

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
	fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
		id
		workerCertifications {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
	fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
		id
		workerReferences {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
	fragment workerEducationOverviewFragment on WorkerEducationOverview {
		id
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
	fragment workExperienceOverview on WorkExperienceOverview {
		id
		discipline
		primarySpecialty
		secondarySpecialty
		yearsOfExperience
		workExperiences {
			...workExperienceFragment
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on ProfileOnboarding {
			worker {
				socialSecurityNumber
				dateOfBirth
				workerAddress {
					aptNumber
					city
					country
					id
					state
					street
					workerId
					zipcode
				}

				id
				email
				availableStartDate
				emergencyContact {
					contactName
					contactPhone
					contactRelationship
					id
				}
				shiftRequested
				facilityTypes
				firstName
				lastName
				workingPreferredLocations {
					id
					preferredWorkingState
				}
				workerSpecialties {
					category
					completedChecklist
					id
					status
					specialtyLongName
				}
				phone
			}
		}
		... on UserTermsOfService {
			agreedAt
			termsOfService {
				id
				url
			}
		}
		... on WorkExperienceOverview {
			id
			workExperiences {
				city
				country
				currentlyEmployed
				endDate
				facilityName
				id
				nurseRatio
				startDate
				state
				supervisor
				travelAssignment
				zipcode
			}
			yearsOfExperience
		}
		... on WorkerEducationOverview {
			id
			workerEducations {
				city
				country
				createdAt
				degreeDate
				degreeName
				id
				major
				schoolName
				state
			}
		}
		... on WorkerReferenceOnboarding {
			id
			workerReferences {
				city
				contactFirstName
				contactFullName
				contactLastName
				contactEmail
				contactPhone
				createdAt
				facilityName
				id
				relationship
				salutation
				state
				workedFrom
				workedTo
				referenceType
				jobTitle
			}
		}
		... on WorkerCertificationOverview {
			id
			workerCertifications {
				certificationType
				compactLicense
				createdAt
				expirationDate
				id
				licenseDiscipline
				licenseSpecialty
				licenseNumber
				licenseState
				title
				licenseImages {
					blobId
					contentType
					fileUrl
					filename
					id
				}
			}
		}
	}
`

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		onboardingPart {
			id
			name
		}
		integration {
			...workerOnboardingPartIntegrationFragment
		}
		workerOnboarding {
			numOfCompletedSteps
		}
	}

	${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const REFERRED_TRAVELER_FRAGMENT = gql`
	fragment referredTravelerFragment on ReferredTraveler {
		id
		firstName
		lastName
		email
		phone
		relationship
		discipline
	}
`

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		id
		startDate
		availableToExtend
		endDate
		status
		client {
			id
			name
		}
		job {
			...jobFragment
		}
		jobApplicant {
			applyDate
			createdAt
			id
			note
			source
			status
			submittal {
				externalUpdatedAt
				id
				jobId
				status
				submittalDate
			}
			updatedAt
			applicationProcess
		}
	}
	${JOB_FRAGMENT}
`
