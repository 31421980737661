import React from 'react'
import { FC, ReactNode } from 'react'
import { createContext } from 'react'
import { IAuthService } from '../interfaces'

export const AuthContext = createContext<Partial<IAuthService>>({})

export interface IAuthProviderProps {
	authService: IAuthService
	children?: ReactNode
}

export const AuthProvider: FC<IAuthProviderProps> = ({ authService, children }) => {
	return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
}
