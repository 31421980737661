import { css } from 'styled-components'

export const textFieldStyle = css`
	width: 100%;

	input[type='time'] {
		position: relative;
		cursor: pointer;
	}

	input[type='time']::-webkit-calendar-picker-indicator {
		display: block;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		background: transparent;
	}

	.MuiInputBase-root {
		overflow: hidden;
	}

	.MuiInputBase-input {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
	}

	.MuiInputBase-multiline {
		padding: 8px 14px;
	}
	&:hover {
		.clear-button {
			opacity: 1;
			transition: opacity 0.2s;
		}
	}
	.clear-button {
		opacity: 0;
		transition: opacity 0.2s;
	}

	.MuiInputBase-input[readonly] {
		background: #f8f8f8a1;
	}
`

export const labelStyle = css`
	margin-bottom: 4px;
`

export const viewLabelStyle = css`
	.MuiFormLabel-root {
		color: ${({ theme }) => theme.colors.primary.main};
	}

	.MuiInputBase-root > .MuiBox-root {
		word-break: break-word;
	}

	&.full-width {
		.MuiInputBase-root > .MuiBox-root {
			word-break: break-word;
			width: 100%;
		}
	}
`
