import { Box, ButtonBase, SvgIcon, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { FIELD_MODE } from '~/common/constants'
import { PlusCircleBlueSvg } from '~/components/icons'
import { Button } from '~/components/button'
import React from 'react'
import { useStyles } from '~/features/care-profile/tabs/info/info-tab.style'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { blueLabel, redLabel } from '~/components/section-box/section-box.style'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { ACTIATION_TRACKING_CONSTANT } from '~/features/care-activation/care-activation.constant'
import { chain } from 'lodash'

const ProfessionBox = ({
	profession,
	primarySpecialty,
	mode,
	isPrimary = false,
	groupedSpecialties,
	onAddSpecialties,
	onRemoveSpecialty,
	onRemoveProfession,
	onSetPrimarySpecialty,
	onSetPrimaryProfession,
	disciplinesData,
	notSetPrimary,
	hasAssignedRecruiterOrManager,
	primaryProfession,
	primarySpecialtyInfo,
}) => {
	const classes = useStyles()

	const { t } = useTranslation()
	const disciplineDataFilter = chain(disciplinesData)
		.find({ value: profession })
		.get('specialties', [])
		.filter((item) => item.name !== 'Undefined')
		.size()
		.value()

	const handleRemove = (profession, specialty) => {
		if (specialty) {
			onRemoveSpecialty(profession, specialty?.id)
		} else {
			onRemoveProfession(profession)
		}
	}

	const handleSetPrimary = (profession, specialty) => {
		if (specialty) {
			onSetPrimarySpecialty(profession, specialty)
		} else {
			onSetPrimaryProfession(profession)
		}
	}

	const shouldDisplayRemoveButton =
		!hasAssignedRecruiterOrManager &&
		((groupedSpecialties?.length > 1 && profession !== primaryProfession) || (groupedSpecialties?.length >= 1 && profession === primaryProfession))

	return (
		<Box className={classes.professionBox} display="flex" flexDirection="column" gridRowGap={12} mb={2}>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h4">{disciplinesData?.find((discipline) => discipline.value === profession)?.label}</Typography>
				{isPrimary ? (
					<Chip size="small" label="Primary" color="primary" className={classes.primaryChip} />
				) : (
					mode === FIELD_MODE.edit &&
					!hasAssignedRecruiterOrManager && (
						<Box display="flex" alignItems="center" gridGap={8}>
							<ButtonBase
								disableRipple
								onClick={() => {
									logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_SET_PRIMARY_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION)
									handleSetPrimary(profession)
								}}
							>
								<Typography variant="subtitle2" css={blueLabel}>
									{t('SET_AS_PRIMARY')}
								</Typography>
							</ButtonBase>

							<ButtonBase
								disableRipple
								onClick={() => {
									logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_REMOVE_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION)
									handleRemove(profession)
								}}
							>
								<Typography variant="subtitle2" css={redLabel}>
									{t('REMOVE')}
								</Typography>
							</ButtonBase>
						</Box>
					)
				)}
			</Box>

			{disciplineDataFilter > 0 ? (
				<Typography variant="body1" color="textSecondary">
					Specialties:
				</Typography>
			) : (
				<Typography variant="body1" color="textSecondary">
					This profession has no specialties
				</Typography>
			)}
			<Box display="flex" flexDirection="column" gridRowGap={8}>
				{isPrimary && primarySpecialty && (
					<Box display="flex" justifyContent="space-between" alignItems="center" className={classes.specialtyItem}>
						<Typography style={{ fontWeight: 800 }} variant="body1">
							{primarySpecialty}
						</Typography>
						<Chip size="small" label="Primary" color="primary" className={classes.primaryChip} />
					</Box>
				)}

				{groupedSpecialties?.length > 0 &&
					groupedSpecialties.map(
						(specialty) =>
							specialty.name && (
								<Box key={specialty.id} display="flex" justifyContent="space-between" alignItems="center" className={classes.specialtyItem}>
									<Typography style={{ fontWeight: 800 }} variant="body1">
										{specialty.name}
									</Typography>
									{mode === FIELD_MODE.edit && (
										<Box display="flex" alignItems="center" gridGap={8}>
											{isPrimary && !notSetPrimary && !hasAssignedRecruiterOrManager && (
												<ButtonBase
													disableRipple
													onClick={() => {
														logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_SET_PRIMARY_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION)
														handleSetPrimary(profession, specialty)
													}}
												>
													<Typography variant="subtitle2" css={blueLabel}>
														{t('SET_AS_PRIMARY')}
													</Typography>
												</ButtonBase>
											)}
											{shouldDisplayRemoveButton && (
												<ButtonBase
													disableRipple
													onClick={() => {
														logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_REMOVE_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION)
														handleRemove(profession, specialty)
													}}
												>
													<Typography variant="subtitle2" css={redLabel}>
														{t('REMOVE')}
													</Typography>
												</ButtonBase>
											)}
										</Box>
									)}
								</Box>
							)
					)}
			</Box>

			{mode === FIELD_MODE.edit && !hasAssignedRecruiterOrManager && disciplineDataFilter > 0 && (
				<Button variant="text" color="primary" className={classes.addSpecialtyBtn} onClick={onAddSpecialties}>
					<SvgIcon style={{ marginRight: '8px' }} viewBox="0 0 20 20" component={PlusCircleBlueSvg} />
					{t('ADD_SPECIALTIES')}
				</Button>
			)}
		</Box>
	)
}

export default ProfessionBox
