export const CERTIFICATIONS = {
	license: 'license',
	certification: 'certification',
}

export const PROFILE_STEP = {
	basic_info: 'personal_information',
	education: 'education_history',
	work_experience: 'work_experience',
	reference: 'references',
	checklist: 'skills_checklist',
	credential: 'credential',
	term_sign: 'term_signed',
}

export const ADDITIONAL_APPLY = {
	resume: 'resume',
	reference: 'references',
	checklist: 'skills_checklist',
}

export const SORT_TYPE = {
	asc: 'asc',
	desc: 'desc',
}

export const LIMIT_PAGE = 24
export const EDUCATION_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-completion-educations',
	PROFILE_COMPLETION_EDUCATIONS_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-EDUCATIONS_SUBMIT_SUCCESS',
	PROFILE_COMPLETION_EDUCATIONS_SUBMIT_FAILED: 'PROFILE-COMPLETION-EDUCATIONS_SUBMIT_FAILED',
	CLICK_SKIP_STEP_2: 'skip-step-2_link',
	CLICK_REMOVE_EDUCATION_BTN: 'remove-education_btn',
	CLICK_ADD_EDUCATION_BTN: 'add-education_btn',
	CLICK_PREVIOUS_STEP_BTN: 'previous-step_btn',
	CLICK_NEXT_STEP_BTN: 'next-step_btn',
}

export const EXPERIENCE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-completion-work-experiences',
	PROFILE_COMPLETION_EXPERIENCES_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	PROFILE_COMPLETION_EXPERIENCES_SUBMIT_FAILED: 'PROFILE-COMPLETION-WORK-EXPERIENCES_SUBMIT_FAILED',
	CLICK_SKIP_STEP_3: 'skip-step-3_link',
	CLICK_REMOVE_RESUME_BTN: 'remove-resume_btn',
	CLICK_PREVIEW_RESUME_BTN: 'preview-resume_btn',
	CLICK_UPLOAD_RESUME_BTN: 'upload-resume_btn',
	CLICK_REMOVE_WORK_BTN: 'remove-work-experience_btn',
	CLICK_ADD_WORK_BTN: 'add-work-experience_btn',
	CLICK_NEXT_STEP_BTN: 'next-step_btn',
	CLICK_PREVIOUS_BTN: 'previous-step_btn',
}

export const REFERENCE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-completion-references',
	PROFILE_COMPLETION_REFERENCES_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-REFERENCES_SUBMIT_SUCCESS',
	PROFILE_COMPLETION_REFERENCES_SUBMIT_FAILED: 'PROFILE-COMPLETION-REFERENCES_SUBMIT_FAILED',
	CLICK_SKIP_STEP_4: 'skip-step-4_link',
	CLICK_REMOVE_REFERENCE_BTN: 'remove-reference_btn',
	CLICK_ADD_REFERENCE_BTN: 'add-reference_btn',
	CLICK_PREVIOUS_BTN: 'previous-step_btn',
	CLICK_NEXT_STEP_BTN: 'next-step_btn',
}

export const SKILL_CHECKLIST_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-completion-skill-check-lists',
	SCREEN_POPUP_TRACKING: 'confirm-open-una-link',
	CLICK_SKIP_STEP_5: 'skip-step-5_link',
	CLICK_OPEN_UNA_LINK_BTN: 'open-una-link_btn',
	CLICK_PREVIOUS_BTN: 'previous-step_btn',
	CLICK_NEXT_STEP_BTN: 'next-step_btn',
	CLICK_CANCEL_BTN: 'cancel_btn',
	CLICK_CONFIRM_BTN: 'confirm_btn',
}

export const TERM_SIGNED_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-completion-term-signed',
	CLICK_CHECKBOX_PROFILE: 'checkbox_profile',
	CLICK_PREVIOUS_BTN: 'previous-step_btn',
	CLICK_NEXT_STEP_BTN: 'complete_btn',
}
