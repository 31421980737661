import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'

export const useFieldError = (meta, nestedField, errorDisplayFormat) => {
	const { t } = useTranslation()
	const { submitCount } = useFormikContext()

	const error = useMemo(
		() => (typeof errorDisplayFormat === 'function' ? errorDisplayFormat(meta.error) : nestedField ? get(meta.error, nestedField) : meta.error),
		[meta.error, nestedField, errorDisplayFormat]
	)

	return useMemo(() => error && (meta.touched || submitCount > 0) && t(error), [error, meta.touched, submitCount, t])
}
