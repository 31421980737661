import { gql } from '@apollo/client'

export const CREATE_REFERRED_TRAVELER_MUTATION = gql`
	mutation CreateReferredTraveler(
		$email: String!
		$firstName: String!
		$lastName: String!
		$phone: String
		$referrerEmail: String
		$referrerFirstName: String
		$referrerLastName: String
		$jobId: String
		$referrerPhone: String
		$discipline: String
		$referralPath: String
		$source: SignupSourceEnum
		$deviceId: String
		$recruiterId: String
	) {
		createReferredTraveler(
			email: $email
			firstName: $firstName
			lastName: $lastName
			phone: $phone
			referrerEmail: $referrerEmail
			referrerFirstName: $referrerFirstName
			referrerLastName: $referrerLastName
			jobId: $jobId
			referrerPhone: $referrerPhone
			discipline: $discipline
			referralPath: $referralPath
			source: $source
			deviceId: $deviceId
			recruiterId: $recruiterId
		) {
			success
		}
	}
`
