import { gql } from '@apollo/client'

export const CREATE_CONTACT_FORM_MUTATION = gql`
	mutation createContactFormMutation($contactType: ContactFormTypeEnum!, $content: String, $source: SourceEnum = opus_web) {
		createContactForm(contactType: $contactType, content: $content, source: $source) {
			id
			status
		}
	}
`
