import axios from 'axios'
import { find, isEmpty } from 'lodash'
import { isExistingEmailError, parseLocation, RECAPTCHA_CLIENT_KEY, toFormErrors } from '~/common/helpers'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, masterStore, notifyStore } from '~/stores'
import { apolloClient, QUICK_APPLY_JOB_MUTATION, VERIFY_EXISTED_EMAIL } from '~/common/apollo'
import { additionalInfoApplyDialogStore } from '~/components/additional-info-apply-dialog/additional-info-apply-dialog.store'
import { logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { CORE_BEHAVIOR_TRACKING } from '~/common/tracking/event-click.constant'

@store()
class QuickApplyStore {
	@observable confirm = false
	@observable phoneNumber = ''
	@observable currentJob = {}
	@observable applyJobData
	@observable cityObjects = []
	@observable openQuickApply = false
	@observable successApplyDialog = false
	@observable showAdditionInfo = false
	@observable autoAssignScl = false

	@computed
	get currentJobID() {
		return this.currentJob?.id
	}

	@computed
	get specialtiesOptions() {
		const specialties = masterStore?.specialties
		if (isEmpty(specialties)) {
			return []
		}

		let options = []
		options = specialties?.map((item) => ({
			value: item.skill_name,
			label: item.long_name,
			has_checklist: item.has_checklist,
		}))
		return options
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@action
	convertToSaveData = (variables) => {
		const workerSpecialtiesAttributes = variables?.specialty?.map((item, index) => {
			let specialty = masterStore?.specialties?.find((pick_item) => pick_item?.skill_name === item)

			return {
				specialty: item,
				category: specialty?.category,
				isPrimary: index === 0,
				workerSkillChecklistSectionsAttributes: [],
			}
		})

		let cities = []
		let states = []
		if (!isEmpty(variables?.locationCity)) {
			cities = Object.values(variables?.locationCity).map((item) => ({
				preferredWorkingCity: item?.city,
				preferredWorkingState: item?.stateCode,
			}))
		}

		if (!isEmpty(variables?.locationState)) {
			states = variables?.locationState?.map((state) => ({ preferredWorkingState: state }))
		}
		const workingPreferredLocationsAttributes = [...cities, ...states]

		return {
			externalJobId: variables?.externalJobId,
			workerSpecialtiesAttributes: workerSpecialtiesAttributes,
			workingPreferredLocationsAttributes: workingPreferredLocationsAttributes,
			...variables,
		}
	}

	@action
	checkExistAccount = async (variables, { setErrors }, resetForm, setClose) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'verifyExistedEmail',
					})
					const { email } = variables || {}

					let updatedVariables = {
						recaptchaToken: token,
						email,
					}
					const { data } = await apolloClient.mutate({
						mutation: VERIFY_EXISTED_EMAIL,
						variables: { ...updatedVariables },
						context: {
							clientName: 'public',
						},
					})

					await this.setApplyJobData(variables)

					const isExist = data?.verifyExistedEmail?.existed
					await setClose(false)
					await resetForm()
					if (isExist) {
						await this.toggleConfirm(true)
					} else {
						await quickApplyStore.setAutoAssignScl(false)
						await additionalInfoApplyDialogStore.changeActiveStep(0)
						await this.setShowAdditionInfo(true)
						additionalInfoApplyDialogStore.percentPerStep = {}
					}

					// await this.setSuccessApplyDialog(true)
					resolve()
				} catch (error) {
					await notifyStore.error(error.message)
					reject(error)
				}
			})
		})
	}

	@action
	quickApplyJobs = async (variables, { setErrors }, setClose) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'quickApply',
					})
					const { resume, references, ...rest } = variables || {}
					const filterResume = resume
						?.filter((item) => item.signedBlobId && !item._destroy && !item.parentId)
						?.map((item) => ({
							fileSignedBlobId: item.signedBlobId,
						}))
					let updatedVariables = {
						recaptchaToken: token,
						jobId: this.currentJobID,
						resumes: filterResume,
						workerReferences: references?.map((item) => ({
							id: item.id,
							email: item.email,
							firstName: item.firstName,
							lastName: item.lastName,
							phone: item.phone,
							relationship: item.relationship,
							jobTitle: item.jobTitle,
							state: item.location?.stateCode ?? null,
							city: item.location?.name ?? null,
						})),
						autoAssignScl: this.autoAssignScl,
						...rest,
					}

					await apolloClient.mutate({
						mutation: QUICK_APPLY_JOB_MUTATION,
						variables: { ...updatedVariables },
						context: {
							clientName: 'public',
						},
					})
					await additionalInfoApplyDialogStore.changeActiveStep(0)
					await setClose(false)

					await this.setSuccessApplyDialog(true)
					logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.applicationSentSuccess, {
						job_id: this.currentJobID,
						is_account_created: !!authStore.id, // true HERE
					})
					resolve()
				} catch (error) {
					if (isExistingEmailError(error)) {
						this.confirm = true
					}

					const errors = toFormErrors(error) || {}
					if (errors?.hasOwnProperty('__ERROR')) {
						notifyStore.error(error.message)
					} else {
						setClose(false)
						notifyStore.error(error.message)
					}
					logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.applicationSentFailed, {
						job_id: this.currentJobID,
						is_account_created: !!authStore.id, // true HERE
					})
					reject(errors)
				}
			})
		})
	}

	@action
	toggleConfirm = async (confirm) => {
		this.confirm = confirm
	}
	@action
	setShowAdditionInfo = (value) => {
		this.showAdditionInfo = value
	}

	@action
	setSuccessApplyDialog = async (successApplyDialog) => {
		this.successApplyDialog = successApplyDialog
	}

	@action
	removePhoneNumber = async () => {
		this.phoneNumber = null
	}

	@action
	setCurrentJob = async (job) => {
		this.currentJob = job
	}

	@action
	setApplyJobData = async (data) => {
		this.applyJobData = data
	}
	@action
	setAutoAssignScl = async (data) => {
		this.autoAssignScl = data
	}

	@action
	fetchCities = async (keywordCity, keywordState) => {
		const response = await axios.get(`/cities?name=${keywordCity}`)
		let cities = response?.data?.data

		if (keywordCity !== '' && keywordState !== '') {
			const option = find(cities, (item) => item.name === keywordCity && item.stateCode === keywordState)
			if (option !== undefined) {
				this.cityObjects = [parseLocation(option)]
			}
		}
	}

	@action
	setOpenQuickApply = async (value) => {
		this.openQuickApply = value
	}
}

export const quickApplyStore = new QuickApplyStore()
