import React, { FC, ReactNode, createContext } from 'react'
import { IAuditService } from '../interfaces'

export const AuditContext = createContext<Partial<IAuditService>>({})

export interface IAuditProviderProps {
	auditService: IAuditService
	children?: ReactNode
}

export const AuditProvider: FC<IAuditProviderProps> = ({ auditService, children }) => {
	return <AuditContext.Provider value={auditService}>{children}</AuditContext.Provider>
}
