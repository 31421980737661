export const SIGN_IN_EVENT = {
	SIGNIN_SUBMIT_SUCCESS: 'SIGNIN_SUBMIT_SUCCESS',
	SIGNIN_SUBMIT_FAILED: 'SIGNIN_SUBMIT_FAILED',

	CLICK_FORGOT_PASSWORD_LINK: 'FORGOT-PASSWORD_LINK_SIGNIN',
	CLICK_SIGN_IN_BTN: 'SIGN-IN_BTN_SIGNIN',
	CLICK_SIGN_UP_LINK: 'SIGN-UP_LINK_SIGNIN',
	CLICK_TRY_AGAIN_BTN: 'TRY-AGAIN_BTN_SIGNIN',
	CLICK_I_GOT_IT_BTN: 'I-GOT-IT_BTN_SIGNIN',
}
