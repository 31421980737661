import { Box, Chip, makeStyles, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Autocomplete } from '@material-ui/lab'
import { LabelField } from '@opus/web.core.form.label-field'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import axios from 'axios'
import { useField, useFormikContext } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useDebounce, useEffectOnce } from 'react-use'
import { css } from 'styled-components'
import { FIELD_MODE } from '~/common/constants'
import { parseLocation } from '~/common/helpers'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import { TextField } from '~/components/fields'
import { appStore } from '~/stores'
import { CheckBoxSvg, CheckedBoxSvg, CheckMark2Svg } from '~/components/icons'

export const labelStyle = css`
	font-weight: 600;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.content.veryDark} !important;
	margin-bottom: 4px;
	text-transform: capitalize;
	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}
	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`

const useStyles = makeStyles((theme) => ({
	clearIndicator: {
		backgroundColor: 'transparent',

		'&:hover': {
			backgroundColor: 'transparent',
		},
		'& span': {
			'& svg': {
				fill: theme.palette.status.error.medium,
				fontSize: '16px',
			},
		},
	},
}))

export const CityField = ({
	name,
	validate,
	label,
	placeholder,
	disabled,
	freeSolo,
	mode,
	multiple,
	disableClearable,
	onSelect,
	defaultValue,
	textFieldVariant,
	endLabel,
	state,
}) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { setFieldValue, values } = useFormikContext()
	const valueState = get(values, state)

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [inputValue, updateInputValue] = useState('')
	const [focused, focusProps] = useFieldFocused(field)
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [fetchDone, setFetchDone] = React.useState(false)
	const loading = useMemo(() => open && options?.length === 0 && !fetchDone, [open, options.length, fetchDone])

	const fetchCities = async (input) => {
		const response = await axios.get(`/cities?name=${input}&state=${valueState}`)
		const cities = response?.data
		setFetchDone(true)
		setOptions(cities)
	}

	React.useEffect(() => {
		if (!loading) {
			return undefined
		}

		fetchCities(field.value?.name || '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, field.value])

	useDebounce(
		() => {
			if (isEmpty(field.value) && !focused) {
				updateInputValue(multiple ? [] : '')
			}
		},
		0,
		[field.value]
	)

	React.useEffect(() => {
		if (!open) {
			setOptions([])
		} else {
			fetchCities(field.value?.name || '')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, field.value])

	useEffectOnce(() => {
		if (!isEmpty(defaultValue)) {
			setFieldValue(name, { ...defaultValue })
		}
	})

	const errorMsg = meta?.error
	const errorFlat = useFieldError(meta)
	const errorNest = useFieldError(meta, 'city')
	const error = typeof errorMsg === 'string' ? errorFlat : errorNest

	const handleChange = useCallback(
		(_, option) => {
			if (option !== null) {
				const address = multiple ? option.map((opt) => parseLocation(opt)) : parseLocation(option)
				setFieldValue(name, { ...address })
				updateInputValue(`${option.city || option.name}, ${option.state || option.state_code}`)

				if (valueState !== address?.state) {
					setFieldValue(state, address?.state)
				}

				onSelect && onSelect(option)
				onSelect && updateInputValue('')
				onSelect && setFieldValue(name, '')
			} else {
				setFieldValue(name, undefined)
				updateInputValue(undefined)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateInputValue, multiple, setFieldValue, name]
	)

	const city = field?.value?.city

	const viewValue = useMemo(() => {
		return city ? city : t('$PLACEHOLDERS.NONE')
	}, [t, city])

	if (mode === FIELD_MODE.view) {
		if (multiple) {
			return (
				<Box>
					<Box mb={2} css={labelStyle}>
						<label> {t(label)} </label>
					</Box>
					{field?.value &&
						Object.values(field?.value)?.map((item) => (
							<span>
								{' '}
								<Chip label={`${item.name}, ${item.stateCode}`} />{' '}
							</span>
						))}
				</Box>
			)
		} else {
			return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => viewValue} />
		}
	}

	return (
		<Autocomplete
			key={name}
			id={name}
			freeSolo={freeSolo}
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			loading={loading}
			options={options}
			getOptionSelected={(option, value) => {
				return option?.name === value?.name && option?.state_code === value?.stateCode
			}}
			renderOption={(option, { selected }) => {
				return (
					<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
						<Typography variant="h4" css={selected ? { color: appStore.getTheme.colors.secondary.main } : undefined}>
							<b>{option.name}</b>
							{', ' + option.state_code}
						</Typography>
						{multiple ? selected ? <CheckedBoxSvg /> : <CheckBoxSvg /> : selected ? <CheckMark2Svg /> : null}
					</Box>
				)
			}}
			getOptionLabel={(option) => {
				if (Object.values(option).some((item) => item === undefined)) {
					return null
				} else {
					return option?.name || option?.city ? `${option?.name || option?.city}` : ''
				}
			}}
			fullWidth
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			onChange={handleChange}
			inputValue={inputValue}
			disableClearable={disableClearable}
			filterOptions={(options) => {
				return options
			}}
			classes={{
				clearIndicatorDirty: classes.clearIndicator,
			}}
			onInputChange={(event, newInputValue) => {
				if (newInputValue !== 'null, null') {
					updateInputValue(newInputValue)
				} else {
					updateInputValue(undefined)
				}

				fetchCities(newInputValue)
			}}
			defaultValue={defaultValue}
			popupIcon={<MdExpandMore />}
			renderInput={(params) => {
				let endAdormentChildren = Boolean(inputValue) ? params.InputProps.endAdornment.props.children : params.InputProps.endAdornment.props.children[1]

				return (
					<>
						<TextField
							{...params}
							label={t(label)}
							disabled={disabled}
							placeholder={placeholder && t(placeholder)}
							error={!!error}
							helperText={error}
							variant={textFieldVariant}
							endLabel={endLabel}
							InputProps={{
								...params.InputProps,
								style: {
									paddingRight: '10px',
								},
								endAdornment: (
									<React.Fragment>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{endAdormentChildren}
									</React.Fragment>
								),
							}}
						/>
					</>
				)
			}}
		/>
	)
}

CityField.defaultProps = {
	freeSolo: false,
	mode: FIELD_MODE.edit,
	multiple: false,
	textFieldVariant: 'outlined',
}
