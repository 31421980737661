import * as yup from 'yup'
import { Box, CircularProgress, Grid, SvgIcon } from '@material-ui/core'
import { FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useWindowScrollTop } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { loadingState, observer } from '~/common/mobx.decorator'
import { UPDATE_WORKER_RESUME_MUTATION } from '~/features/care-profile/care-profile.service'
import { FormMode } from '@opus/web.core.form.utils'
import { PlusBlueSvg } from '~/components/icons'
import { Button } from '~/components/button'
import { authStore, masterStore, notifyStore } from '~/stores'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import SectionBox from '~/components/section-box'
import { ExperienceForm } from '~/features/care-profile/components/experience-form'
import { FileUploadField } from '~/components/fields'
import { careProfileStore } from '~/features/care-profile/care-profile.store'
import { WrapperUploadBox } from '../../../../components/wrapper-upload-box/wrapper-upload-box.component'
import { workTabStore } from './work-tab.store'
import { first, isEmpty } from 'lodash'
import { logClickEvent, logDefaultActionEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { EXPERIENCE_TRACKING_EVENT } from '~/common/constants'
import HintBox from '~/features/care-activation/components/hint-box'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { useStyles } from '~/features/care-profile/tabs/info/info-tab.style'

const validateSchema = yup.object().shape({
	resumes: yup.array().test({
		message: 'Please upload your resume',
		test: (arr) => {
			return arr.length > 0 && arr.filter((item) => !item._destroy).length !== 0
		},
	}),
	workExperiences: yup.array().of(
		yup.object().shape({
			facilityName: yup.string().trim().required('Please enter facility name').typeError('Please enter facility name'),
			unitType: yup.string().trim().required('Please select unit type').typeError('Please select unit type'),
			city: yup
				.string()
				.trim()
				.when('state', {
					is: (state) => {
						const hasCities = !isEmpty(state) ? masterStore.workLocations?.find((location) => location.value === state)?.hasCities : true
						return hasCities
					},
					then: yup.string().required('Please select your city').typeError('Please select your city'),
					otherwise: yup.string().nullable(),
				}),
			state: yup.string().trim().required('Please select your state').typeError('Please select your state'),
			gapInEmployment: yup.bool().nullable(false),
			currentlyEmployed: yup.bool().nullable(false),
			title: yup.string().trim().required('Please enter your position title').typeError('Please enter your position title'),
			reasonForLeaving: yup
				.string()
				.nullable()
				.when('currentlyEmployed', {
					is: (currentlyEmployed) => !currentlyEmployed,
					then: yup.string().test('isValidReason', 'This is not a valid reason for leaving', function (value) {
						const validReasons = masterStore.reasonsForLeaving
						return value ? validReasons.filter((item) => item.label === value).length > 0 : true
					}),
				}),
			agency: yup.string().trim().nullable().max(60, 'Maximum is 60 characters'),
			startDate: yup.date().when('gapInEmployment', {
				is: false,
				then: yup.date().required('Please select start date').typeError('Please select start date').max(new Date(), 'Start date should be in the past'),
				otherwise: yup.date().nullable(),
			}),
			endDate: yup.date().when(['currentlyEmployed', 'gapInEmployment'], {
				is: (currentlyEmployed, gapInEmployment) => !currentlyEmployed && !gapInEmployment,
				then: yup
					.date()
					.required('Please select end date')
					.test('endDate', 'End Date should be greater than Start Date', function (value) {
						const startDate = moment(this.parent.startDate)
						const endDate = moment(value)
						return endDate > startDate
					})
					.max(new Date(), 'End date should be in the past')
					.typeError('Please select end date'),
				otherwise: yup.date().nullable(),
			}),
			gapReason: yup.string().when('gapInEmployment', {
				is: true,
				then: yup.string().required('Please enter your gap reason').typeError('Please enter your gap reason'),
				otherwise: yup.string().nullable(),
			}),
			gapStartDate: yup.date().when('gapInEmployment', {
				is: true,
				then: yup
					.date()
					.required('Please select gap start date')
					.typeError('Please select gap start date')
					.max(new Date(), 'Gap start date should be in the past'),
				otherwise: yup.date().nullable(),
			}),
			gapEndDate: yup.date().when('gapInEmployment', {
				is: true,
				then: yup
					.date()
					.required('Please select gap end date')
					.typeError('Please select gap end date')
					.max(new Date(), 'Gap end date should be in the past')
					.test('gapEndDate', 'Gap end date should be greater than gap start date', function (value) {
						const gapStartDate = moment(this.parent.gapStartDate)
						const gapEndDate = moment(value)
						return gapEndDate.isAfter(gapStartDate)
					}),
				otherwise: yup.date().nullable(),
			}),
		})
	),
})

export const initialExperiencesForm = {
	id: 'add',
	facilityName: '',
	unitType: '',
	city: '',
	state: '',
	country: '',
	agency: '',
	startDate: '',
	endDate: '',
	currentlyEmployed: false,
	gapInEmployment: true,
	workedThroughStaffingFirm: true,
}

const ExperienceViewForm = observer(({ initialValues = {}, onSubmit }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<Formik enableReinitialize={true} initialValues={initialValues} validateOnMount={true} validationSchema={validateSchema} onSubmit={onSubmit}>
			{({ handleSubmit, values }) => {
				return (
					<Form id="experience-form" onSubmit={handleSubmit}>
						<ResumeSection values={values} />

						<FieldArray name="workExperiences">
							{({ push, remove, name }) => (
								<>
									{values?.workExperiences.map((_, index) => (
										<SectionBox
											minimumRemove={values?.workExperiences.length}
											minimumRemoveDialogTitle={t('$MESSAGES.MINIMUM_EXPERIENCE_REMOVE')}
											header={t('WORK_EXPERIENCES')}
											onRemove={() => {
												logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_REMOVE_WORK_BTN, {
													workExperiences: values?.workExperiences?.map((item) => ({
														facility_name: item.facilityName,
														unit_type: item.unitType,
														city: item.city,
														state: item.state,
														start_date: moment(item.startDate).format('YYYY-MM-DD'),
														end_date: moment(item.endDate).format('YYYY-MM-DD'),
														currently_employed: item.currentlyEmployed,
														agency: item.agency,
														_destroy: item?.['_destroy'] || false,
														id: item.id | null,
													})),
												})
												remove(index)
											}}
											showItemRemove
											hasBorder
											key={`${name}.${index}`}
										>
											<Box m={2}>
												<ExperienceForm prefixName={`${name}.${index}`} mode={FormMode.Edit} />
											</Box>
										</SectionBox>
									))}
									<Button
										variant="contained"
										color="secondary"
										startIcon={<SvgIcon component={PlusBlueSvg} viewBox="0 0 18 18"></SvgIcon>}
										onClick={() => {
											push(initialExperiencesForm)
											eventClient.logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_ADD_WORK_BTN, {})
										}}
										className={classes.addSectionBtn}
									>
										{t('ADD_WORK_EXPERIENCE')}
									</Button>
								</>
							)}
						</FieldArray>
					</Form>
				)
			}}
		</Formik>
	)
})

const ExperienceSection = observer(({ onNextTab }) => {
	const { experiences, handleSubmitExperience, currentFormValues, setCurrentFormValues } = workTabStore
	const { resumes } = careProfileStore

	const firstResume = first(resumes?.map((file) => ({ ...file.file, parentId: file.id })))

	const initialValues = useMemo(
		() => {
			if (!isEmpty(currentFormValues)) {
				return {
					workExperiences: currentFormValues?.workExperiences,
					resumes: firstResume ? [firstResume] : [],
				}
			}
			return {
				resumes: firstResume ? [firstResume] : [],
				workExperiences: isEmpty(experiences) ? [initialExperiencesForm] : [...experiences.map((item) => ({ ...item, city: item.city?.city || item.city }))],
			}
		},
		// eslint-disable-next-line
		[resumes, experiences, firstResume, currentFormValues]
	)
	return (
		<Box>
			<Grid item xs={12}>
				<ExperienceViewForm
					onSubmit={async (values) => {
						await handleSubmitExperience(values)
							.then(() => {
								logDefaultActionEvent(EXPERIENCE_TRACKING_EVENT.PROFILE_COMPLETION_EXPERIENCES_SUBMIT_SUCCESS, {
									resumes: values?.resumes?.map((item) => ({
										id: item?.id || null,
										_destroy: item?.['_destroy'] || false,
										file_signed_blob_id: item?.fileSignedBlobId || item?.blobId || null,
									})),
									workExperiences: values?.workExperiences?.map((item) => ({
										facility_name: item.facilityName,
										unit_type: item.unitType,
										city: item.city,
										state: item.state,
										start_date: moment(item.startDate).format('YYYY-MM-DD'),
										end_date: moment(item.endDate).format('YYYY-MM-DD'),
										currently_employed: item.currentlyEmployed,
										agency: item.agency,
										_destroy: item?.['_destroy'] || false,
										id: item.id | null,
									})),
								})
							})
							.catch(() => {
								logDefaultActionEvent(EXPERIENCE_TRACKING_EVENT.PROFILE_COMPLETION_EXPERIENCES_SUBMIT_FAILED, {
									resumes: values?.resumes?.map((item) => ({
										id: item?.id || null,
										_destroy: item?.['_destroy'] || false,
										file_signed_blob_id: item?.fileSignedBlobId || item?.blobId || null,
									})),
									workExperiences: values?.workExperiences?.map((item) => ({
										facility_name: item.facilityName,
										unit_type: item.unitType,
										city: item.city,
										state: item.state,
										start_date: moment(item.startDate).format('YYYY-MM-DD'),
										end_date: moment(item.endDate).format('YYYY-MM-DD'),
										currently_employed: item.currentlyEmployed,
										agency: item.agency,
										_destroy: item?.['_destroy'] || false,
										id: item.id | null,
									})),
								})
							})
						onNextTab()
					}}
					initialValues={initialValues}
					onChange={(values) => {
						setCurrentFormValues(values)
					}}
				/>
			</Grid>
		</Box>
	)
})

const ResumeSection = observer((values) => {
	const { resumeInitialValues } = careProfileStore
	const { handleResumesChange, changeWorkExperiences } = workTabStore
	const { t } = useTranslation()
	const [mode] = useState(FormMode.View)
	const [isRemoveFile, setRemoveFile] = useState(false)
	const { fetchWorkerResumes } = careProfileStore
	const [updateResume, { loading }] = useMutation(UPDATE_WORKER_RESUME_MUTATION, {
		onCompleted: async () => {
			await careProfileStore.fetchWorkerBasicInfo()
			await careProfileStore.fetchWorkerResumes()

			if (!isRemoveFile) {
				await notifyStore.success('Success')
			}
		},
	})

	useEffect(() => {
		if (values?.values?.resumes?.length) {
			handleResumesChange(values?.values?.resumes)
		}
		// eslint-disable-next-line
	}, [values])

	useEffectOnce(() => {
		void fetchWorkerResumes()
	})

	return (
		<WrapperUploadBox hideOption={true} mode={mode} header={`${t('RESUME')}`} onEdit={() => {}} onRemove={() => {}} onClick hasBorder>
			<Grid item>
				<FileUploadField
					loading={loading}
					invisibleUploadInput
					onPreviewTracking={() => {
						if (values.values?.resumes?.length)
							logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_PREVIEW_RESUME_BTN, { file_signed_blob_id: values.values?.resumes?.[0].blobId })
					}}
					removeFile={async (id) => {
						setRemoveFile(true)
						changeWorkExperiences(values.values.workExperiences)
						const resumes_signed_blob_ids = values.values?.resumes?.map((item) => {
							if (item.id === id) {
								return {
									id: item.parentId,
									_destroy: true,
								}
							}
							return item
						})

						await updateResume({
							variables: {
								resumes: resumes_signed_blob_ids,
								id: authStore.id,
							},
						})

						setRemoveFile(false)
					}}
					handleUploaded={async (filesValues) => {
						changeWorkExperiences(values.values.workExperiences)
						if (!isEmpty(filesValues)) {
							const resumes_signed_blob_ids = filesValues
								?.filter((item) => item.hasOwnProperty('_destroy') === false)
								?.map((item) => {
									return {
										fileSignedBlobId: item?.signedBlobId,
									}
								})
							await updateResume({
								variables: {
									resumes: resumes_signed_blob_ids,
									id: authStore.id,
								},
							})
						}
					}}
					mode={'edit'}
					onlyPdf={false}
					name="resumes"
					label={isEmpty(resumeInitialValues?.resumes) ? 'UPLOAD_RESUME_TITLE' : ''}
					title="UPLOAD_RESUME"
					subTitle={'ALLOW_UPLOAD_TYPE'}
					multiple={false}
					accept=".docx ,.png,.jpg,.jpeg,.pdf ,.heic ,.doc, .gif, .xls, .xlsx, .txt, .msg"
					message="$MESSAGES.MAKE_SURE_RESUME"
				/>
			</Grid>
		</WrapperUploadBox>
	)
})

export const WorkTab = observer(({ hintText, onNextTab, onPreviousTab }) => {
	const { fetchWorkExperiences } = workTabStore
	const loading = loadingState('workTabStore/fetchWorkExperiences')
	const loadingSubmit = loadingState('workTabStore/handleSubmitExperience')
	const { t } = useTranslation()
	useWindowScrollTop()

	useEffectOnce(() => {
		fetchWorkExperiences()
		logTrackingScreenEvent(EXPERIENCE_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
	})

	const handleNextTab = () => {
		logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_SKIP_STEP_3, {})
		onNextTab()
	}

	return (
		<Box p={2}>
			<HintBox text={hintText} onSkipTab={handleNextTab} />

			{loading ? (
				<Box display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			) : (
				<ExperienceSection onNextTab={onNextTab} />
			)}

			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							onClick={() => {
								logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_PREVIOUS_BTN, {})
								onPreviousTab()
							}}
						>
							{t('PREVIOUS_STEP')}
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						onClick={() => {
							logClickEvent(EXPERIENCE_TRACKING_EVENT.CLICK_NEXT_STEP_BTN, {})
						}}
					>
						<Button loading={loadingSubmit} form="experience-form" type="submit" fullWidth variant="contained" color="primary">
							{t('SAVE_AND_NEXT_STEP')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
})
