export const FORGOT_PASSWORD_EVENT = {
	SEND_CODE_SUCCESS: 'FORGOT-PASSWORD_SEND-CODE_SUCCESS',
	SEND_CODE_FAIL: 'FORGOT-PASSWORD_SEND-CODE_FAILED',

	CLICK_SEND_CODE_BTN: 'SEND-CODE_BTN',
	CLICK_SEND_CODE_LINK: 'RESEND-CODE_BTN',
	CLICK_UPDATE_NEW_PASSWORD: 'UPDATE-NEW-PASSWORD_BTN',
	CLICK_I_GOT_IT_BTN: 'I-GOT-IT_BTN',
	CLICK_SEND_NEW_CODE_BTN: 'SEND-NEW-CODE_BTN',
}
