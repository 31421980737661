import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useField } from 'formik'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { labelStyle, radioStyle } from './yes-no-field.style'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { viewLabelStyle } from '~/components/fields/autocomplete-field/autocomplete-field.style'

const OPTIONS = {
	yes: 'yes',
	no: 'no',
}

export const YesNoField = ({ name, validate, label, mode, noText, yesText, endLabel }) => {
	const { t } = useTranslation()

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(event) => {
			if (mode === FIELD_MODE.edit) {
				field.onChange({ target: { name, value: event.target.value === OPTIONS.yes } })
			}
		},
		[field, name, mode]
	)

	const fieldValue = useMemo(() => (typeof field.value !== 'boolean' ? OPTIONS.no : field.value === true ? OPTIONS.yes : OPTIONS.no), [field])
	const viewValue = field.value === true ? yesText : noText || field.value

	const titleValue = t(viewValue)

	if (mode === FIELD_MODE.view) {
		return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => titleValue} />
	}

	return (
		<FormControl error={!!error} focused={true} fullWidth={true}>
			<Box display="flex" justifyContent="space-between">
				<FormLabel css={labelStyle}>{t(label)}</FormLabel>
				{endLabel}
			</Box>

			<RadioGroup {...field} value={fieldValue} onChange={handleChange}>
				<Box mt={1}>
					<Grid container>
						<Grid item>
							<FormControlLabel value={OPTIONS.yes} control={<Radio size="small" css={radioStyle} />} label={t(yesText)} />
						</Grid>

						<Grid item>
							<FormControlLabel value={OPTIONS.no} control={<Radio size="small" css={radioStyle} />} label={t(noText)} />
						</Grid>
					</Grid>
				</Box>
			</RadioGroup>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

YesNoField.defaultProps = {
	yesText: 'YES',
	noText: 'NO',
	mode: FIELD_MODE.edit,
}
