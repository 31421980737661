import { css } from 'styled-components'

export const notifyDialogStyle = css`
	position: relative;
	z-index: 1300 !important;
	.MuiDialog-paper {
		margin: 8px;

		> .MuiBox-root {
			@media (min-width: 500px) {
				min-width: 350px;
			}

			max-width: 470px;
			.header {
				position: relative;
				background-color: transparent;

				.MuiTypography-root {
					position: absolute;
					top: 50%;
					left: 50%;

					transform: translate(-50%, -50%);
					padding: 0 4px;
					background-color: ${({ theme }) => theme.palette.common.white};
					color: ${({ theme }) => theme.palette.primary.main};
				}
			}
		}
	}
`
