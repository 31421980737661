import { apolloClient, CHANGE_PASSWORD_USER_MUTATION } from '~/common/apollo'
import { captureException } from '~/common/helpers'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'

@store()
class CommonChangePasswordStore {
	@observable confirm = false
	@computed
	get show() {
		return !!authStore.authorization
	}

	@action
	changePassword = async (variables, { setErrors }, updateForRequired = false, setShowChangePasswordDialog) => {
		try {
			const response = await apolloClient.mutate({ mutation: CHANGE_PASSWORD_USER_MUTATION, variables })
			if (setShowChangePasswordDialog !== false) {
				await setShowChangePasswordDialog(false)
			}

			if (!updateForRequired) {
				this.confirm = true
			}

			authStore.changeToken(response?.data?.changePassword?.authToken)
		} catch (error) {
			captureException('Change Password', error)
			if (error?.graphQLErrors?.[0]?.extensions?.error === 'invalid_password') {
				setErrors({ oldPassword: 'Incorrect current password, please recheck' })
			} else {
				notifyStore.error('$MESSAGES.CHANGE_PASSWORD_FAILED')
			}
		}
	}

	@action
	toggleConfirm = async (confirm) => {
		this.confirm = confirm
	}
}

export const commonChangePasswordStore = new CommonChangePasswordStore()
