import styled from 'styled-components'
import { FormControl } from '@material-ui/core'

export const WrapperCheckBoxField = styled(FormControl)`
	.MuiFormControlLabel-label {
		color: ${({ theme }) => theme.colors.content.veryDark};
		${({ theme }) => theme.typography.body1};
		font-size: 14px;
		font-weight: 400;
	}
`
