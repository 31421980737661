/*global google*/
import React, { useCallback, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { Box, TextField as MuiTextField } from '@material-ui/core'
import { addressFieldStyle } from './address-field.style'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { parseAddress } from '~/common/helpers'
// import { address } from '~/common/validator'
import { useDebounce } from 'react-use'
import { labelStyle, viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import InputLabel from '@material-ui/core/InputLabel'

export const AddressField = ({
	name,
	validate,
	label,
	placeholder,
	mode,
	fullData,
	errorField,
	types,
	valueDisplayFormat,
	errorDisplayFormat,
	componentRestrictions,
	size,
	variant,
	endLabel,
	...rest
}) => {
	const inputRef = React.useRef()
	const { setFieldValue } = useFormikContext()
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, setInputValue] = useState()

	const handleChange = useCallback(
		(event) => {
			setFieldValue(`${name}.formatted_address`, event.target.value)
			setInputValue(event.target.value)
			if (!event.target.value) {
				setFieldValue(name, {})
			}
		},
		[setInputValue, setFieldValue, name]
	)

	const handlePlaceChanged = useCallback(
		(autocomplete) => {
			const address = parseAddress(autocomplete.getPlace())
			setFieldValue(name, { ...field.value, ...address })
			if (typeof valueDisplayFormat === 'function') {
				setInputValue(valueDisplayFormat(address))
			}
		},
		[name, setFieldValue, field.value, valueDisplayFormat]
	)

	useDebounce(
		() => {
			if (focused) {
				return
			}

			if (typeof valueDisplayFormat === 'function') {
				setInputValue(valueDisplayFormat(field.value))
			} else {
				setInputValue(field.value?.formatted_address || '')
			}
		},
		300,
		[field.value, focused]
	)
	React.useEffect(() => {
		if (inputRef.current) {
			const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
				fields: ['geometry', 'address_components', 'formatted_address'],
				componentRestrictions,
				types,
			})

			autocomplete.addListener('place_changed', () => handlePlaceChanged(autocomplete))
		}
		// eslint-disable-next-line
	}, [inputRef.current])

	const error = useFieldError(meta)

	if (mode === FIELD_MODE.view) {
		return (
			<LabelField
				css={viewLabelStyle}
				shrink
				name={name}
				label={t(label)}
				displayValueFormat={(fieldValue) => (valueDisplayFormat ? valueDisplayFormat(fieldValue) : fieldValue?.formatted_address)}
			/>
		)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<InputLabel shrink htmlFor={name} css={labelStyle}>
					{t(label)}
				</InputLabel>

				{endLabel}
			</Box>
			<MuiTextField
				size={size}
				inputRef={inputRef}
				id={name}
				helperText={error}
				error={!!error}
				css={addressFieldStyle}
				{...field}
				value={inputValue}
				onChange={handleChange}
				{...focusProps}
				{...rest}
				placeholder={placeholder && t(placeholder)}
				variant={variant}
				InputLabelProps={{ shrink: true }}
			/>
		</>
	)
}

AddressField.defaultProps = {
	label: 'ADDRESS',
	placeholder: '$PLACEHOLDERS.ADDRESS',
	mode: FIELD_MODE.edit,
	fullData: true,
	errorField: 'street',
	// validate: address,
	componentRestrictions: { country: ['US'] },
	size: 'small',
	variant: 'outlined',
}
