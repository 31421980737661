export const getQueriesUrlParams = () => {
	const urlSearchParams = new URLSearchParams(window.location.search)
	return Object.fromEntries(urlSearchParams.entries()) || {}
}

export const formatStringUrl = (input) => {
	return input.replace(/[./\s-]+/g, '-').toLowerCase()
}

export function parsePathname(pathname) {
	return pathname.split('/').filter((item) => item !== '')
}
export function removeJobsPath(url) {
	return url
}
