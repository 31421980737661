import { nanoid } from 'nanoid'

export const TEMP_PREFIX = 'TEMP_ID'

export const generateTempId = () => `${TEMP_PREFIX}_${nanoid()}`

export const isTempId = (id) => id?.startsWith(TEMP_PREFIX)

export const removeTempIds = (arr) => {
	return arr.map((item) => {
		if (!isTempId(item.id)) {
			return item
		} else {
			const { id, ...rest } = item
			return rest
		}
	})
}

export const removeTempId = (object) => {
	if (isTempId(object.id)) {
		const { id, ...rest } = object
		return rest
	}
	return object
}

export const generateUUID = () => `${nanoid()}`
