import React, { useCallback, useEffect, useState } from 'react'
import { useField } from 'formik'
import { Box, TextField as MuiTextField } from '@material-ui/core'
import { labelStyle, textFieldStyle, viewLabelStyle } from './text-field.style'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import InputLabel from '@material-ui/core/InputLabel'

export const TextField = ({
	type = 'text',
	name,
	validate,
	label,
	placeholder,
	mode,
	shrink = true,
	variant = 'outlined',
	size = 'small',
	endLabel,
	InputProps,
	...rest
}) => {
	const { t } = useTranslation()

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, updateInputValue] = useState(field.value)
	const error = useFieldError(meta)

	useEffect(() => {
		if (field.value !== inputValue && !focused) {
			updateInputValue(field.value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value, focused])

	useEffect(() => {
		if (field.value !== inputValue) {
			field.onChange({ target: { name, value: inputValue } })
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue])

	const handleChange = useCallback(
		(event) => {
			updateInputValue(event.target.value || '')
		},
		[updateInputValue]
	)

	if (mode === FIELD_MODE.view) {
		return <LabelField css={viewLabelStyle} shrink label={t(label)} displayValueFormat={() => inputValue} />
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<InputLabel shrink htmlFor={name} css={labelStyle}>
					{t(label)}
				</InputLabel>
				{endLabel}
			</Box>

			<MuiTextField
				type={type}
				id={name}
				variant={variant}
				size={size}
				placeholder={placeholder && t(placeholder)}
				helperText={error}
				error={!!error}
				css={textFieldStyle}
				InputProps={{
					...focusProps,
					...InputProps,
					placeholder,
				}}
				InputLabelProps={{
					shrink,
				}}
				{...field}
				value={inputValue || ''}
				onChange={handleChange}
				{...rest}
				{...(type === 'email' && { autoComplete: 'email' })}
			/>
		</>
	)
}
