import { hintBoxStyle, skipStepStyle } from '~/features/care-activation/care-activation.style'
import { Box, Typography } from '@material-ui/core'
import { Button } from '~/components/button'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'

const HintBox = ({ text, onSkipTab }) => {
	const { t } = useTranslation()
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" p={2} mb={2} css={hintBoxStyle} gridGap={8}>
			<Typography variant="body2">{text}</Typography>
			{onSkipTab && (
				<Button variant="text" color="primary" size="small" css={skipStepStyle} onClick={() => typeof onSkipTab === 'function' && onSkipTab()}>
					{t('DO_IT_LATER')}
				</Button>
			)}
		</Box>
	)
}

export default HintBox
