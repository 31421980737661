import { filter, find, flatMap, get, includes, isEmpty, isObject, isString } from 'lodash'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { masterStore, notifyStore } from '~/stores'
import { createWorkExperience, getWorkExperiences, updateWorkerResume } from '../../care-activation.service'
import { initialExperiencesForm } from './work-tab.component'

@store()
class WorkTabStore {
	@observable workExperiences
	@observable filesResumes = []
	@observable currentFormValues = {}
	@computed
	get experiences() {
		return this.workExperiences?.map(({ city, state, country, ...exp }) => ({
			...exp,
			city: city ? { country, state, city, stateCode: state, name: city } : null,
			state,
		}))
	}

	@computed
	get unitOptions() {
		const options = find(masterStore.disciplines, { value: 'RN' })
		return options?.dependent_values || []
	}

	@computed
	get floatedUnitOptions() {
		const options = this.unitOptions.map((option) => this.unitOptions.map((o) => ({ ...o, parentValue: option.value })))

		return filter(flatMap(options), (option) => option.parentValue !== option.value)
	}

	@computed
	get agencyOptions() {
		return masterStore.agencies
	}

	@computed
	get chartingOptions() {
		return masterStore.chartings
	}

	@action
	handleResumesChange = (files) => {
		this.filesResumes = files
	}

	@action
	fetchWorkExperiences = async () => {
		this.workExperiences = await getWorkExperiences()
	}

	@action
	changeWorkExperiences = (data) => {
		this.workExperiences = data
	}

	@action
	handleSubmitExperience = async (values) => {
		try {
			const submitDataIds = values?.workExperiences.map((item) => item.id)

			const destroyedFiles = values.resumes
				?.filter((item) => item._destroy && item.parentId)
				?.map((item) => ({
					id: item.parentId,
					_destroy: true,
				}))
			const newFiles = values.resumes
				?.filter((item) => item.signedBlobId && !item._destroy && !item.parentId)
				?.map((item) => ({
					fileSignedBlobId: item.signedBlobId,
				}))

			// case add, update
			const newValues = values?.workExperiences.map((item) => {
				const {
					id,
					startDate,
					endDate,
					state,
					city,
					currentlyEmployed,
					facilityName,
					unitType,
					agency,
					gapInEmployment,
					title,
					workedThroughStaffingFirm,
					reasonForLeaving,
					gapReason,
					gapStartDate,
					gapEndDate,
					reasonForLeavingNote,
				} = item
				return {
					...(id !== initialExperiencesForm.id && { id }),
					startDate: isEmpty(startDate) ? null : startDate,
					endDate: currentlyEmployed ? null : isEmpty(endDate) ? null : endDate,
					currentlyEmployed,
					city: isObject(city?.name) ? get(city?.name, 'city.city.name') : isString(city?.name) ? city?.name : null,
					state: state ?? null,
					country: 'US',
					facilityName,
					unitType,
					agency,
					gapInEmployment,
					title,
					workedThroughStaffingFirm,
					reasonForLeaving: !currentlyEmployed ? reasonForLeaving : null,
					gapReason: gapInEmployment ? gapReason : null,
					gapStartDate: gapInEmployment ? gapStartDate : null,
					gapEndDate: gapInEmployment ? gapEndDate : null,
					reasonForLeavingNote: !currentlyEmployed && reasonForLeaving === 'Other (requires explanation)' ? reasonForLeavingNote : null,
				}
			})

			//case delete
			const removedValues = this.workExperiences
				.map((item) => {
					const { id } = item

					if (!includes(submitDataIds, id) && id !== initialExperiencesForm.id) {
						return {
							id,
							_destroy: true,
						}
					}
					return ''
				})
				.filter(Boolean)
			await updateWorkerResume([...destroyedFiles, ...newFiles] || [])
			await createWorkExperience([...newValues, ...removedValues])
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	setCurrentFormValues = (values) => {
		this.currentFormValues = values
	}
}

export const workTabStore = new WorkTabStore()
