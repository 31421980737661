import React from 'react'
import { SvgIcon, Typography as MuiTypography } from '@material-ui/core'
import { typographyStyle } from './typography.style'

export const Typography = ({ icon: Icon, children, ...props }) => (
	<MuiTypography {...props} css={typographyStyle}>
		{Icon && (
			<SvgIcon>
				<Icon />
			</SvgIcon>
		)}
		{children}
	</MuiTypography>
)
