import React, { useState } from 'react'
import { TextField } from '../text-field/text-field.component'
import { IconButton, InputAdornment } from '@material-ui/core'
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons'

export const PasswordField = (props) => {
	const [show, setShow] = useState()

	return (
		<TextField
			type={show ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton style={{ padding: 0 }} onClick={() => setShow(!show)}>
							{show ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
						</IconButton>
					</InputAdornment>
				),
				...props?.InputProps,
			}}
			{...props}
		/>
	)
}
