import { Box, IconButton, ListItemIcon, Menu, SvgIcon, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { PencilSvg, ThreeDot, TrashAltSvg } from '~/components/icons'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { wrapperStyle } from '~/components/wrapper-upload-box/wrapper-upload-box.style'

const MINIMUM_ITEM_REMOVE = 1

export const WrapperUploadBox = ({
	header,
	subHeader,
	children,
	onEdit,
	onRemove,
	onCloseMenu,
	hasBorder,
	footerActions,
	hideOption = false,
	headerActions,
	...rest
}) => {
	const minimimRemove = rest?.minimimRemove
	const [anchorEl, setAnchorEl] = useState(null)
	const { t } = useTranslation()

	const handleClose = () => {
		typeof onCloseMenu === 'function' && onCloseMenu()
		setAnchorEl(null)
	}
	const handleRemove = () => {
		typeof onRemove === 'function' && onRemove()

		handleClose()
	}

	const handleDotClick = (event) => {
		if (typeof onEdit === 'function' && typeof onRemove !== 'function') {
			onEdit()

			return
		}
		setAnchorEl(event.currentTarget)
	}

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h3">{header}</Typography>
				{headerActions && headerActions}
				{!hideOption && (
					<IconButton size="small" onClick={handleDotClick}>
						{typeof onEdit === 'function' && typeof onRemove === 'function' && <SvgIcon fontSize="small" component={ThreeDot} style={{ fill: '#333335' }} />}
						{typeof onEdit === 'function' && typeof onRemove !== 'function' && (
							<SvgIcon fontSize="small">
								<PencilSvg />
							</SvgIcon>
						)}
					</IconButton>
				)}
			</Box>
			{subHeader && <Typography variant="subtitle1">{subHeader}</Typography>}

			<Box css={hasBorder ? wrapperStyle : undefined} my={2}>
				{children}
			</Box>

			{footerActions ?? footerActions}

			<Menu
				elevation={3}
				id="profession-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: -50,
					horizontal: 'right',
				}}
			>
				{typeof onRemove === 'function' && minimimRemove !== MINIMUM_ITEM_REMOVE && (
					<MenuItem onClick={handleRemove}>
						<ListItemIcon style={{ minWidth: '28px', padding: '4px 0' }}>
							<SvgIcon fontSize="small">
								<TrashAltSvg fontSize="small" />
							</SvgIcon>
						</ListItemIcon>
						<Typography variant="subtitle1" color="textPrimary">
							{t('REMOVE')}
						</Typography>
					</MenuItem>
				)}
			</Menu>
		</Box>
	)
}
