import React, { useCallback, useState } from 'react'
import { useField } from 'formik'
import { TextField as MuiTextField } from '@material-ui/core'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { numberFieldStyle } from './number-field.style'
import NumberFormat from 'react-number-format'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'

const NumFormat = ({ inputRef, onChange, value, ...rest }) => {
	const [inputValue, updateInputValue] = useState(value)
	const [focused, setFocus] = useState(false)

	const onValueChange = useCallback(
		(values) => {
			updateInputValue(values.value || '')
		},
		[updateInputValue]
	)

	const onFocus = useCallback(
		(event) => {
			setFocus(true)
			if (typeof rest.onFocus === 'function') {
				rest.onFocus(event)
			}
		},
		[setFocus, rest]
	)

	useDebounce(
		() => {
			if (value !== inputValue && !focused) {
				updateInputValue(value || '')
			}
		},
		500,
		[value]
	)

	useDebounce(
		() => {
			onChange({ target: { name: rest.name, value: inputValue } })
		},
		300,
		[inputValue]
	)

	return (
		<NumberFormat
			{...rest}
			value={inputValue}
			getInputRef={inputRef}
			onValueChange={onValueChange}
			onFocus={onFocus}
			isNumericString
			decimalSeparator="."
			allowedDecimalSeparators={[',', '.']}
			inputMode="decimal"
		/>
	)
}

export const NumberField = ({ name, validate, label, placeholder, autoComplete, mode, ...props }) => {
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)

	const error = useFieldError(meta)

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => field.value} />
	}

	return (
		<MuiTextField
			id={name}
			label={t(label)}
			placeholder={placeholder && t(placeholder)}
			helperText={error}
			error={!!error}
			css={numberFieldStyle}
			InputProps={{ inputComponent: NumFormat, autoComplete, ...focusProps }}
			InputLabelProps={{
				shrink: focused || !!field.value,
			}}
			{...field}
			{...props}
		/>
	)
}

NumberField.defaultProps = {
	mode: FIELD_MODE.edit,
}
