import { PROFILE_STEP } from '~/common/constants'

export const ACTIATION_TRACKING_CONSTANT = {
	PERSONAL_INFO_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-PERSONAL-INFO_SUBMIT_SUCCESS',
	PERSONAL_INFO_SUBMIT_FAILED: 'PROFILE-COMPLETION-PERSONAL-INFO_SUBMIT_FAILED',

	CONFIRM_KEEP_EMAIL_SUBMIT_SUCCESS: 'CONFIRM_KEEP_EMAIL_SUBMIT_SUCCESS',
	CONFIRM_KEEP_EMAIL_SUBMIT_FAILED: 'CONFIRM_KEEP_EMAIL_SUBMIT_FAILED',

	CONFIRM_UPDATE_EMAIL_SUBMIT_SUCCESS: 'CONFIRM_UPDATE_EMAIL_SUBMIT_SUCCESS',
	CONFIRM_UPDATE_EMAIL_SUBMIT_FAILED: 'CONFIRM_UPDATE_EMAIL_SUBMIT_FAILED',

	CONFIRM_VERIFY_PHONE_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-CONFIRM-VERIFY-PHONE_SUBMIT_SUCCESS',
	CONFIRM_VERIFY_PHONE_SUBMIT_FAILED: 'PROFILE-COMPLETION-CONFIRM-VERIFY-PHONE_SUBMIT_FAILED',

	PROFILE_COMPLETION_CONFIRM_VERIFY_PHONE_SUBMIT_SUCCESS: 'PROFILE-COMPLETION-CONFIRM-VERIFY-PHONE_SUBMIT_SUCCESS',
	PROFILE_COMPLETION_CONFIRM_VERIFY_PHONE_SUBMIT_FAILED: 'PROFILE-COMPLETION-CONFIRM-VERIFY-PHONE_SUBMIT_FAILED',

	VERIFY_PHONE_SUBMIT_SUCCESS: 'VERIFY-PHONE_SUBMIT_SUCCESS',
	VERIFY_PHONE_SUBMIT_FAILED: 'VERIFY-PHONE_SUBMIT_FAILED',

	CHANGE_PHONE: 'change-phone',
	CONFIRM_VERIFY_PHONE: 'confirm-verify-phone',
	VERIFY_PHONE: 'verify-phone',
	PHONE_EXISTED: 'phone-existed',
	PHONE_CHANGE_LIMIT_REACHED: 'phone-change-limit-reached',
	SPAM_OTP: 'spam-otp',
	SCREEN_PHONE_CHANGE_LIMIT_6_TIMES: 'SCREEN_PHONE-CHANGE-LIMIT-6-TIMES',
	SCREEN_CONFIRM_UPDATE_EMAIL: 'confirm-update-email',

	CLICK_VERIFY_PHONE_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'verify-phone_btn_profile-completion-personal-info',
	CLICK_CHANGE_PHONE_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'change-phone_btn_profile-completion-personal-info',
	CLICK_ADD_SPECIALTIES_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'add-specialties_btn_profile-completion-personal-info',
	CLICK_ADD_DISCIPLINE_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'add-discipline_btn_profile-completion-personal-info',
	CLICK_EDIT_DISCIPLINE_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'edit-discipline_btn_profile-completion-personal-info',
	CLICK_EDIT_SPECIALTIES_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'edit-specialty_btn_profile-completion-personal-info',
	CLICK_SUBMIT_STEP_1_BTN_PROFILE_COMPLETION_PERSONAL_INFO: 'submit-step-1_btn_profile-completion-personal-info',
	CLICK_SET_PRIMARY_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION: 'set-primary_btn_discipline-specialties-edit-option',
	CLICK_REMOVE_BTN_DISCIPLINE_SPECIALTIES_EDIT_OPTION: 'remove_btn_discipline-specialties-edit-option',
	CLICK_CANCEL_BTN_CHANGE_PHONE: 'cancel_btn_change-phone',
	CLICK_CONFIRM_BTN_CHANGE_PHONE: 'confirm_btn_change-phone',
	CLICK_CANCEL_BTN_CONFIRM_VERIFY_PHONE: 'cancel_btn_confirm-verify-phone',
	CLICK_CONFIRM_BTN_CONFIRM_VERIFY_PHONE: 'confirm_btn_confirm-verify-phone',
	CLICK_UPDATE_PHONE_BTN_CONFIRM_VERIFY_PHONE: 'update-phone_btn_confirm-verify-phone',
	CLICK_CANCEL_BTN_VERIFY_PHONE: 'cancel_btn_verify-phone',
	CLICK_CONFIRM_BTN_VERIFY_PHONE: 'confirm_btn_verify-phone',
	CLICK_RESEND_BTN_VERIFY_PHONE: 'resend_btn_verify-phone',
	CLICK_UPDATE_PHONE_BTN_VERIFY_PHONE: 'update-phone_btn_verify-phone',
	CLICK_UPDATE_PHONE_BTN_PHONE_EXISTED: 'update-phone_btn_phone-existed',
	CLICK_OK_BTN_PHONE_CHANGE_LIMIT_REACHED: 'ok_btn_phone-change-limit-reached',
	CLICK_OK_BTN_SPAM_OTP: 'ok_btn_spam-otp',
	CLICK_OK_BTN_PHONE_CHANGE_LIMIT_6_TIMES: 'ok_btn_phone-change-limit-6-times',

	[PROFILE_STEP.basic_info]: 'completion-personal-info',
	[PROFILE_STEP.education]: '',
	[PROFILE_STEP.work_experience]: '',
	[PROFILE_STEP.reference]: '',
	[PROFILE_STEP.checklist]: '',
	[PROFILE_STEP.term_sign]: '',
}
