import { Box, Grid, SvgIcon } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useWindowScrollTop } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'

import { FormMode } from '@opus/web.core.form.utils'
import { PlusBlueSvg } from '~/components/icons'

import { credentialsTabStore } from './credentials-tab.store'
import { Button } from '~/components/button'
import { Drawer } from '~/components/drawer'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { useMutation } from '@apollo/client'
import { DESTROY_WORKER_EDU_MUTATION, UPDATE_WORKER_EDU_MUTATION } from '~/features/care-profile/care-profile.service'
import SectionBox from '~/components/section-box'
import { Clear } from '@material-ui/icons'
import { Typography } from '~/components/typography'
import { NotifyDialog } from '~/components/notify'
import { CredentialForm } from '~/features/care-profile/components/credential-form'
import { WrapperEmptyBox } from '~/features/care-profile/care-profile.style'

const CredentialsUpdateForm = observer(({ initialValues, onClose, onSubmit }) => {
	const { t } = useTranslation()
	const { fetchWorkerCredentials, setCredentialsCurrentId, setCredentialsUpdating } = credentialsTabStore
	const [handleUpdateEducation] = useMutation(UPDATE_WORKER_EDU_MUTATION, {
		onCompleted: async () => {
			await fetchWorkerCredentials()
			await setCredentialsCurrentId(null)
			await setCredentialsUpdating(false)
		},
	})
	const handleSubmitEducationUpdate = async (values) => {
		await handleUpdateEducation({
			variables: {
				updateWorkerEducationId: values?.id,
				schoolName: values?.schoolName,
				major: values?.major,
				city: values?.location?.city,
				country: values?.location?.country,
				state: values?.location?.state,
				degreeName: values?.degreeName,
				degreeDate: values?.degreeDate,
			},
		})
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmitEducationUpdate}>
			{({ handleSubmit, isSubmitting, dirty }) => {
				return (
					<Drawer
						title={t('UPDATE_CREDENTIAL')}
						open={true}
						onClose={onClose}
						showDiscard={dirty}
						closeIcon={
							<Box display="flex" alignItems="center" ml={1}>
								<Clear />
							</Box>
						}
					>
						<Form onSubmit={handleSubmit}>
							<Box maxWidth="calc(100vw - 48px)" width="552px">
								<Box mb={3}>
									<CredentialForm singleColumn />
								</Box>

								<Button loading={isSubmitting} type="submit" eventId="submit-credential_btn">
									{t('UPDATE_CREDENTIAL')}
								</Button>
							</Box>
						</Form>
					</Drawer>
				)
			}}
		</Formik>
	)
})

const CredentialsCreateForm = observer(({ onClose, onSubmit }) => {
	const { t } = useTranslation()

	return (
		<Formik initialValues={{}} onSubmit={onSubmit}>
			{({ handleSubmit, isSubmitting, dirty }) => {
				return (
					<Drawer
						title={t('ADD_CREDENTIAL')}
						open={true}
						onClose={onClose}
						showDiscard={dirty}
						closeIcon={
							<Box display="flex" alignItems="center" ml={1}>
								<Clear />
							</Box>
						}
					>
						<form onSubmit={handleSubmit}>
							<Box maxWidth="calc(100vw - 48px)" width="552px">
								<Box mb={3}>
									<CredentialForm singleColumn />
								</Box>

								<Button loading={isSubmitting} type="submit" eventId="submit-credential_btn">
									{t('ADD_CREDENTIAL')}
								</Button>
							</Box>
						</form>
					</Drawer>
				)
			}}
		</Formik>
	)
})

const CredentialViewForm = observer(({ initialValues = {}, onSubmit, index }) => {
	const { t } = useTranslation()
	const {
		credentials,
		credentialsUpdating,
		credentialsCurrentId,
		setCredentialsUpdating,
		setCredentialsCurrentId,
		fetchWorkerCredentials,
	} = credentialsTabStore

	const [handleDeleteWorkerEdu] = useMutation(DESTROY_WORKER_EDU_MUTATION, {
		onCompleted: async () => {
			await fetchWorkerCredentials()
		},
	})

	const [showConfirmation, setShowConfirmation] = useState(false)

	const handleRemoveClick = async (e) => {
		await handleDeleteWorkerEdu({
			variables: {
				destroyWorkerEducationId: initialValues?.id,
			},
		})
	}
	return (
		<>
			<SectionBox
				minimumRemove={credentials?.length}
				header={`${t('CREDENTIALS')} ${index}`}
				onEdit={() => {
					setCredentialsCurrentId(initialValues.id)
					setCredentialsUpdating(true)
				}}
				onRemove={() => setShowConfirmation(true)}
				hasBorder
			>
				<Box m={2}>
					<Formik initialValues={initialValues}>{() => <CredentialForm mode={FormMode.View} />}</Formik>
				</Box>
			</SectionBox>

			<NotifyDialog
				open={showConfirmation}
				okText="Confirm"
				onOk={handleRemoveClick}
				title={t('$MESSAGES.REMOVE_CREDENTIAL_CONFIRMATION')}
				onCancel={() => setShowConfirmation(false)}
			/>

			{credentialsUpdating && credentialsCurrentId === initialValues.id && (
				<CredentialsUpdateForm initialValues={initialValues} onClose={() => setCredentialsUpdating(false)} onSubmit={onSubmit} />
			)}
		</>
	)
})

const CredentialsSection = observer(() => {
	const { t } = useTranslation()
	const { credentials, credentialsCreating, setCredentialsCreating, handleSubmitCredentials } = credentialsTabStore

	return (
		<>
			<Box>
				{!credentials.length && (
					<WrapperEmptyBox>
						<Typography variant="title2">{t('$ERRORS.EMPTY_CREDENTIAL')}</Typography>
					</WrapperEmptyBox>
				)}

				{credentials?.map((credential, index) => (
					<Grid item key={credential.id} xs={12}>
						<CredentialViewForm onSubmit={handleSubmitCredentials} initialValues={credential} index={index + 1} />
					</Grid>
				))}

				<Button
					variant="contained"
					color="secondary"
					startIcon={<SvgIcon component={PlusBlueSvg} viewBox="0 0 18 18"></SvgIcon>}
					onClick={() => {
						const eventId = 'add-credential_btn'
						eventClient.logClickEvent(eventId, null).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))

						setCredentialsCreating(true)
					}}
				>
					{t('ADD_CREDENTIAL')}
				</Button>
			</Box>
			{credentialsCreating && <CredentialsCreateForm onClose={() => setCredentialsCreating(false)} onSubmit={handleSubmitCredentials} />}
		</>
	)
})

export const CredentialsTab = observer(() => {
	const { fetchWorkerCredentials } = credentialsTabStore

	useWindowScrollTop()

	useEffectOnce(() => {
		fetchWorkerCredentials()
	}, [])

	return (
		<Box p={2}>
			<CredentialsSection />
		</Box>
	)
})
