import React from 'react'
import { Redirect } from 'react-router-dom'
import { parse } from 'query-string'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { useRequest } from 'ahooks'
import { delay } from './common/helpers'
import { useEffectOnce } from 'react-use'
import { routerStore } from '~/stores'

export const Tracking = (props) => {
	const { data, loading } = useRequest(async () => {
		await delay(300)
		const branchData = await window.host.getBranchData()

		const data = branchData.data_parsed
		return data
	})

	const queryParams = parse(props.location.search)

	useEffectOnce(() => {
		eventClient.logEvent({ type: 'DATA_TRACKING', payload: queryParams })
	})

	const returnUrl = queryParams?.return_url || queryParams?.returnUrl || data?.return_url || data?.returnUrl

	if (loading || !returnUrl) {
		return null
	}

	return <Redirect to={{ pathname: returnUrl, state: { from: routerStore.from } }} />
}
