export const INTEGRATION_OBJECTS = {
	licenses: 'WorkerCertificationOverview',
	skillChecklist: 'WorkerCertificationOverview',
	experiences: 'WorkExperienceOverview',
	educations: 'WorkerEducationOverview',
	references: 'WorkerReferenceOnboarding',
	terms: 'UserTermsOfService',
	profile: 'ProfileOnboarding',
}

export const JOB_TITLE = {
	nurse: 'Charge Nurse',
	manager: 'Manager',
}
