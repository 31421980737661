import { gql } from '@apollo/client'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SAVE_FILTER_QUERY = gql`
	query SavedFilters($after: String, $before: String, $first: Int, $last: Int) {
		savedFilters(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				alertChannels
				enableAlert
				externalId
				filterCondition {
					conditions
					id
				}
				frequency
				id
				lastAlertedAt
				name
				pauseAlert
				pauseAlertPeriod
				pauseAlertUntil
				startPauseAlertDate
			}
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
		}
	}
`

export const GET_APPLICATION_JOB_QUERY = gql`
	query getApplicantJobQuery($jobId: String!) {
		job(id: $jobId) {
			isLiked
			isDisliked
			isBookmarked
			id
			externalJobId
			jobApplicant {
				currentWorkerAssignment {
					createdAt
					endDate
					id
					startDate
					status
					updatedAt
					client {
						name
						id
					}
				}
				applicationProcess
				createdAt
				id
				job {
					id
				}
				status
				statusName

				interview {
					availableTime {
						interviewEndTime
						interviewStartTime
						timezone
					}

					address1
					city
					createdAt
					description
					id
					interviewEndTime
					interviewLink
					interviewStartTime
					interviewType
					note
					state
					status
					statusName
					statusUpdatedAt
					title
					updateEvents
					updatedAt
					interviewers {
						employer {
							avatar {
								fileUrl
							}
							firstName
							lastName
						}
					}
				}
				submittal {
					client {
						id
						name
					}
					id
					jobId
					status
				}
				updatedAt
			}
		}
	}
`

export const GET_JOB_ALERT_QUERY = gql`
	query JobAlerts($after: String, $before: String, $first: Int, $last: Int) {
		jobAlerts(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				name
				alertChannels
				enableAlert
				filterCondition {
					startDate
					exclusiveJob
					externalId
					addressCities
					addressStates
					workTypes
					disciplines
					id
					maxWage
					minMatchingPercentage
					payAmountMin
					nearBy
					shifts
					specialties
				}
				frequency
				lastAlertedAt
				name
				pauseAlert
				pauseAlertPeriod
				pauseAlertUntil
				startPauseAlertDate
				jobs {
					...jobFragment
				}
			}
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
		}
	}
	${JOB_FRAGMENT}
`

export const GET_ALL_CLOSE_JOB_QUERY = gql`
	query ClosedJobsList($ids: [String!]!) {
		closedJobsList(ids: $ids) {
			id
		}
	}
`

export const GET_SPECITALY_BY_JOB = gql`
	query getSpecialtyJob($id: String!) {
		job(id: $id) {
			id
			atsSource
			metadata
			isPerDiemLongTerm
			isPublish
			jobGeneral {
				specialties {
					discipline {
						id
						disciplineName
					}
				}
			}
		}
	}
`
