import { action, observable, store } from '~/common/mobx.decorator'

@store()
class VerifyEmailStore {
	@observable showVerifyEmail = false
	@observable showEmailOtp = false
	@observable email = ''

	@action
	toggleShowVerifyEmail = (value) => {
		this.showVerifyEmail = value
	}

	@action
	toggleShowEmailOtp = (value) => {
		this.showEmailOtp = value
	}

	@action
	setEmail = (value) => {
		this.email = value
	}
}

export const verifyEmailStore = new VerifyEmailStore()
