import React, { useCallback, useMemo } from 'react'
import { DesktopDatePicker } from '@material-ui/pickers'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Box, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { dateRangeFieldStyle, iconButtonStyle } from './date-range-field.style'
import { DATE_FORMAT_SAVE, DATE_FORMAT_VIEW, FIELD_MODE } from '~/common/constants'
import { CalendarSvg } from '~/components/icons'
import moment from 'moment'
import classnames from 'classnames'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'

export const DateRangeField = ({
	name,
	validate,
	label,
	startPlaceholder,
	endPlaceholder,
	saveFormat,
	current,
	currentText,
	minDate,
	maxStartDate,
	maxEndDate,
	mode,
	clearable,
}) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	const handleStartChange = useCallback(
		(startDate) => {
			const startValue = !startDate ? null : startDate?.format(saveFormat)
			setFieldValue(`${name}.0`, startValue)
		},
		[setFieldValue, name, saveFormat]
	)

	const handleEndChange = useCallback(
		(endDate) => {
			const endValue = current || !endDate ? null : endDate.format(saveFormat)

			setFieldValue(`${name}.1`, endValue)
		},
		[setFieldValue, current, name, saveFormat]
	)

	const fieldValue = useMemo(() => {
		if (!field.value) {
			return [null, null]
		}
		const [startString, endString] = field.value

		return [startString && moment(startString, saveFormat), !current && endString && moment(endString, saveFormat)]
	}, [field.value, saveFormat, current])

	const viewValue = useMemo(() => {
		const [startDate, endDate] = fieldValue
		const startString = startDate ? startDate.format(DATE_FORMAT_VIEW) : t('$PLACEHOLDERS.NONE')
		const endString = !current && endDate && endDate.format(DATE_FORMAT_VIEW)
		return endString ? `${startString} → ${endString}` : startString
	}, [fieldValue, t, current])

	useDebounce(
		() => {
			if (current && field?.value?.length > 0) {
				field.onChange({ target: { name, value: [field.value[0], null] } })
			}
		},
		1000,
		[current]
	)

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => viewValue} />
	}

	return (
		<FormControl focused error={!!error} css={dateRangeFieldStyle}>
			<InputLabel htmlFor={name}>{t(label)}</InputLabel>
			<Box className={classnames({ 'current-date': current, 'date-range-wrapper': true })}>
				<DesktopDatePicker
					inputFormat={DATE_FORMAT_VIEW}
					onChange={handleStartChange}
					value={fieldValue?.[0] || null}
					minDate={minDate}
					maxDate={maxStartDate}
					openPickerIcon={<CalendarSvg />}
					clearable={clearable}
					renderInput={(startProps) => (
						<Input
							{...startProps}
							className="start-date"
							name={`${field.name}.startDate`}
							fullWidth
							{...startProps.inputProps}
							placeholder={t(startPlaceholder)}
							endAdornment={
								startProps.InputProps.endAdornment || (
									<InputAdornment position="end">
										<IconButton aria-label="show calendar" css={iconButtonStyle} onClick={startProps.inputProps.onClick} type="button">
											<CalendarSvg />
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					)}
				></DesktopDatePicker>
				<DesktopDatePicker
					inputFormat={DATE_FORMAT_VIEW}
					onChange={handleEndChange}
					minDate={minDate}
					maxDate={maxEndDate}
					value={fieldValue?.[1] || null}
					openPickerIcon={<CalendarSvg />}
					clearable={clearable}
					renderInput={(endProps) => {
						const extraProps = !current ? { ...endProps } : {}
						return (
							<Input
								{...extraProps}
								className="end-date"
								name={`${field.name}.endDate`}
								fullWidth
								{...endProps.inputProps}
								value={current ? t(currentText) : endProps.inputProps?.value}
								placeholder={t(endPlaceholder)}
								endAdornment={
									!current &&
									(endProps.InputProps.endAdornment || (
										<InputAdornment position="end">
											<IconButton aria-label="show calendar" css={iconButtonStyle} onClick={endProps.inputProps.onClick} type="button">
												<CalendarSvg />
											</IconButton>
										</InputAdornment>
									))
								}
							/>
						)
					}}
				></DesktopDatePicker>
			</Box>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

DateRangeField.defaultProps = {
	label: 'START_END_DATES',
	placeholder: '$PLACEHOLDERS.START_END_DATES',
	startText: 'START_DATE',
	endText: 'END_DATE',
	currentText: 'CURRENT',
	startPlaceholder: '$PLACEHOLDERS.START_DATE',
	endPlaceholder: '$PLACEHOLDERS.END_DATE',
	saveFormat: DATE_FORMAT_SAVE,
	clearable: true,
	current: false,
	mode: FIELD_MODE.edit,
	minDate: new Date(1700, 0, 1),
}
