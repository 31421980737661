import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { FormMode } from '@opus/web.core.form.utils'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { AutoCompleteField, CheckField, DateField, FileUploadField, TextField } from '~/components/fields'
import { appStore, masterStore } from '~/stores'
import { CREDENTIALS_ATS_SOURCE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import { isEmpty, isNil, last } from 'lodash'
import { downloadContainer } from '~/features/credentials/credentials-feature.style'
import { CREDENTIAL_STATUS_MAPPING, TRACKING_CONSTANT } from '~/features/credentials/credentials-feature.constant'
import { logClickEvent } from '~/common/tracking/event-client.tracking'

export const CredentialForm = ({ mode = FormMode.Edit, singleColumn, formValues }) => {
	const { t } = useTranslation()
	const { licenseStates } = masterStore
	const credentialSource = formValues.credential?.atsSource
	const isSymplrCredential = formValues.credential?.atsSource === 'symplr'
	const isAllowShowNumber = () => {
		if (formValues?.credential?.default === true) {
			return false
		}
		if (formValues?.requiredFields?.number === true && isSymplrCredential) {
			return true
		}
		if (!isSymplrCredential) {
			if (!(!formValues.number && credentialSource === CREDENTIALS_ATS_SOURCE.bullhorn)) {
				return true
			}
		}
		return false
	}
	const isAllowShowState = () => {
		if (formValues?.credential?.default === true) {
			return false
		}
		if (formValues?.requiredFields?.state === true && isSymplrCredential) {
			return true
		}
		if (!isSymplrCredential) {
			if (!isNil(formValues?.state) || formValues?.isLicense) {
				return true
			}
		}
		return false
	}
	const isAllowShowCompactState = () => {
		if (formValues?.credential?.enableCompactOrMultiState === false) {
			return false
		} else {
			if (formValues?.credential?.default === true || (isSymplrCredential && !formValues?.isLicense)) {
				return false
			}
			return true
		}
	}

	const isAllowShowExpirationDate = () => {
		if (formValues?.credential?.default === true) {
			return false
		}
		if (formValues?.requiredFields?.expiration_date === true && isSymplrCredential) {
			return true
		}
		if (!isSymplrCredential) {
			if (!(!formValues.expirationDate && credentialSource === CREDENTIALS_ATS_SOURCE.bullhorn)) {
				return true
			}
		}
		return false
	}

	const isAllowShowFileDownload = () => {
		if (!isEmpty(formValues.files) && mode === FormMode.View) {
			return true
		}
		return false
	}
	const isAllowShowFileUpload = () => {
		if (mode === FormMode.View) {
			return false
		}
		if (formValues?.credential?.default === true) {
			return false
		}
		if (formValues?.requiredFields?.upload === true && isSymplrCredential && mode === FormMode.Edit) {
			return true
		}
		return true
	}

	const isCheckRequiredNumber = () => {
		if (formValues.isLicense === true) {
			return true
		}
		if (formValues?.requiredFields?.number === true && isSymplrCredential) {
			return true
		}
		return false
	}

	const isCheckRequiredIssuedDate = () => {
		if (formValues.isLicense === true) {
			return true
		}
		if (formValues?.requiredFields?.effective_date === true && isSymplrCredential) {
			return true
		}
		return false
	}

	const isCheckRequiredExpirationDate = () => {
		if (formValues.isLicense === true) {
			return true
		}
		if (formValues?.requiredFields?.expiration_date === true && isSymplrCredential) {
			return true
		}
		return false
	}

	const isShowCurrentDateOnly = formValues?.credential?.default === true

	return (
		<Grid container spacing={2}>
			{isAllowShowNumber() && (
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						endLabel={
							!isCheckRequiredNumber() ? (
								<Typography color="textSecondary" variant="subtitle2">
									{t('OPTIONAL')}
								</Typography>
							) : null
						}
						name="number"
						label={t('CREDENTIAL_NUMBER')}
						mode={mode}
						placeholder={t('$PLACEHOLDERS.CREDENTIAL_NUMBER')}
					/>
				</Grid>
			)}

			{isAllowShowState() && (
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<AutoCompleteField
						multiple={false}
						name="state"
						label={mode === FormMode.View ? t('STATE') : t('STATE_LICENSE_ISSUED_IN')}
						placeholder={t('$PLACEHOLDERS.CREDENTIAL_STATE')}
						options={licenseStates}
						mode={mode}
					/>
				</Grid>
			)}

			{isAllowShowCompactState() && (
				<Grid item md={singleColumn ? 12 : 6}>
					<CheckField name="compactState" label={t('COMPACT_MULTI_STATE_LICENSE')} mode={mode} />
				</Grid>
			)}

			<Grid item xs={12} md={singleColumn ? 12 : 6}>
				<DateField
					endLabel={
						!isCheckRequiredIssuedDate() ? (
							<Typography color="textSecondary" variant="subtitle2">
								{t('OPTIONAL')}
							</Typography>
						) : null
					}
					maxDate={isShowCurrentDateOnly ? new Date() : null}
					minDate={isShowCurrentDateOnly ? new Date() : null}
					name="effectiveDate"
					label="ISSUED_DATED"
					placeholder="$PLACEHOLDERS.CREDENTIAL_ISSUED_DATE"
					mode={mode}
				/>
			</Grid>

			{isAllowShowExpirationDate() && (
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<DateField
						endLabel={
							!isCheckRequiredExpirationDate() ? (
								<Typography color="textSecondary" variant="subtitle2">
									{t('OPTIONAL')}
								</Typography>
							) : null
						}
						name="expirationDate"
						label="EXPIRATION_DATE"
						placeholder="$PLACEHOLDERS.CREDENTIAL_EXPIRATION_DATE"
						mode={mode}
					/>
				</Grid>
			)}
			{mode === FormMode.View && (
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<LabelField
						name="status"
						css={viewLabelStyle}
						label={t('CREDENTIAL_STATUS')}
						mode={mode}
						displayValueFormat={() => (
							<Box
								borderRadius={100}
								color={CREDENTIAL_STATUS_MAPPING?.[formValues.status]?.textColor}
								bgcolor={CREDENTIAL_STATUS_MAPPING?.[formValues.status]?.backgroundColor}
								css={[appStore.getTheme.typography.title3]}
								px={1}
								py="3px"
							>
								{CREDENTIAL_STATUS_MAPPING?.[formValues.status]?.label}
							</Box>
						)}
					/>
				</Grid>
			)}
			{isAllowShowFileDownload() && (
				<Grid item xs={12} md={12}>
					<Box css={downloadContainer}>
						<Box className={singleColumn ? 'upload-edit' : 'upload'}>
							<FileUploadField
								isAllowShowCtaDownload={true}
								mode={FormMode.View}
								name="files"
								label={formValues.credential?.default ? 'SIGNED_DOCUMENT' : 'LICENSE_CERITIFICATION_OTHER_DOCS'}
								onClickDownload={() => {
									logClickEvent(TRACKING_CONSTANT.downloadSignedDocumentBtn, {
										credential_category: formValues.credential.category,
										credential_name: formValues.credential.name,
										credential_source: formValues.credential?.atsSource,
										file_name: !isEmpty(formValues.files) ? last(formValues.files)?.file?.filename : '',
									})
								}}
								callBackRemove={(file) => {
									logClickEvent(TRACKING_CONSTANT.deleteCredentialBtn, {
										credential_category: formValues.credential.category,
										credential_name: formValues.credential.name,
										credential_source: formValues.credential?.atsSource,
										file_name: file?.filename,
									})
								}}
								callbackChange={() => {
									logClickEvent(TRACKING_CONSTANT.uploadCredentialBtn, {
										credential_category: formValues.credential.category,
										credential_name: formValues.credential.name,
										credential_source: formValues.credential?.atsSource,
									})
								}}
							/>
						</Box>
					</Box>
				</Grid>
			)}
			{isAllowShowFileUpload() && (
				<Grid item xs={12} md={12}>
					<FileUploadField
						accept=".docx ,.png,.jpg,.jpeg,.pdf ,.heic ,.doc, .gif, .xls, .xlsx, .txt, .msg"
						mode={mode}
						name="files"
						heading={'LICENSE_CERITIFICATION_OTHER_DOCS'}
						label={''}
						title="UPLOAD_CREDENTIAL"
						subTitle={'ALLOW_UPLOAD_TYPE'}
						multiple={true}
						message="$MESSAGES.ALLOW_UPLOAD_TYPE_CREDENTIALS"
					/>
				</Grid>
			)}
		</Grid>
	)
}
