import { Box, ButtonBase, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { blueLabel, redLabel, wrapperStyle } from '~/components/section-box/section-box.style'
import { Button } from '~/components/button'
import { notifyStore } from '~/stores'

const MINIMUM_ITEM_REMOVE = 1

export const SectionBox = ({
	header,
	subHeader,
	children,
	onEdit,
	onRemove,
	onCloseMenu,
	hasBorder,
	headerActions,
	footerActions,
	showItemRemove,
	minimumRemove,
	minimumRemoveDialogTitle,
}) => {
	const { t } = useTranslation()

	const handleClose = () => {
		typeof onCloseMenu === 'function' && onCloseMenu()
	}

	const handleEdit = () => {
		typeof onEdit === 'function' && onEdit()

		handleClose()
	}

	const handleRemove = () => {
		if (showItemRemove && minimumRemove === MINIMUM_ITEM_REMOVE) {
			void notifyStore.success('$MESSAGES.MINIMUM_ITEM_REMOVE', {
				showDialog: true,
				title: minimumRemoveDialogTitle,
				okText: t('I_GOT_IT'),
			})
			return
		}

		typeof onRemove === 'function' && onRemove()

		handleClose()
	}

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h4">{header}</Typography>
				{headerActions && headerActions}
				{!showItemRemove && !headerActions && (
					<Box display="flex" gridGap="1rem">
						{typeof onEdit === 'function' && (
							<ButtonBase disableRipple onClick={handleEdit}>
								<Typography variant="subtitle2" css={blueLabel}>
									{t('EDIT')}
								</Typography>
							</ButtonBase>
						)}

						{typeof onRemove === 'function' && (
							<ButtonBase disableRipple onClick={handleRemove}>
								<Typography variant="subtitle2" css={redLabel}>
									{t('REMOVE')}
								</Typography>
							</ButtonBase>
						)}
					</Box>
				)}
			</Box>
			{subHeader && <Typography variant="subtitle1">{subHeader}</Typography>}

			<Box css={hasBorder ? wrapperStyle : undefined} my={2}>
				{children}

				{showItemRemove && (
					<Box p={2}>
						<Button css={redLabel} onClick={handleRemove} variant="text">
							{t('REMOVE')}
						</Button>
					</Box>
				)}
			</Box>

			{footerActions ?? footerActions}
		</Box>
	)
}
