import { gql } from '@apollo/client'

export const GET_BULLETINS_QUERY = gql`
	query getBulletinsQuery {
		bulletins {
			id
			active
			allowDismiss
			content
			expiredAt
			kind
			name
			scope
			targetedClass
			title
			cssStyle
			options
		}
	}
`
