import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { NotifyDialog } from '../notify'
import { observer } from '~/common/mobx.decorator'
import { handleLoadReCaptcha } from '~/common/helpers'
import { notifyStore } from '~/stores'
import { ERROR_CODE } from '~/common/constants'
import { EmailOtpDialogInput } from '~/components/email-otp-dialog/email-otp-dialog-input.component'
import { ACTIATION_TRACKING_CONSTANT } from '~/features/care-activation/care-activation.constant'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { emailOtpDialogStore } from './email-otp-dialog.store'

export const EmailOtpDialog = observer(({ showDialog, onCancel, onClose, onOk, email, phoneNumber }) => {
	const {
		handleSubmitOTP,
		handleSendOTP,
		errorCode,
		submitErrorCode,
		fetchInitialVerifyData,
		loadingInitial,
		resendTimeLeft,
		initialErrorCode,
	} = emailOtpDialogStore

	useEffect(() => {
		if (!showDialog) {
			return
		}
		handleLoadReCaptcha()
		void fetchInitialVerifyData(email)
		// eslint-disable-next-line
	}, [fetchInitialVerifyData, showDialog, email])

	const onClickResend = async (helper) => {
		await handleSendOTP({ email }, helper)
	}

	const onSubmitOtp = (values, helper) => {
		const submitData = {
			email,
			code: values.otpCode,
		}
		void handleSubmitOTP(submitData, helper, onOk).catch((err) => {
			notifyStore.error(err?.message)
		})
	}

	if (loadingInitial || !showDialog) {
		return null
	}

	return !initialErrorCode ? (
		<>
			{errorCode === ERROR_CODE.OTP_REQUEST_LIMITED ? (
				<NotifyDialog
					eventName={ACTIATION_TRACKING_CONSTANT.SPAM_OTP}
					showButtonClose={true}
					onClose={() => {
						typeof onClose === 'function' && onClose()
					}}
					title="You can only request one verification code per minute"
					onOk={() => {
						logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_OK_BTN_SPAM_OTP)
						onClose()
					}}
					okText="I got it"
				>
					<Typography color="textSecondary">Please wait a moment and try again later.</Typography>
					<Typography color="textSecondary">Thank you for your understanding.</Typography>
				</NotifyDialog>
			) : (
				<>
					{(errorCode === '' ||
						[ERROR_CODE.EMAIL_VERIFICATION_REQUEST_LIMITED, ERROR_CODE.SESSION_LOCK].includes(errorCode) ||
						['invalid_otp', 'twilio_error'].includes(submitErrorCode)) && (
						<EmailOtpDialogInput
							errorCode={errorCode}
							onSubmitOtp={onSubmitOtp}
							onClose={onClose}
							email={email}
							onCancel={onCancel}
							resendTimeLeft={resendTimeLeft}
							onClickResend={onClickResend}
						/>
					)}
				</>
			)}
		</>
	) : (
		(initialErrorCode === ERROR_CODE.OTP_REQUEST_LIMITED || errorCode === ERROR_CODE.OTP_REQUEST_LIMITED) && (
			<NotifyDialog
				eventName={ACTIATION_TRACKING_CONSTANT.SPAM_OTP}
				showButtonClose={true}
				onClose={() => {
					typeof onClose === 'function' && onClose()
				}}
				title="You can only request one verification code per minute"
				onOk={() => {
					logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_OK_BTN_SPAM_OTP)
					onClose()
				}}
				okText="I got it"
			>
				<Typography color="textSecondary">Please wait a moment and try again later.</Typography>
				<Typography color="textSecondary">Thank you for your understanding.</Typography>
			</NotifyDialog>
		)
	)
})
