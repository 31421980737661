import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { NotifyDialog } from '../notify'
import { observer } from '~/common/mobx.decorator'
import { verifyEmailStore } from './verify-email.store'
import { EmailOtpDialog } from '../email-otp-dialog'
import { careStore } from '~/companies/care/care.store'
import { infoTabStore } from '~/features/care-activation/tabs/info/info-tab.store'
import { careProfileStore } from '~/features/care-profile/care-profile.store'

export const VerifyEmail = observer(({ email }) => {
	const { t } = useTranslation()
	const { toggleShowEmailOtp, toggleShowVerifyEmail } = verifyEmailStore

	return (
		<>
			<NotifyDialog
				showButtonClose
				open={verifyEmailStore.showVerifyEmail}
				okText="Confirm"
				onOk={() => {
					toggleShowVerifyEmail(false)
					toggleShowEmailOtp(true)
				}}
				title={t('VERIFY_EMAIL_ADDRESS')}
				onCancel={() => {
					toggleShowVerifyEmail(false)
				}}
				onClose={() => {
					toggleShowVerifyEmail(false)
				}}
			>
				<Box display="flex" flexDirection="column" gridRowGap={8}>
					<Typography color="textSecondary">
						Please confirm that <strong style={{ color: '#333335' }}>{email}</strong> is your email address to proceed verification.
					</Typography>
				</Box>
			</NotifyDialog>

			<EmailOtpDialog
				showDialog={verifyEmailStore.showEmailOtp}
				email={email}
				onClose={() => toggleShowEmailOtp(false)}
				onCancel={() => toggleShowEmailOtp(false)}
				onOk={() => {
					toggleShowEmailOtp(false)
					careProfileStore.updateWorkerBasicInfo({ emailVerifiedAt: new Date() })
					infoTabStore.updateWorkerInfo({ emailVerifiedAt: new Date() })
					void careStore.fetchWorkerBasicInfo()
				}}
			/>
		</>
	)
})
