import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'

export const tabStyle = css`
	.MuiTabs-centered {
		justify-content: unset;
	}
	.MuiTabs-flexContainer {
		color: ${({ theme }) => theme.palette.text.secondary};
		border-bottom: solid 1px ${({ theme }) => theme.palette.content.light};

		.MuiTab-root {
			opacity: 1;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;

			&.Mui-selected {
				color: ${({ theme }) => theme.palette.primary.main};
				font-weight: 800;
			}
		}

		> .MuiButtonBase-root.MuiTab-root {
			min-width: fit-content;
			flex-grow: 1;
		}
	}
	@media (max-width: 599.95px) {
		.MuiTabs-flexContainer {
			border-bottom: none;
		}
		.MuiButtonBase-root {
			border-bottom: solid 1px #e6e6e6;
		}
	}
	@media (max-width: 500px) {
		.MuiTab-wrapper {
			font-size: 10px !important;
		}
	}

	.MuiTabs-indicator {
		width: calc(inherit - 24px);
		height: 2px;
	}
`

export const labelEndStyle = css`
	color: #7e7e80;
`

export const changePhoneStyle = css`
	${({ theme }) => theme.typography.title2};
	color: #00aae5;
	cursor: pointer;
`

export const changePhoneDisabledStyle = css`
	${({ theme }) => theme.typography.title2};
	color: #7e7e80;
	cursor: pointer;
`

export const helperTextStyle = css`
	${({ theme }) => theme.typography.subtitle3};
	color: ${({ theme }) => theme.palette.content.dark};
	margin-top: 4px;
`

export const WrapperEmptyBox = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
	span {
		color: ${({ theme }) => theme.palette.content.dark};
	}
`
export const hidePlaceHolderStyle = css`
	input::placeholder {
		color: transparent;
	}
`
