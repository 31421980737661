import { Box, Dialog, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import { composeT, required, samePassword } from '~/common/validator'
import { Button } from '~/components/button'
import { commonChangePasswordStore } from './common-change-password.store'
import { EmailField } from '@opus/web.core.form.email-field'
import { PasswordField } from '@opus/web.core.form.password-field'
import { css } from 'styled-components'
import { Typography } from '~/components/typography'

const changePasswordDialog = css`
	width: 100%;
	min-width: 450px;
	.MuiDialog-paperWidthSm {
		max-width: 600px;
		width: 40%;
		min-width: 450px;
	}
	.title {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.1px;
		text-align: center;
		justify-content: center;
		color: ${({ theme }) => theme.colors.primary.main};
	}
`

export const CommonChangePasswordDialogFeature = observer(({ showChangePasswordDialg, setShowChangePasswordDialog }) => {
	const { t } = useTranslation()
	const handleOnSubmitPassword = async (values, setErrors) => {
		await commonChangePasswordStore.changePassword(values, { setErrors }, true, setShowChangePasswordDialog)
	}

	return (
		<>
			<Dialog open={showChangePasswordDialg} css={changePasswordDialog}>
				<Box m={2}>
					<Box mt={2} mb={2}>
						<Typography className={'title'}>{t('CHANGE_PASSWORD')}</Typography>
					</Box>
					<Formik initialValues={{}} onSubmit={(values, { setErrors }) => handleOnSubmitPassword(values, setErrors)} validate={samePassword(t)}>
						{({ handleSubmit }) => (
							<Form onSubmit={handleSubmit}>
								<Grid container spacing={2} direction="column">
									<Box height="0px" overflow="hidden">
										<EmailField name="email" validate={null} />
									</Box>
									<Grid item>
										<PasswordField autoComplete="off" name="oldPassword" label={t('TEMP_PASSWORD')} validate={composeT(t)(required)} />
									</Grid>
									<Grid item>
										<PasswordField autoComplete="off" name="password" label={t('NEW_PASSWORD')} validate={composeT(t)(required)} />
									</Grid>
									<Grid item>
										<PasswordField autoComplete="off" name="passwordConfirmation" label={t('NEW_PASSWORD_CONFIRM')} validate={composeT(t)(required)} />
									</Grid>
								</Grid>

								<Box mt={2} mb={2}>
									<Grid item container spacing={2}>
										<Grid item xs={12}>
											<Button type="submit" eventId="update-change-pass_btn">
												{t('CONFIRM')}
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Dialog>
		</>
	)
})
