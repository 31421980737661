export const SIGN_UP_EVENT = {
	SIGNUP_SUBMIT_SUCCESS: 'SIGNUP_SUBMIT_SUCCESS',
	SIGNUP_SUBMIT_FAILED: 'SIGNUP_SUBMIT_FAILED',

	CLICK_TERMS_OF_SERVICE_LINK: 'TERMS-OF-SERVICE_LINK_SIGNUP',
	CLICK_PRIVACY_POLICY_LINK: 'PRIVACY-POLICY_LINK_SIGNUP',
	CLICK_CONTINUE_BTN_SIGNUP: 'CONTINUE_BTN_SIGNUP',
	CLICK_SIGN_IN_LINK_SIGNUP: 'SIGN-IN_LINK_SIGNUP',
	CLICK_COMPLETE_BTN_SIGNUP: 'COMPLETE_BTN_SIGNUP',
	CLICK_CLOSE_BTN_SIGNUP: 'CLOSE_BTN_SIGNUP',
}
