import { Box, Grid, Typography } from '@material-ui/core'
import { AddressField, AutoCompleteField, PhoneField, SelectField, TextField } from '~/components/fields'
import { FIELD_MODE, TRAVEL_EMPLOYMENT_TYPE } from '~/common/constants'
import { CheckCircleSvg, SuccessMarkSvg } from '~/components/icons'
import { changePhoneDisabledStyle, changePhoneStyle, helperTextStyle, labelEndStyle } from '~/features/care-profile/care-profile.style'
import { parseAddressToView, validatePhoneNumber } from '~/common/helpers'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { FormMode } from '@opus/web.core.form.utils'
import { useToggle } from 'react-use'
import { masterStore } from '~/stores'
import { observer } from '~/common/mobx.decorator'
import { isArray } from 'lodash'
import { infoTabStore } from '~/features/care-activation/tabs/info/info-tab.store'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { ACTIATION_TRACKING_CONSTANT } from '../care-activation.constant'
import { verifiedTextStyle } from '~/components/fields/phone-field/phone-field.style'
import { LabelField } from '@opus/web.core.form.label-field'
import { viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import { verifiedWrapperStyle } from '../care-activation.style'

const BasicInformationForm = observer(({ mode = FormMode.Edit, singleColumn, formValues, onVerifyPhone, showPhoneOtp, isApplyJob = false, onVerifyEmail }) => {
	const { t } = useTranslation()
	const [changePhone, toggleChangePhone] = useToggle(false)
	const { phoneVerified, workerPhone } = infoTabStore
	const { jobShifts, workTypes, licenseStates } = masterStore

	useEffect(() => {
		if (!showPhoneOtp) {
			toggleChangePhone(false)
		}
	}, [showPhoneOtp, toggleChangePhone])

	const showPreferredLocations = useMemo(() => isArray(formValues.preferredWorkTypes) && formValues.preferredWorkTypes?.includes(TRAVEL_EMPLOYMENT_TYPE), [
		formValues.preferredWorkTypes,
	])

	const newPhoneInputted = useMemo(() => (formValues.phone ?? '').replace('+1', '') !== (workerPhone ?? '').replace('+1', ''), [formValues.phone, workerPhone])

	const phoneValid = useMemo(() => formValues.phone?.length === 12 && validatePhoneNumber(formValues.phone), [formValues.phone])

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField inputProps={{ maxLength: 50 }} name="firstName" label="FIRST_NAME" placeholder={t('$PLACEHOLDERS.FIRST_NAME')} mode={mode} />
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField name="lastName" inputProps={{ maxLength: 50 }} label="LAST_NAME" placeholder={t('$PLACEHOLDERS.LAST_NAME')} mode={mode} />
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					{mode === FIELD_MODE.view || infoTabStore.worker?.emailVerifiedAt ? (
						<LabelField
							style={{ width: '100%' }}
							css={viewLabelStyle}
							className="full-width"
							shrink
							label={t('EMAIL_ADDRESS')}
							displayValueFormat={() => (
								<Box css={verifiedWrapperStyle} display="flex" alignItems="center" width={'100%'}>
									<Box width={infoTabStore.worker?.emailVerifiedAt ? 'calc(100% - 100px)' : '100%'} className="email-address">
										{formValues.email}
									</Box>
									{infoTabStore.worker?.emailVerifiedAt && (
										<Box ml={2} display="flex" alignItems="center" gridGap={4} css={verifiedTextStyle} position="absolute" right={0}>
											{t('VERIFIED')} <CheckCircleSvg />
										</Box>
									)}
								</Box>
							)}
						/>
					) : (
						<TextField
							name="email"
							type="email"
							mode={mode}
							label="EMAIL_ADDRESS"
							placeholder={t('$PLACEHOLDERS.EMAIL')}
							InputProps={{
								readOnly: true,
								endAdornment: infoTabStore.worker?.emailVerifiedAt && (
									<Box ml={2} pr={2} display="flex" alignItems="center" gridGap={4} css={verifiedTextStyle} position="absolute" right={0}>
										<CheckCircleSvg />
									</Box>
								),
							}}
							endLabel={
								!infoTabStore.worker?.emailVerifiedAt && (
									<Typography
										css={changePhoneStyle}
										onClick={() => {
											onVerifyEmail()
										}}
									>
										{t('VERIFY')}
									</Typography>
								)
							}
						/>
					)}
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<PhoneField
						autoFocus
						phoneVerified={phoneVerified}
						disabled={mode === FIELD_MODE.edit && !changePhone && phoneVerified}
						name="phone"
						mode={mode}
						label="PHONE_NUMBER"
						placeholder="$PLACEHOLDERS.PHONE"
						InputProps={{
							endAdornment: !changePhone && phoneVerified && <SuccessMarkSvg />,
						}}
						endLabel={
							!changePhone && phoneVerified ? (
								<Typography
									css={changePhoneStyle}
									onClick={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CHANGE_PHONE_BTN_PROFILE_COMPLETION_PERSONAL_INFO)
										toggleChangePhone(true)
									}}
								>
									{t('CHANGE_ALT')}
								</Typography>
							) : (
								<Typography
									css={!formValues.phone || (!newPhoneInputted && phoneVerified) || !phoneValid ? changePhoneDisabledStyle : changePhoneStyle}
									onClick={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_VERIFY_PHONE_BTN_PROFILE_COMPLETION_PERSONAL_INFO)

										if (!formValues.phone || (!newPhoneInputted && phoneVerified) || !phoneValid) {
											return
										}
										onVerifyPhone()
									}}
								>
									{t('VERIFY')}
								</Typography>
							)
						}
					/>
				</Grid>

				{!isApplyJob && (
					<>
						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<AddressField
								name="workerAddress"
								label="PERMANENT_ADDRESS"
								mode={mode}
								valueDisplayFormat={(data) => parseAddressToView(data, ['street', 'city', 'state', 'zipcode'])}
							/>
						</Grid>

						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<AddressField
								name="temporaryAddress"
								label="TEMPORARY_ADDRESS"
								mode={mode}
								endLabel={
									<Typography variant="subtitle2" css={labelEndStyle}>
										{t('OPTIONAL')}
									</Typography>
								}
								valueDisplayFormat={(data) => parseAddressToView(data, ['street', 'city', 'state', 'zipcode'])}
							/>
						</Grid>

						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<AutoCompleteField
								multiple={true}
								name="preferredShifts"
								label="DESIRED_SHIFTS"
								options={jobShifts}
								placeholder="$PLACEHOLDERS.DESIRED_SHIFTS"
								mode={mode}
								helperText={<Typography css={helperTextStyle}>{t('$MESSAGES.SELECT_SHIFTS')}</Typography>}
							/>
						</Grid>

						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<AutoCompleteField
								multiple={true}
								name="preferredWorkTypes"
								label="DESIRED_EMPLOYMENT_TYPES"
								placeholder="$PLACEHOLDERS.DESIRED_EMPLOYMENT_TYPES"
								options={workTypes}
								mode={mode}
								helperText={<Typography css={helperTextStyle}>{t('$MESSAGES.SELECT_EMPLOYMENT_TYPES')}</Typography>}
							/>
						</Grid>

						{showPreferredLocations && (
							<Grid item xs={12} md={singleColumn ? 12 : 6}>
								<SelectField
									name="preferredLocation"
									label={t('PRIMARY_PREFERRED_LOCATION')}
									placeholder={t('$PLACEHOLDERS.PREFERRED_LOCATIONS')}
									options={licenseStates}
									mode={mode}
								/>
							</Grid>
						)}

						{showPreferredLocations && (
							<Grid item xs={12} md={singleColumn ? 12 : 6}>
								<AutoCompleteField
									name="otherPreferredLocations"
									label={t('OTHER_PREFERRED_LOCATION')}
									placeholder={t('$PLACEHOLDERS.OTHER_PREFERRED_LOCATIONS')}
									options={licenseStates?.filter((item) => item?.value !== formValues.preferredLocation)}
									mode={mode}
									multiple
								/>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	)
})

export default BasicInformationForm
