import { FormControlLabel, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Checkbox } from '../../checkbox'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { WrapperCheckBoxField } from '~/components/fields/check-field/check-field.style'

export const CheckField = ({ name, validate, label, mode, handleOnChange, ...props }) => {
	const { t } = useTranslation()
	const eventId = props?.eventId
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(event, checked) => {
			event.target.value = checked

			if (mode === FIELD_MODE.edit) {
				field.onChange(event)
				handleOnChange && handleOnChange()
			}

			logClickEvent(eventId, {
				value: event.target.value,
			})
		},

		// eslint-disable-next-line
		[mode, field, eventId]
	)

	return (
		<WrapperCheckBoxField error={!!error}>
			<FormControlLabel
				control={<Checkbox disabled={mode === 'view'} {...field} checked={field.value || false} onChange={handleChange} name={name} {...props} />}
				label={t(label)}
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</WrapperCheckBoxField>
	)
}

CheckField.defaultProps = {
	mode: FIELD_MODE.edit,
}
