import gql from 'graphql-tag'
import { JOB_BASED_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SEARCH_JOB_QUERY = gql`
	query searchJobQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		jobSearch(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}
	${JOB_BASED_FRAGMENT}
`

export const GET_LIST_JOBS_QUERY = gql`
	query getListJobsQuery($savedFilterId: String, $filter: JobFilter, $sortBy: JobManagementSort, $after: String, $before: String, $first: Int, $last: Int) {
		searchJobs(savedFilterId: $savedFilterId, filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			nodes {
				...jobBasedFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				pageSize
				startCursor
			}
		}
	}
	${JOB_BASED_FRAGMENT}
`

export const GET_MORE_LIST_JOBS_QUERY = gql`
	query getMoreListJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $after: String, $before: String, $first: Int, $last: Int) {
		searchJobs(filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			nodes {
				...jobBasedFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				pageSize
				startCursor
			}
		}
	}
	${JOB_BASED_FRAGMENT}
`
export const SEARCH_SCROLL_END_JOB_QUERY = gql`
	query searchScrollEndJobQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		jobSearch(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}
	${JOB_BASED_FRAGMENT}
`
