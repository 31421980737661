import React, { useMemo } from 'react'
import { useField, useFormikContext } from 'formik'
import { Box, MenuItem, SvgIcon, TextField as MuiTextField, Typography } from '@material-ui/core'
import { selectFieldStyle } from './select-field.style'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { MdExpandMore } from 'react-icons/md'
import { useIsMobile } from '~/common/hooks'
import { filter, get } from 'lodash'
import { useDebounce } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import InputLabel from '@material-ui/core/InputLabel'
import { labelStyle, viewLabelStyle } from '~/components/fields/text-field/text-field.style'

export const SelectField = ({ name, validate, options, label, placeholder, parentName, multiple, mode, endLabel, variant, size, ...rest }) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const isMobile = useIsMobile()
	const OptionComponent = useMemo(() => (isMobile ? 'option' : MenuItem), [isMobile])

	const parentValue = useMemo(() => parentName && get(values, parentName), [values, parentName])

	const finalOptions = useMemo(() => {
		if (!parentName) {
			return options
		}

		return filter(options, (option) => (!parentValue ? !option.parentValue : parentValue === option.parentValue)) || []
	}, [parentName, parentValue, options])

	const disabled = useMemo(() => parentName && finalOptions.length === 0, [parentName, finalOptions])

	const error = useFieldError(meta)

	useDebounce(
		() => {
			if (parentName && field.value && finalOptions?.length === 0) {
				field.onChange({ target: { name, value: null } })
			}
		},
		2000,
		[parentValue]
	)

	const fieldValue = useMemo(() => field.value || (multiple ? [] : ''), [field.value, multiple])

	const selectedOption = useMemo(() => finalOptions?.find((option) => option.value === fieldValue), [fieldValue, finalOptions])

	if (mode === FIELD_MODE.view) {
		return (
			<LabelField css={[labelStyle, viewLabelStyle]} label={t(label)} displayValueFormat={() => (multiple ? fieldValue?.join(', ') : selectedOption?.label)} />
		)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<InputLabel shrink htmlFor={name} css={labelStyle}>
					{t(label)}
				</InputLabel>
				{endLabel}
			</Box>

			<MuiTextField
				variant={variant}
				size={size}
				id={name}
				select
				placeholder={placeholder && t(placeholder)}
				helperText={error}
				error={!!error}
				css={selectFieldStyle}
				disabled={disabled}
				multiline={multiple}
				SelectProps={{
					IconComponent: (iconProps) => <SvgIcon {...iconProps} component={MdExpandMore} />,
					native: isMobile,
					multiple,
					displayEmpty: true,
					renderValue: (value) => (!value ? <Typography style={{ color: '#AEAEAE' }}>{t(placeholder)}</Typography> : selectedOption?.label),
				}}
				InputLabelProps={{
					shrink: !!field.value || (multiple && isMobile),
				}}
				{...field}
				{...rest}
				value={fieldValue}
			>
				{isMobile && !multiple && <OptionComponent aria-label="None" value="" disabled style={{ color: '#333335' }} />}
				{finalOptions.length === 0 && <OptionComponent aria-label="Temp" value={field.value} label={field.value} disabled style={{ color: '#333335' }} />}
				{finalOptions?.map((option) => (
					<OptionComponent key={option.value} value={option.value} style={{ color: '#333335' }}>
						{option.keepOriginLabel ? option.label : t(option.label)}
					</OptionComponent>
				))}
			</MuiTextField>
		</>
	)
}

SelectField.defaultProps = {
	options: [],
	mode: FIELD_MODE.edit,
	variant: 'outlined',
	size: 'small',
}
