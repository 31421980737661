import moment from 'moment'

const isNumeric = (input) => {
	const number = input.replace(',', '.') //to Universal format
	return !isNaN(parseFloat(number)) && isFinite(number)
}

const validateHHMMInput = (input) => {
	const min = input.slice(0, 2)
	const second = input.slice(2)

	return (
		isNumeric(min) &&
		parseInt(min, 10) >= 0 &&
		parseInt(min, 10) <= 23 &&
		(!second || (isNumeric(second) && parseInt(second, 10) >= 0 && parseInt(second, 10) <= 59))
	)
}

const formatHHMM = (input) => {
	return input.slice(0, 2) + ':' + input.slice(2)
}

const suggestTimeSet = (input) => {
	let result = new Set()

	if (input?.length === 4) {
		result.add(input)
		return result
	}

	if (input?.length > 4) {
		return result
	}

	for (let i = 0; i <= input?.length; ++i) {
		const value = input.slice(0, i) + '0' + input.slice(i)
		if (value?.length === 4) {
			result.add(value)
		} else {
			result = new Set([...result, ...suggestTimeSet(value)])
		}
	}

	return result
}

const suggestHHMMList = (input) => {
	const hhmm = input?.replace(/[^\d]/g, '')
	//
	return input.length > 0
		? Array.from(suggestTimeSet(hhmm))
				.filter((t) => validateHHMMInput(t))
				.map((t) => formatHHMM(t))
		: []
}

const VALUE_HHMM_LENGTH_FOUR = 4

const VALUE_HHMM_LENGTH_FIVE = 5
export { suggestHHMMList, validateHHMMInput, VALUE_HHMM_LENGTH_FIVE, VALUE_HHMM_LENGTH_FOUR }

export function extractMonths(input) {
	// Match any sequence of digits at the beginning of the string
	const matches = input.match(/^\d+/)

	// Check if there is a match
	if (matches) {
		// Extract the first match and convert it to an integer
		const result = parseInt(matches[0], 10)

		// Check if the result is a valid integer
		if (!isNaN(result)) {
			return result
		}
	}

	// Return a default value if no valid integer is found
	return null
}

export const convertSelectTimeToFutureDate = (time) => {
	const numMonths = extractMonths(time)
	// Calculate the future date
	const originalDate = moment().add(numMonths, 'months')
	return originalDate.format('MMMM DD, YYYY')
}
