import { CREDENTIALS_STATUS } from '~/common/constants'

export const CREDENTIAL_STATUS_MAPPING = {
	[CREDENTIALS_STATUS.verified]: {
		label: 'Verified',
		textColor: '#3BB700',
		backgroundColor: '#3BB7001A',
	},
	[CREDENTIALS_STATUS.in_verification]: {
		label: 'In verification',
		textColor: '#2C6EEE',
		backgroundColor: '#2C6EEE1A',
	},
	[CREDENTIALS_STATUS.pending]: {
		label: 'Pending submission',
		textColor: '#FF9B04',
		backgroundColor: '#FF9B041A',
	},
	[CREDENTIALS_STATUS.expired]: {
		label: 'Expired',
		textColor: '#FF2727',
		backgroundColor: '#FF27271A',
	},
	[CREDENTIALS_STATUS.signed]: {
		label: 'Signed',
		textColor: '#3BB700',
		backgroundColor: '#3BB7001A',
	},
}

export const TRACKING_CONSTANT = {
	editCredential: 'edit-credential',
	submitCredentialJotform: 'submit-credential-jotform',
	expandBtn: 'expand_btn',
	signSubmitBtn: 'sign-submit_btn',
	submitAbleBtn: 'submit-able_btn',
	downloadBtn: 'download_btn',
	downloadSignedDocumentBtn: 'download-signed-document_btn',
	deleteCredentialBtn: 'delete-credential_btn',
	saveBtn: 'save_btn',
	uploadCredentialBtn: 'upload-credential_btn',
	cancelBtn: 'cancel_btn',
	proceedBtn: 'proceed_btn',
	submitBtn: 'submit_btn',
	closeBtn: 'close_btn',
	completeAndCloseBtn: 'complete-and-close_btn',
}

export const CREDENTIAL_TYPE = {
	esign: 'esign',
	inApp: 'in_app',
	external: 'external',
}

export const REQUIRED_VISIBLE_FIELDS_MAPPING = {
	state: 'state',
	number: 'number',
	upload: 'files',
	allow_update: 'allowUpdate',
	compact_state: 'compactState',
	effective_date: 'effectiveDate',
	expiration_date: 'expirationDate',
}

export const REQUIRED_FIELDS_MAPPING = {
	state: 'state',
	number: 'number',
	upload: 'files',
	allow_update: 'allowUpdate',
	compact_state: 'compactState',
	effective_date: 'effectiveDate',
	expiration_date: 'expirationDate',
}
