import { IAuditLogParams, IAuditService } from '~/core/interfaces'

export class AuditService implements IAuditService {
	async log(params: IAuditLogParams) {
		console.log('🚀 ~ file: AuditService.ts:5 ~ AuditService ~ log ~ params:', params)
		return Promise.resolve({ success: true })
	}
}

export const auditService = new AuditService()
