import { IconButton, SnackbarContent, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { forwardRef } from 'react'
import { useStyle } from './snackbar.style'
import { CheckedBrokenSvg, CloseSvg } from '../icons'

const SNACKBAR_TYPE = {
	success: 'success',
	error: 'error',
	info: 'info',
	warning: 'warning',
}

export const SnackbarCustomization = forwardRef((props, ref) => {
	const classes = useStyle()
	const { id, message, variant, ...other } = props
	const { closeSnackbar } = useSnackbar()
	const onClose = () => {
		closeSnackbar(id)
	}

	if (variant === SNACKBAR_TYPE[variant]) {
		return (
			<SnackbarContent
				ref={ref}
				classes={{ root: classes.root, action: classes.action }}
				action={
					<>
						<CheckedBrokenSvg />
						<Typography variant="subtitle2" className="message">
							{message}
						</Typography>
						<IconButton key={id} size="small" color="content.dark" onClick={onClose}>
							<CloseSvg />
						</IconButton>
					</>
				}
				{...other}
			/>
		)
	}
	return null
})
